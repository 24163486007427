import { Form, Input, Row, WizardItemType } from '@gimlite/watermelon';
import { UpdateAdminUserWizardContext } from '../updateAdminUser.wizard';

export const UpdateCredentialsStep = ({
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateAdminUserWizardContext>) => {
  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'password',
              label: 'Mot de passe',
              rules: [{ required: true, min: 8 }],
            }}
          >
            <Input
              config={{ placeholder: '', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
