import { Result as ResultAnt } from 'antd';
import { Color } from '../../types/component.type';
import { Col } from '../col/col.component';
import { SkeletonAvatar, SkeletonInput } from '../skeleton/skeleton.component';
import { Space } from '../space/space.component';
import './result.component.scss';

const statusConvert = {
  warn: 'warning',
  error: 'error',
  success: 'success',
} as const;

export declare namespace ResultType {
  type Props = {
    className?: string;
    data: {
      status?: Extract<Color, 'success' | 'warn' | 'error'> | null;
      title?: string | null;
      subtitle?: string | null;
    };
  };
}

export const Result = ({
  data: { status, title = '', subtitle = '' },
  className = '',
}: ResultType.Props) => {
  return status ? (
    <div className={`result ${className}`}>
      <ResultAnt
        status={statusConvert[status]}
        title={title}
        subTitle={subtitle}
      ></ResultAnt>
    </div>
  ) : (
    <Col config={{ horizontal: 'center' }}>
      <SkeletonAvatar config={{ size: 100 }} />
      <Space />
      <SkeletonInput config={{ size: 'medium' }} />
    </Col>
  );
};
