import {
  FilterExpanded,
  Page,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Query, QueryTranslationsArgs } from '../../../../client/graphql';
import { translationsGql } from '../gql/translations.gql';
import { useNavigate } from 'react-router-dom';

export const TranslationsSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { translations: Query['translations'] },
    QueryTranslationsArgs
  >(translationsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const translations = useMemo(() => {
    if (!data) return null;

    return data.translations;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['filter'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          <FilterExpanded
            data={{ value: getParamsUrl }}
            handleEvent={{
              submit: (data: any) => {
                setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
              },
            }}
          >
            <FilterExpanded.Fields></FilterExpanded.Fields>
            <FilterExpanded.Actions></FilterExpanded.Actions>
          </FilterExpanded>
        </Zone.Area>

        <Zone.Area config={{ area: 'table' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: 'Liste des dictionnaires' }}
          >
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/adm/translations/${value.dictionaryKey}`);
                },
              }}
              data={
                translations
                  ? {
                      list: translations?.list?.map(
                        ({ key, languages, documents, createdAt, ...rest }) => {
                          return {
                            ...rest,
                            createdAt: createdAt
                              ? DateTime.fromISO(createdAt).toFormat(
                                  'dd/MM/yy HH:mm:ss',
                                )
                              : null,
                            dictionaryKey: key,
                            languagesLength: languages?.length ?? 0,
                            translationsLength: documents
                              ? documents?.reduce((acc, item) => {
                                  return (
                                    acc + Object.keys(item?.translations).length
                                  );
                                }, 0)
                              : 0,
                          };
                        },
                      ),
                      paging: translations?.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Name',
                    key: 'dictionaryKey',
                  },
                  {
                    title: 'Nombre de langue',
                    key: 'languagesLength',
                  },
                  {
                    title: 'Nombre de traductions',
                    key: 'translationsLength',
                  },
                  {
                    title: 'Date de création',
                    key: 'createdAt',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
