import {
  Badge,
  Button,
  FilterExpanded,
  Page,
  Table,
  useMutation,
  useMyUrl,
  useQuery,
  useRootStore,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { Duration } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mutation, Query, QueryUpsListArgs } from '../../../../client/graphql';
import { updateUpsGql, UpdateUpsParams } from '../gql/updateUps.gql';
import { upsListGql } from '../gql/upsList.gql';

export const UpsSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { WizardStore } = useRootStore();

  const [updateUps, updateUpsState] = useMutation<
    Mutation['updateUps'],
    UpdateUpsParams
  >(updateUpsGql, {
    notification: {
      loading: "Mise à jour de l'UPS en cours ...",
      success: "L'UPS a été mise à jour avec succès",
      error: "Erreur lors de la mise à jour de l'UPS",
    },
  });

  const { data, loading, error, refetch } = useQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const upsList = useMemo(() => {
    if (!data) return null;

    return data.upsList;
  }, [data]);

  useEffect(() => {
    if (updateUpsState.data) {
      refetch();
    }
  }, [updateUpsState?.data, refetch]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des UPS' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded>
              <FilterExpanded.Fields></FilterExpanded.Fields>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              _id: string;
              code?: string | null;
              client: string;
              maximumDuration?: string | null;
              terminalId?: string | null;
              shape: React.ReactNode;
            }>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value) => {
                  navigate(`/onstreet/ups/${value._id}`);
                },
                update: ({ _id, terminalId }) => {
                  updateUps({
                    variables: {
                      input: {
                        upsId: _id,
                        config: {
                          prestoScan: {
                            terminalId,
                          },
                        },
                      },
                    },
                  });
                },
              }}
              data={
                upsList
                  ? {
                      list: upsList.list.map(
                        ({
                          client,
                          maximumDuration,
                          shape,
                          config,
                          ...rest
                        }) => {
                          return {
                            client: client?.slug,
                            maximumDuration:
                              typeof maximumDuration === 'number'
                                ? Duration.fromMillis(
                                    maximumDuration * 1000,
                                  ).toFormat('hh:mm')
                                : null,
                            shape: !!!shape ? (
                              <Badge
                                config={{
                                  color: 'error',
                                  text: 'Indisponible',
                                }}
                              />
                            ) : (
                              <Badge
                                config={{
                                  color: 'success',
                                  text: 'Disponible',
                                }}
                              />
                            ),
                            terminalId: config?.prestoScan?.terminalId || null,
                            ...rest,
                          };
                        },
                      ),
                      paging: upsList.paging,
                    }
                  : undefined
              }
              config={{
                actions: { read: true, update: ['terminalId'] },
                columns: [
                  {
                    title: 'Code',
                    key: 'code',
                  },
                  {
                    title: 'Client',
                    key: 'client',
                  },
                  {
                    title: 'Durée maximum',
                    key: 'maximumDuration',
                  },
                  {
                    title: 'TerminalID',
                    key: 'terminalId',
                  },
                  {
                    title: 'Shape',
                    key: 'shape',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
