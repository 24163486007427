import {
  FilterExpanded,
  Page,
  Table,
  useMyUrl,
  useQuery,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Query, QueryParkingsArgs } from '../../../../client/graphql';
import { parkingsGql } from '../gql/parkings.gql';

export const ParkingsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { parkings: Query['parkings'] },
    QueryParkingsArgs
  >(parkingsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const parkings = useMemo(() => {
    if (!data) return null;

    return data.parkings;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des parkings' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded data={{ value: getParamsUrl }}>
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions></FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  console.log({ value });
                },
              }}
              data={
                parkings
                  ? {
                      list: parkings.list.map(({ coordinates, ...rest }) => {
                        const [coordX, coordY] = coordinates;
                        return {
                          ...rest,
                          coordX,
                          coordY,
                        };
                      }),
                      paging: parkings.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Nom',
                    key: 'name',
                  },
                  {
                    title: 'Code',
                    key: 'code',
                  },
                  {
                    title: 'Coordonnée X',
                    key: 'coordX',
                  },
                  {
                    title: 'Coordonnée Y',
                    key: 'coordY',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
