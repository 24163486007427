import {
  Description,
  Page,
  useMyUrl,
  useQuery,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryCameraArgs } from '../../../../client/graphql';
import { cameraGql } from '../gql/camera.gql';

export const CameraDetails = () => {
  const { cameraId } = useParams();
  const { getParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { camera: Query['camera'] },
    QueryCameraArgs
  >(cameraGql, {
    variables: {
      ...getParamsUrl,
      cameraId: cameraId || undefined,
    },
  });

  const camera = useMemo(() => {
    if (!data) return null;

    return data.camera;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['description']],
          rows: ['min-content'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'description' }}>
          <Widget
            state={{
              loading: loading,
              error: error,
              refetch,
            }}
            config={{ title: 'Details' }}
          >
            <Description
              data={
                camera
                  ? [
                      { key: '_id', label: 'ID', value: camera._id },
                      { key: 'code', label: 'Code', value: camera.code },
                      {
                        key: 'position',
                        label: 'Position',
                        value: camera.position,
                      },
                      {
                        key: 'description',
                        label: 'Description',
                        value: camera.description,
                      },
                      {
                        key: 'ups',
                        label: 'upsID',
                        value: camera.ups,
                      },
                      {
                        key: 'upsCode',
                        label: 'upsCode',
                        value: camera.upsCode,
                      },
                    ]
                  : []
              }
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
