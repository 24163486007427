import { gql } from '@apollo/client';

export const accountsGql = gql`
  query accounts($realm: String!) {
    accounts(realm: $realm) {
      accounts {
        firstName
        lastName
        username
        email
        emailVerified
        id
        rolesFlat
      }
    }
  }
`;
