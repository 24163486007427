import { Col, Icon } from '@gimlite/watermelon';
import { Button } from '@gimlite/watermelon/components/button/button.component';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Write } from '@gimlite/watermelon/components/write/write.component';
import {
  CreatePrestoScanWizardContext,
  spaceBeetwenInput,
} from '../createPrestoScan.wizard';
import './general.scss';

export const CamerasStep = ({
  context: { cameras, modeParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  return (
    <Form
      data={{ value: { cameras } }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ cameras: value.cameras });
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Write
          data={{ item: 'Configuration des caméras' }}
          config={{
            mode: 'title-small',
          }}
        ></Write>

        <Form.List
          data={{
            defaultValues: [],
          }}
          config={{
            name: 'cameras',
          }}
        >
          {(fields, { add, remove }, { errors }) => (
            <Col config={{ gap: 20, width: 'full' }}>
              {fields.map(({ name, key, ...restOfField }: any, index) => (
                <Row
                  key={key}
                  config={{
                    vertical: 'end',
                    horizontal: 'end',
                    width: 'full',
                    gap: 20,
                  }}
                >
                  <Form.Item
                    {...restOfField}
                    config={{
                      way: 'vertical',
                      label: 'Orientation',
                      name: [name, 'position'],
                      rules: [{ required: true }],
                    }}
                  >
                    <Select
                      data={{
                        items: [
                          {
                            value: 'inside',
                            label: "Pointe vers l'intérieur",
                          },
                          {
                            value: 'outside',
                            label: "Pointe vers l'extérieur",
                          },
                        ],
                      }}
                      config={{
                        height: 'xlarge',
                        width: 'medium',
                      }}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    {...restOfField}
                    config={{
                      way: 'vertical',
                      label: 'Code de la caméra',
                      name: [name, 'code'],
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'CH_4S_Velodrome_02',
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restOfField}
                    config={{
                      way: 'vertical',
                      label: 'Description de la caméra',
                      name: [name, 'description'],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                      }}
                    />
                  </Form.Item>

                  <div className="formListButtonContain">
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => remove(name)}
                    >
                      <Icon
                        config={{
                          type: 'faSquareMinusSolid',
                          size: 'large',
                          color: 'primary',
                        }}
                      />
                    </div>
                  </div>
                </Row>
              ))}

              <Row config={{ horizontal: 'center' }}>
                <Button
                  handleEvent={{
                    click: () => add(),
                  }}
                  config={{ text: 'Ajouter une caméra' }}
                />
              </Row>
            </Col>
          )}
        </Form.List>
      </Form.Structure>
    </Form>
  );
};
