import { Skeleton as SkeletonAnt } from 'antd';
import type { Size } from '../../types/component.type';
import './skeleton.component.scss';
export declare namespace SkeletonInputType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
      xMax?: number | string;
    };
  };
}

export const SkeletonInput = ({
  config: { size = 'medium', xMax = '50%' } = {},
  className = '',
}: SkeletonInputType.Props) => {
  const sizeFormatted = size === 'medium' ? 'default' : size;

  return (
    <SkeletonAnt.Input
      active={true}
      block={true}
      style={{ width: xMax }}
      size={sizeFormatted}
      className={`skeleton__input ${className}`}
    />
  );
};

export declare namespace SkeletonAvatarType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'small' | 'medium' | 'large'> | number;
    };
  };
}

export const SkeletonAvatar = ({
  config: { size = 'medium' } = {},
  className = '',
}: SkeletonAvatarType.Props) => {
  const sizeFormatted = size === 'medium' ? 'default' : size;

  return (
    <SkeletonAnt.Avatar
      active={true}
      size={sizeFormatted}
      className={`skeleton__avatar ${className}`}
    />
  );
};

export declare namespace SkeletonButtonType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
    };
  };
}

export const SkeletonButton = ({
  config: { size = 'medium' } = {},
  className = '',
}: SkeletonButtonType.Props) => {
  const sizeFormatted = size === 'medium' ? 'default' : size;

  return (
    <SkeletonAnt.Button
      active={true}
      size={sizeFormatted}
      className={`skeleton__button ${className}`}
    />
  );
};

export declare namespace SkeletonImageType {
  type Props = {
    className?: string;
  };
}

export const SkeletonImage = ({ className = '' }: SkeletonImageType.Props) => {
  return (
    <SkeletonAnt.Image
      active={true}
      className={`skeleton__image ${className}`}
    />
  );
};

export declare namespace SkeletonParagraphType {
  type Props = {
    className?: string;
  };
}

export const SkeletonParagraph = ({
  className = '',
}: SkeletonParagraphType.Props) => {
  return (
    <SkeletonAnt
      active={true}
      className={`skeleton__parapgraph ${className}`}
    />
  );
};

export declare namespace SkeletonLineType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
    };
  };
}

export const SkeletonLine = ({
  config: { size = 'medium' } = {},
  className = '',
}: SkeletonLineType.Props) => {
  return (
    <SkeletonAnt.Node
      active={true}
      className={`skeleton__line skeleton__line--${size} ${className}`}
    />
  );
};

export declare namespace SkeletonBlockType {
  type Props = {
    className?: string;
  };
}

export const SkeletonBlock = ({ className = '' }: SkeletonBlockType.Props) => {
  return (
    <SkeletonAnt.Node
      active={true}
      className={`skeleton__block ${className}`}
    />
  );
};
