import {
  Button,
  Description,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
  useRootStore,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Query, QueryClientArgs } from '../../../../../client/graphql';
import { clientGql } from '../../gql/client.gql';

export const ClientsDetailsUps = () => {
  const { clientId } = useParams();
  const { getParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql, {
    variables: { ...getParamsUrl, clientId },
  });

  const client = useMemo(() => {
    if (!data) return null;

    return data.client;
  }, [data]);

  return (
    <Zone
      config={{
        zones: [['details'], ['table']],
        rows: ['min-content', '1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'details' }}>
        <Widget
          state={{
            error: error,
            loading: loading,
            refetch: refetch,
          }}
          config={{ title: "Détails de l'UPS" }}
        >
          <Description
            data={[
              {
                key: 'ID',
                label: 'ID',
                value: client?._id,
              },
            ]}
          ></Description>
        </Widget>
      </Zone.Area>
      <Zone.Area config={{ area: 'table' }}>
        <Widget
          state={{
            error: error,
            loading: loading,
            refetch: refetch,
          }}
          config={{ title: 'Liste des UPS' }}
        >
          <Table<{
            _id: string;
            code: string;
            maximumAmount: number | null;
            maximumDuration: number | null;
          }>
            handleEvent={{
              read: (value: any) => {
                navigate(`/onstreet/ups/${value._id}`);
              },
            }}
            data={
              client?.ups
                ? {
                    list: client.ups.list.map(({ code, config, ...rest }) => {
                      const configOnstreet =
                        config && 'onstreet' in config ? config.onstreet : null;

                      const maximumAmount =
                        configOnstreet &&
                        typeof configOnstreet === 'object' &&
                        'maximumAmount' in configOnstreet &&
                        typeof configOnstreet.maximumAmount === 'number'
                          ? configOnstreet.maximumAmount
                          : null;

                      const maximumDuration =
                        configOnstreet &&
                        typeof configOnstreet === 'object' &&
                        'maximumDuration' in configOnstreet &&
                        typeof configOnstreet.maximumDuration === 'number'
                          ? configOnstreet.maximumDuration
                          : null;

                      return {
                        ...rest,
                        code,
                        maximumAmount,
                        maximumDuration,
                      };
                    }),
                    paging: client.ups.paging,
                  }
                : undefined
            }
            config={{
              actions: { read: true },
              columns: [
                {
                  title: 'code',
                  key: 'code',
                },
                {
                  title: 'maximumDuration',
                  key: 'maximumDuration',
                },
                {
                  title: 'maximumAmount',
                  key: 'maximumAmount',
                },
              ],
            }}
          />
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
