import { IconProp } from '@fortawesome/fontawesome-svg-core';
import * as iconsAwesomeRegular from '@fortawesome/free-regular-svg-icons';
import * as iconsAwesomeSolid from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import addOneMotoristToAnExistingPoolContract from '../../assets/icons/add-one-motorist-to-an-existing-pool-contract';
import addSeveralMotoristToAnExistingPoolContract from '../../assets/icons/add-several-motorist-to-an-existing-pool-contract';
import customAgent from '../../assets/icons/agent';
import cardUserBCDoubleLine from '../../assets/icons/card-user-bc-double-line';
import cardUserBRSingleLine from '../../assets/icons/card-user-br-single-line';
import cardUserTLSingleLine from '../../assets/icons/card-user-tl-single-line';
import createNewMasterPoolContract from '../../assets/icons/create-new-master-pool-contract';
import createNewSingleContract from '../../assets/icons/create-new-single-contract';
import createNewSubPoolContract from '../../assets/icons/create-new-sub-pool-contract';
import customDish from '../../assets/icons/dish';
import faviconPrestoScan from '../../assets/icons/favicon-presto-scan';
import googleAuthentificator from '../../assets/icons/google-authentificator';
import gridMenu from '../../assets/icons/grid-menu';
import horodatorSmartSolution from '../../assets/icons/horodator-smart-solution';
import customPieFull from '../../assets/icons/pie-full';
import customPiePartial from '../../assets/icons/pie-partial';
import partnerSkidata from '../../assets/icons/skidata';
import partnerTcs from '../../assets/icons/tcs';
import transmission from '../../assets/icons/transmission';
import type {
  Color2,
  Color as ColorGlobal,
  Size as SizeGlobal,
} from '../../types/component.type';
import './icon.component.scss';

const formattedAwesome = (pack: Record<string, any>, type: string) =>
  Object.fromEntries(
    Object.entries(pack).map(([key, value]) => [key + type, value]),
  );

const iconsCustom = {
  customAgent: customAgent(),
  customCardUserBCDoubleLine: cardUserBCDoubleLine(),
  customCardUserBRSingleLine: cardUserBRSingleLine(),
  customCardUserTLSingleLine: cardUserTLSingleLine(),
  customCreateNewMasterPoolContract: createNewMasterPoolContract(),
  customAddOneMotoristToAnExistingPoolContract:
    addOneMotoristToAnExistingPoolContract(),
  customAddSeveralMotoristToAnExistingPoolContract:
    addSeveralMotoristToAnExistingPoolContract(),
  customCreateNewSubPoolContract: createNewSubPoolContract(),
  customCreateNewSingleContract: createNewSingleContract(),
  customPieFull: customPieFull(),
  customPiePartial: customPiePartial(),
  customDish: customDish(),
  partnerTCS: partnerTcs(),
  partnerSKIDATA: partnerSkidata(),
  partnerGoogleAuthentificator: googleAuthentificator(),
  transmission: transmission(),
  horodatorSmartSolution: horodatorSmartSolution(),
  customGridMenu: gridMenu(),
  customFaviconPrestoScan: faviconPrestoScan(),
} as const;

const iconsAwesome = {
  ...formattedAwesome(iconsAwesomeSolid, 'Solid'),
  ...formattedAwesome(iconsAwesomeRegular, 'Regular'),
};

const icons = {
  ...iconsAwesome,
  ...iconsCustom,
};

export declare namespace IconType {
  type Props = {
    className?: string;
    config?: {
      type?: IconType.Config.Type;
      color?: IconType.Config.Color;
      size?: IconType.Config.Size;
    };
  };
  namespace Config {
    type Type =
      | `${keyof typeof iconsAwesomeSolid}Solid`
      | `${keyof typeof iconsAwesomeRegular}Regular`
      | keyof typeof iconsCustom;

    type Color =
      | Extract<
          ColorGlobal,
          | 'success'
          | 'default'
          | 'warn'
          | 'disabled'
          | 'info'
          | 'label'
          | 'error'
          | 'primary'
          | 'white'
          | 'black'
          | 'yin'
          | 'yang'
          | 'text'
          | 'primary-over'
        >
      | Color2;
    type Size =
      | Extract<
          SizeGlobal,
          | 'xxxsmall'
          | 'xxsmall'
          | 'xsmall'
          | 'small'
          | 'medium'
          | 'xmedium'
          | 'large'
          | 'xlarge'
          | 'xxlarge'
          | 'mega'
          | 'giga'
          | 'tera'
          | 'list'
        >
      | 'free'
      | {
          maxHeight: string;
          maxWidth: string;
        };
  }
}

export const Icon = ({
  config: { type = 'faKeySolid', size = 'medium', color } = {},
  className = '',
}: IconType.Props) => {
  const iconSelect = icons?.[`${type}`];

  const styleSize: React.CSSProperties =
    typeof size !== 'string'
      ? {
          height: '100%',
          width: '100%',
          maxWidth: `${size.maxWidth}`,
          maxHeight: `${size.maxHeight}`,
        }
      : {};

  return (
    <div
      style={{ ...styleSize }}
      className={`icon icon--color--${color} ${
        typeof size === 'string' ? `icon--size--${size}` : 'icon--free'
      } ${className}`}
    >
      {iconSelect && type?.startsWith('fa') ? (
        <FontAwesomeIcon className="icon__lib" icon={iconSelect as IconProp} />
      ) : (
        <div className="icon__custom">{iconSelect}</div>
      )}
    </div>
  );
};
