import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { buildFullSchemaGQL } from '../functions/request.function';
import { useRootStore } from '../hook/useRootStore.hook';
import { Languages } from '../types/lang.type';
import { GQLContextType } from './gql.store';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type DocumentType = {
  _id: Languages;
  lang: Languages;
  translations: Record<Languages, string>;
};

export type DictionaryType = {
  _id: string;
  documents: DocumentType[];
  key: string;
  languages: Languages[];
  createdAt: string;
};

export type I18NContextType = {
  lang: Languages;
  langsAvailable: Languages[];
  dictionary: DictionaryType;
};

export class I18NStoreMobX {
  rootStore: RootStoreMobX;
  @observable public lang: I18NContextType['lang'] = 'en_EN';
  @observable public langsAvailable: I18NContextType['langsAvailable'] = [
    'en_EN',
  ];
  @observable public dictionary: I18NContextType['dictionary'] | null = null;
  @observable public load: boolean = false;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public async setDictionary({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    try {
      const { data: dataI18n } = await client.query<{
        i18n: DictionaryType;
      }>({
        query: buildFullSchemaGQL({
          operationName: 'i18n',
          operationType: 'QUERY',
          introspection: introspection,
        }),
      });

      this.dictionary = dataI18n.i18n;
      this.langsAvailable = dataI18n.i18n.languages;
    } catch (error) {
      console.error("Erreur lors de l'initialisation de l'i18n:", error);
    }
  }

  @action public async init({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    this.load = false;
    await this.setDictionary({ client, introspection });
    this.load = true;
  }

  @action public updateLang(lang: Languages) {
    this.lang = lang;
  }
}

export const ConfigI18N = observer(
  ({ children }: { children: React.ReactNode }) => {
    const { GQLStore, I18NStore } = useRootStore();

    useEffect(() => {
      if (
        !I18NStore.load &&
        GQLStore.load &&
        GQLStore.client &&
        GQLStore.introspection
      ) {
        logWithState({ state: 'INFO', value: 'ConfigI18N init' });
        I18NStore.init({
          client: GQLStore.client!,
          introspection: GQLStore.introspection,
        });
      }

      if (I18NStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigI18N load' });
      }
    }, [
      I18NStore.load,
      GQLStore.load,
      GQLStore.client,
      GQLStore.introspection,
    ]);

    return I18NStore.load ? children : null;
  },
);
