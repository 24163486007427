import {
  Blurb,
  ListFlatSingleCheck,
  Row,
  useTranslation,
  Zone,
} from '@gimlite/watermelon';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useEffect, useMemo } from 'react';
import { CreateUserWizardContext } from '../createUser.wizard';

const typesOfModules = [
  {
    key: 'module_equipment',
    title: 'Module Équipment',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    roles: ['app-view-module_equipment', 'app-use-module_equipment'],
  },
  {
    key: 'module_finance',
    title: 'Module Finance',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    roles: ['app-view-module_finance', 'app-use-module_finance'],
  },
  {
    key: 'module_transaction',
    title: 'Module Transaction',
    description:
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    roles: ['app-view-module_transaction', 'app-use-module_transaction'],
  },
];

export const TypeStep = ({
  context: { accounts, selectedAccount, selectedType },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateUserWizardContext>) => {
  const { t } = useTranslation();

  const currentAccount = useMemo(() => {
    return accounts.find(({ _id }) => _id === selectedAccount);
  }, [accounts, selectedAccount]);

  useEffect(() => {
    validate(!!selectedType);
  }, [selectedType]);

  return (
    <Zone
      config={{
        zones: [['list']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'list' }}>
        <ListFlatSingleCheck<'component'>
          config={{
            pagination: 'scroll',
            columns: [{ key: 'component', label: t('identity'), size: '1fr' }],
            header: true,
          }}
          handleEvent={{
            paging: (paging) => {},
            click: () => {},
            checkItem: (_id) => {
              submit({ selectedType: _id });
            },
          }}
          data={{
            checkItem: selectedType,
            list: typesOfModules
              .filter(({ roles }) =>
                roles.every((role) => currentAccount?.roles.includes(role)),
              )
              .map(({ key, title, description }) => ({
                _id: key,
                items: {
                  component: (
                    <Row config={{ gap: 10, vertical: 'center' }}>
                      <Blurb
                        data={{
                          title: `${title}`,
                          description: description,
                        }}
                      />
                    </Row>
                  ),
                },
              })),
            paging: {
              count: typesOfModules.length,
              current: 1,
              limit: typesOfModules.length,
            },
          }}
        />
      </Zone.Area>
    </Zone>
  );
};
