import {
  Col,
  Row,
  Space,
  useMyUrl,
  useQuery,
  Widget,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QuerySiteArgs } from '../../../../../client/graphql';
import { FeedbackChart } from '../../../../charts/feedback.chart';
import { siteFeedbacksGql } from '../../gql/tab/feedbacks.gql';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsFeedback = () => {
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();
  const { data } = useQuery<{ site: Query['site'] }, QuerySiteArgs>(
    siteFeedbacksGql,
    {
      variables: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
    },
  );
  const site = useMemo(() => {
    if (!data) return null;

    const {
      site: { ...rest },
    } = data;

    return {
      ...rest,
    };
  }, [data]);

  return (
    <Col>
      <Row>
        <SitesDetailsFilter />
      </Row>
      <Space config={{ count: 2 }} />

      <Row>
        <Col>
          {site?.feedbacks?.list?.map(
            ({ title, items }: any, index: number) => {
              return (
                <>
                  <Widget config={{ title, yMax: '400px' }}>
                    <FeedbackChart data={items} />
                  </Widget>

                  {site?.feedbacks?.list &&
                    index !== site?.feedbacks?.list?.length - 1 && (
                      <Space config={{ count: 2 }} />
                    )}
                </>
              );
            },
          )}
        </Col>
      </Row>
    </Col>
  );
};
