import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import { CreateAdminUserWizardContext } from '../createAdminUser.wizard';
import { Row, Space } from '@gimlite/watermelon';

export const CredentialsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateAdminUserWizardContext>) => {
  const [passwordValue, setPasswordValue] = useState('');

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'username',
              label: 'Email',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Jhon@snow.ch',
                height: 'xlarge',
                width: 'full',
              }}
              onChange={(value: string) => setPasswordValue(value)}
              data={{ defaultValue: passwordValue }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'password',
              label: 'Mot de passe',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'password',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
