import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  CameraPositionEnum,
  Mutation,
  MutationCreateCameraArgs,
  UpsEntity,
} from '../../../client/graphql.js';
import { createCameraGql } from './gql/createCamera.gql';
import { CameraStep } from './steps/camera.step';

export type CreateCameraWizardContext = {
  camera: {
    code: string | null;
    position: 'inside' | 'outside';
    ups: string | null;
    description: string | null;
  };
  listParking: Array<UpsEntity>;
  selectParking: {
    _id: string | null;
  };
};

export const CreateCameraWizard = () => {
  const { WizardStore } = useRootStore();

  const [createCamera, createCameraState] = useMutation<
    Mutation['createCamera'],
    MutationCreateCameraArgs
  >(createCameraGql);

  const state = useMemo(() => {
    if (createCameraState.loading) return undefined;
    if (createCameraState.error) return false;
    if (createCameraState.data) return true;
  }, [createCameraState]);

  return (
    <Wizard<CreateCameraWizardContext>
      config={{
        title: "Création d'une caméra",
        withValidation: {
          state,
          success: 'La caméra a été créée avec succès',
          error: 'Erreur lors de la création de la caméra',
        },
      }}
      data={{
        defaultContext: {
          camera: {
            code: null,
            position: 'inside',
            ups: null,
            description: null,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ camera, listParking, selectParking }) => {
          try {
            await createCamera({
              variables: {
                input: {
                  code: camera.code!,
                  description: camera.description!,
                  position: camera.position as CameraPositionEnum,
                  ups: selectParking._id!,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Camera', component: CameraStep }} />
    </Wizard>
  );
};
