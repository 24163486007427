import { gql } from '@apollo/client';
import { CSVParkingRightsResponseDto } from '../../../../../../../bff-admin/src/parking-rights/dto/csv-parking-rights.response.dto';

export type CSVParkingRightsResponse = {
  downloadURL: CSVParkingRightsResponseDto['downloadURL'];
};

export const csvParkingRightsGql = gql`
  query csvParkingRights($clientId: String) {
    csvParkingRights(clientId: $clientId) {
      downloadURL
    }
  }
`;
