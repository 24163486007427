import { gql } from '@apollo/client';
import { fragmentsEntity } from '../entity/fragments.gql';

export const siteFragmentsGql = gql`
  query site(
    $siteId: String!
    $fragmentsPage: Int
    $fragmentsLimit: Int
    $startDate: String
    $stopDate: String
  ) {
    site(siteId: $siteId) {
      _id
     ${fragmentsEntity}
    }
  }
`;
