import {
  AutoComplete,
  Form,
  Input,
  useLazyQuery,
  WizardItemType,
  Zone,
} from '@gimlite/watermelon';
import { useEffect } from 'react';
import {
  Query,
  QueryClientArgs,
  QueryClientsArgs,
} from '../../../../client/graphql';
import { clientsGql } from '../../../pages/clients/gql/clients.gql';
import { CreatePrestoScanWizardContext } from '../createPrestoScan.wizard';
import { clientGql } from '../gql/client.gql';
import './general.scss';

export const ClientStep = ({
  context: { createClient, modeClient, searchClient, listClient },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const [clients, clientsState] = useLazyQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    notification: {
      error: 'Erreur lors de la recherche des clients',
    },
  });

  const [client, clientState] = useLazyQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql, {
    notification: {
      error: 'Erreur lors de la lecture du client',
    },
  });

  useEffect(() => {
    if (clientsState.data) {
      submit({
        listClient: clientsState.data.clients.list,
      });
    }
  }, [clientsState.data]);

  useEffect(() => {
    if (clientState.data) {
      submit({
        searchClient: clientState.data.client,
      });
    }
  }, [clientState.data, clientState.count]);

  useEffect(() => {
    validate(
      searchClient
        ? !!listClient.find((element) => element._id === searchClient._id)
        : false,
    );
  }, [searchClient, listClient]);

  return (
    <>
      <Zone
        config={{
          gap: {
            y: 2,
          },
          zones: [['filter'], ['form']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          {/* <Segmented
            handleEvent={{
              option: (value) => {
                validate(true);
                submit({
                  modeParking: value as 'search' | 'create',
                  modeClient: value as 'search' | 'create',
                  searchClient: { _id: null, siret: null, slug: null },
                  createClient: {
                    code: null,
                    defaultContractTag: null,
                    defaultCurrency: null,
                    locale: null,
                    maximumAmount: null,
                    maximumDuration: null,
                    name: null,
                    siret: null,
                    slug: null,
                    ticketsHub: null,
                    timezone: null,
                    translation: [],
                  },
                });
              },
            }}
            data={{ selected: modeClient }}
            config={{
              options: [
                {
                  value: 'search',
                  label: 'Trouver un client',
                },
                {
                  value: 'create',
                  label: 'Créer un client',
                },
              ],
            }}
          /> */}
          <></>
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          {modeClient === 'search' && (
            <Form
              data={{
                value:
                  searchClient && Object.keys(searchClient).length
                    ? searchClient
                    : null,
              }}
              config={{ validateOnChange: true }}
              handleEvent={{
                change: (
                  value: CreatePrestoScanWizardContext['searchClient'],
                ) => {},
                validate: (value) => {},
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'slug',
                    label: 'Nom du client sur Yoonite',
                    rules: [{ required: true }],
                  }}
                >
                  <AutoComplete
                    handleEvent={{
                      input: (slug) => {
                        clients({
                          variables: {
                            slug,
                          },
                        });

                        submit({
                          searchClient: null,
                          modeClient: 'search',
                        });
                      },
                      select: (item) => {
                        if (item?.value) {
                          client({
                            variables: { clientId: item.value },
                          });

                          submit({
                            searchParking: null,
                            listParking: [],
                            modeParking: 'search',
                          });
                        }
                      },
                    }}
                    data={{
                      items: listClient.map(({ _id, slug }) => ({
                        label: slug,
                        value: _id,
                      })),
                    }}
                    config={{
                      search: {
                        caseSensitive: false,
                        accentSensitive: false,
                        pattern: 'start',
                      },
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'plan-les-ouates',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'siret',
                    label: 'SIRET',
                  }}
                >
                  <Input
                    config={{
                      disabled: true,
                      height: 'xlarge',
                      width: 'full',
                    }}
                  />
                </Form.Item>
              </Form.Structure>
            </Form>
          )}
          {/* {modeClient === 'create' && (
            <Form
              data={{ value: createClient }}
              config={{ validateOnChange: true }}
              handleEvent={{
                change: (
                  value: CreatePrestoScanWizardContext['createClient'],
                ) => {
                  submit({
                    createClient: value,
                    modeClient: 'create',
                  });
                },
                validate: (value) => {
                  validate(value);
                },
              }}
            >
              <Form.Structure
                config={{ layout: 'column', width: 'full', fill: true }}
              >
                <Write
                  data={{ item: 'Identité du client' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'name',
                      label: 'Nom',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Annemasse',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'code',
                      label: 'Code',
                      rules: [{ required: true }, { min: 1, max: 4 }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'PLO1',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'slug',
                      label: 'Slug',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'plan-les-ouates',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'siret',
                      label: 'SIRET',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '12345678910111',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'defaultContractTag',
                      label: 'Tag du contrat',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'iem/plan-les-ouates/onstreet',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'locale',
                      label: 'Langue',
                      rules: [{ required: true }],
                    }}
                  >
                    <Select
                      data={{ items: langsItemsSelect }}
                      config={{
                        height: 'xlarge',
                        width: 'full',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'defaultCurrency',
                      label: 'Devise monétaire',
                      rules: [{ required: true }, { len: 3 }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'CHF',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'timezone',
                      label: 'Fuseau horaire',
                      rules: [{ required: true }, { validator: isValidIANA }],
                    }}
                  >
                    <Input
                      config={{
                        height: 'xlarge',
                        width: 'full',
                        placeholder: 'Europe/Paris',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Traduction du nom' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Form.List
                  data={{
                    defaultValues: [
                      { lang: null, name: null, description: null },
                    ],
                  }}
                  config={{
                    name: 'translation',
                  }}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map(
                        ({ name, key, ...restOfField }: any, index) => (
                          <div key={key}>
                            {index !== 0 && (
                              <Space
                                config={{ count: 2, way: 'vertical' }}
                              ></Space>
                            )}

                            <Row
                              config={{ vertical: 'end', horizontal: 'end' }}
                            >
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Langue',
                                  name: [name, 'lang'],
                                  rules: [{ required: true }],
                                }}
                              >
                                <Select
                                  data={{
                                    items: formattedTranslateLang,
                                  }}
                                  config={{
                                    height: 'xlarge',
                                    width: 'medium',
                                  }}
                                ></Select>
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Nom du client',
                                  name: [name, 'name'],
                                  rules: [{ required: true }],
                                }}
                              >
                                <Input
                                  config={{
                                    height: 'xlarge',
                                    width: 'full',
                                    placeholder: 'Annemasse',
                                  }}
                                />
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <Form.Item
                                {...restOfField}
                                config={{
                                  way: 'vertical',
                                  label: 'Description du client',
                                  name: [name, 'description'],
                                }}
                              >
                                <Input
                                  config={{
                                    height: 'xlarge',
                                    width: 'full',
                                    placeholder: '',
                                  }}
                                />
                              </Form.Item>
                              <Space
                                config={{
                                  count: spaceBeetwenInput,
                                  way: 'horizontal',
                                }}
                              ></Space>
                              <div className="upsTranslateDeleteContain">
                                <MinusCircleOutlined
                                  style={{ fontSize: '25px' }}
                                  onClick={() => remove(name)}
                                />
                              </div>
                            </Row>
                          </div>
                        ),
                      )}
                      <Space config={{ count: 2, way: 'vertical' }}></Space>
                      {createClient.translation.length <
                      formattedTranslateLang.length ? (
                        <Row config={{ horizontal: 'center' }}>
                          <Button
                            handleEvent={{
                              click: () => add(),
                            }}
                            config={{ text: 'Ajouter une traduction' }}
                          />
                        </Row>
                      ) : null}
                    </>
                  )}
                </Form.List>
                <Write
                  data={{ item: 'Configuration du centre de billeterie' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>
                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'ticketsHub',
                      label: 'Système',
                      rules: [{ required: true }],
                    }}
                  >
                    <Select
                      data={{
                        items: [
                          { value: 'iem', label: 'IEM' },
                          { value: 'prestopark', label: 'PrestoPark' },
                        ],
                      }}
                      config={{
                        height: 'xlarge',
                        width: 'medium',
                      }}
                    />
                  </Form.Item>
                </Row>
                <Space
                  config={{ count: spaceBeetwenTitle, way: 'vertical' }}
                ></Space>
                <Write
                  data={{ item: 'Configuration de Yoonite' }}
                  config={{
                    mode: 'title-small',
                  }}
                ></Write>

                <Row>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'maximumDuration',
                      label: 'Durée maximum',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '180',
                      }}
                    />
                  </Form.Item>
                  <Space
                    config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                  ></Space>
                  <Form.Item
                    config={{
                      way: 'vertical',
                      name: 'maximumAmount',
                      label: 'Montant maximum',
                      rules: [{ required: true }],
                    }}
                  >
                    <Input
                      config={{
                        type: { name: 'number' },
                        height: 'xlarge',
                        width: 'full',
                        placeholder: '375',
                      }}
                    />
                  </Form.Item>
                </Row>
              </Form.Structure>
            </Form>
          )} */}
        </Zone.Area>
      </Zone>
    </>
  );
};
