import { Form, Input, Select, WizardItemType } from '@gimlite/watermelon';
import { UpdateControlUserWizardContext } from '../updateControlUser.wizard';
import { Row, Space } from '@gimlite/watermelon';

export const DetailsStep = ({
  context: { user, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateControlUserWizardContext>) => {
  return (
    <Form
      data={{ value: user }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ user: { ...value, _id: user._id } });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'firstName',
              label: 'Prénom',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Jhon',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'lastName',
              label: 'Nom de famille',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Doe',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'fpsAgentId',
              label: "Identifiant de l'agent",
              rules: [
                {
                  required: true,
                  pattern: /^\d{3}$/,
                },
              ],
            }}
          >
            <Input
              config={{
                placeholder: '245',
                type: { name: 'number', min: 100 },
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'fpsAgentName',
              label: "Nom de l'agent",
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Mr smith',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'lang',
              label: 'Langue',
              rules: [{ required: true }],
            }}
          >
            <Select
              data={{
                items: [
                  { label: 'FR', value: 'fr_FR' },
                  { label: 'EN', value: 'en_EN' },
                  { label: 'DE', value: 'de_DE' },
                  { label: 'IT', value: 'it_IT' },
                ],
              }}
              config={{
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
