import {
  ApolloClient,
  ApolloProvider,
  FetchResult as FetchResultApollo,
  gql,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { DocumentNode, getIntrospectionQuery } from 'graphql';
import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type FetchResult<T> = FetchResultApollo<T>;

export declare namespace GQLType {
  type OfType = {
    kind: string;
    name?: string;
    ofType?: GQLType.OfType;
  };

  type Field = {
    name: string;
    type?: GQLType.OfType;
  };

  type Arg = {
    name: string;
    type?: GQLType.OfType;
  };

  type Type = {
    kind: string;
    name: string;
    fields?: GQLType.Field[];
    args: GQLType.Arg[];
  };
}

export type GQLContextType = {
  uri: string;
  headers: {
    'x-access-token'?: string | null;
    authorization?: string | null;
  };
  client: ApolloClient<any>;
  introspection: DocumentNode & {
    types: GQLType.Type[];
  };
};

export class GQLStoreMobX {
  rootStore: RootStoreMobX;
  @observable public introspection: GQLContextType['introspection'] | null =
    null;
  @observable public load: boolean = false;
  @observable private httpLink: HttpLink | null = null;
  @observable private headersLink: Object = {};
  @observable private authLink = setContext((request, previousContext) => ({
    ...previousContext.headers,
  }));
  @observable public client: GQLContextType['client'] | null = null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public async init({
    uri,
    headers,
  }: Pick<GQLContextType, 'uri' | 'headers'>) {
    this.load = false;

    await this.setClient({
      uri,
      headers,
    });

    this.load = true;
  }

  @action private async setClient({
    uri,
    headers,
  }: Pick<GQLContextType, 'uri' | 'headers'>) {
    this.httpLink = new HttpLink({ uri: `${uri}/graphql` });

    this.headersLink = {
      ...(headers || {}),
    };

    this.authLink = setContext((request, previousContext) => ({
      headers: {
        ...this.headersLink,
      },
    }));

    this.client = new ApolloClient<NormalizedCacheObject>({
      link: this.authLink.concat(this.httpLink),
      cache: new InMemoryCache(),
    });

    const introspection = await this.client.query({
      query: gql`
        ${getIntrospectionQuery()}
      `,
    });

    this.introspection = introspection.data.__schema;
  }

  @action public async updateHeaders(value: { [key: string]: string | null }) {
    this.headersLink = {
      ...this.headersLink,
      ...value,
    };
  }
}

export const ConfigGQL = observer(
  ({
    children,
    uri,
    headers,
  }: { children: React.ReactNode } & Pick<
    GQLContextType,
    'uri' | 'headers'
  >) => {
    const { GQLStore } = useRootStore();

    useEffect(() => {
      (async () => {
        if (!GQLStore.load) {
          logWithState({ state: 'INFO', value: 'ConfigGQL init' });
          await GQLStore.init({ uri, headers });
        }

        if (GQLStore.load) {
          logWithState({ state: 'INFO', value: 'ConfigGQL load' });
        }
      })();
    }, [GQLStore.load]);

    if (!GQLStore.client) return null;

    return GQLStore.load ? (
      <ApolloProvider client={GQLStore.client}>{children}</ApolloProvider>
    ) : null;
  },
);
