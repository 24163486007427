import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useMemo } from 'react';
import {
  Mutation,
  MutationCreateTechnicalUsersArgs,
} from '../../../client/graphql';
import { createPmsTechnicalUserGql } from './gql/createPmsTechnicalUser.gql';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';

export type CreatePmsTechnicalUserWizardContext = any;

export const CreatePmsTechnicalUserWizard = () => {
  const { WizardStore } = useRootStore();

  const [createTechnicalUser, createTechnicalUserState] = useMutation<
    Mutation['createTechnicalUsers'],
    MutationCreateTechnicalUsersArgs
  >(createPmsTechnicalUserGql);

  const state = useMemo(() => {
    if (createTechnicalUserState.loading) return undefined;
    if (createTechnicalUserState.error) return false;
    if (createTechnicalUserState.data) return true;
  }, [createTechnicalUserState]);

  return (
    <Wizard<CreatePmsTechnicalUserWizardContext>
      config={{
        title: 'Créer un technicien',
        withValidation: {
          state,
          success: 'Le technicien a été créé avec succès',
          error: 'Une erreur est survenue lors de la création du technicien',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value) => {
          try {
            await createTechnicalUser({
              variables: {
                input: value,
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
