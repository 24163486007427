import { RootStoreContext } from '../hook/useRootStore.hook';
import { AntStoreMobX } from './ant.store';
import { AppStoreMobX } from './app.store';
import { AuthStoreMobX } from './auth.store';
import { GQLStoreMobX } from './gql.store';
import { I18NStoreMobX } from './i18n.store';
import { MapsStoreMobX } from './maps.store';
import { MenuStoreMobX } from './menu.store';
import { NotificationStoreMobX } from './notification.store';
import { SocketStoreMobX } from './socket.store';
import { SSOStoreMobX } from './sso.store';
import { ThemeStoreMobX } from './theme.store';
import { WizardStoreMobX } from './wizard.store';

export class RootStoreMobX {
  AppStore: AppStoreMobX;
  ThemeStore: ThemeStoreMobX;
  AuthStore: AuthStoreMobX;
  SSOStore: SSOStoreMobX;
  GQLStore: GQLStoreMobX;
  I18NStore: I18NStoreMobX;
  MenuStore: MenuStoreMobX;
  NotificationStore: NotificationStoreMobX;
  WizardStore: WizardStoreMobX;
  SocketStore: SocketStoreMobX;
  AntStore: AntStoreMobX;
  MapsStore: MapsStoreMobX;

  constructor() {
    this.AppStore = new AppStoreMobX(this);
    this.ThemeStore = new ThemeStoreMobX(this);
    this.AuthStore = new AuthStoreMobX(this);
    this.SSOStore = new SSOStoreMobX(this);
    this.GQLStore = new GQLStoreMobX(this);
    this.I18NStore = new I18NStoreMobX(this);
    this.MenuStore = new MenuStoreMobX(this);
    this.NotificationStore = new NotificationStoreMobX(this);
    this.WizardStore = new WizardStoreMobX(this);
    this.SocketStore = new SocketStoreMobX(this);
    this.AntStore = new AntStoreMobX(this);
    this.MapsStore = new MapsStoreMobX(this);
  }
}

export const RootStore: RootStoreMobX = new RootStoreMobX();

export const ConfigRoot = ({ children }: { children: React.ReactNode }) => {
  return (
    <RootStoreContext.Provider value={RootStore ?? new RootStoreMobX()}>
      {children}
    </RootStoreContext.Provider>
  );
};
