import {
  AutoComplete,
  Form,
  Input,
  Row,
  Segmented,
  Select,
  SelectType,
  Space,
  useLazyQuery,
  WizardItemType,
  Write,
  Zone,
} from '@gimlite/watermelon';
import {
  digitRule,
  isValidEmail,
  lowercaseLetterRule,
  minLengthPasswordRule,
  specialCharRule,
  uppercaseLetterRule,
} from '@gimlite/watermelon/functions/validator.function';
import { useEffect, useMemo } from 'react';
import {
  Query,
  QueryControlUserArgs,
  QueryControlUsersArgs,
} from '../../../../client/graphql';
import {
  CreatePrestoScanWizardContext,
  langsItemsSelect,
  spaceBeetwenInput,
  spaceBeetwenTitle,
} from '../createPrestoScan.wizard';
import { controlUserGql } from '../gql/control-user.gql';
import { controlUsersGql } from '../gql/control-users.gql';
import './general.scss';

export const AgentsStep = ({
  context: { createAgent, modeAgent, searchAgent, listAgent },
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePrestoScanWizardContext>) => {
  const [controlUsers, controlUsersState] = useLazyQuery<
    { controlUsers: Query['controlUsers'] },
    QueryControlUsersArgs
  >(controlUsersGql, {
    notification: {
      error: 'Erreur lors de la recherche des agents',
    },
  });

  const [controlUser, controlUserState] = useLazyQuery<
    { controlUser: Query['controlUser'] },
    QueryControlUserArgs
  >(controlUserGql, {
    notification: {
      error: "Erreur lors de la lecture de l'agent",
    },
  });

  const formattedTranslateLang = useMemo(
    () =>
      langsItemsSelect.map(
        ({ value: langBase, label }: SelectType.Data.Item) => ({
          value: langBase,
          label,
        }),
      ),
    [createAgent],
  );

  useEffect(() => {
    if (controlUsersState.data) {
      submit({
        listAgent: controlUsersState.data.controlUsers.list,
      });
    }
  }, [controlUsersState.data]);

  useEffect(() => {
    if (controlUserState.data) {
      submit({
        searchAgent: controlUserState.data.controlUser,
      });
    }
  }, [controlUserState.data, controlUserState.count]);

  return (
    <Zone
      config={{
        gap: {
          y: 2,
        },
        zones: [['filter'], ['form']],
        rows: ['min-content', '1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'filter' }}>
        <Segmented
          handleEvent={{
            option: (value) => {
              submit({
                modeAgent: value as 'search' | 'create',
                createAgent: null,
                searchAgent: null,
              });
            },
          }}
          data={{ selected: modeAgent }}
          config={{
            options: [
              {
                value: 'search',
                label: 'Trouver un agent',
              },
              {
                value: 'create',
                label: 'Créer un agent',
              },
            ],
          }}
        />
      </Zone.Area>

      <Zone.Area config={{ area: 'form' }}>
        {modeAgent === 'search' && (
          <Form
            data={{ value: searchAgent }}
            config={{ validateOnChange: true }}
            handleEvent={{
              change: (value) => {
                submit({ searchAgent: value });
              },
              validate: (value) => {
                validate(value);
              },
            }}
          >
            <Form.Structure
              config={{ layout: 'column', width: 'full', fill: true }}
            >
              <Form.Item
                config={{
                  way: 'vertical',
                  name: 'username',
                  label: "Email de l'agent",
                  rules: [{ required: true }],
                }}
              >
                <AutoComplete
                  handleEvent={{
                    input: (value) => {
                      controlUsers({
                        variables: {
                          usernamePattern: value,
                        },
                      });
                    },
                    select(item) {
                      if (item?.value) {
                        controlUser({
                          variables: { userId: item.value },
                        });
                      }
                    },
                  }}
                  data={{
                    items: listAgent.map(({ _id, username }) => ({
                      value: _id,
                      label: username,
                    })),
                  }}
                  config={{
                    search: {
                      caseSensitive: false,
                      accentSensitive: false,
                      pattern: 'start',
                    },
                    height: 'xlarge',
                    width: 'full',
                    placeholder: 'control@user.com',
                  }}
                />
              </Form.Item>
            </Form.Structure>
          </Form>
        )}

        {modeAgent === 'create' && (
          <Form
            data={{ value: createAgent }}
            config={{ validateOnChange: true }}
            handleEvent={{
              change: (value) => {
                submit({ createAgent: value });
              },
              validate: (value) => {
                validate(value);
              },
            }}
          >
            <Form.Structure
              config={{ layout: 'column', width: 'full', fill: true }}
            >
              <Write
                data={{ item: "Identité personelle de l'agent" }}
                config={{
                  mode: 'title-small',
                }}
              ></Write>
              <Row>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'lang',
                    label: 'Langue de prédilection',
                    rules: [{ required: true }],
                  }}
                >
                  <Select
                    data={{
                      items: formattedTranslateLang,
                    }}
                    config={{
                      height: 'xlarge',
                      width: 'medium',
                    }}
                  ></Select>
                </Form.Item>
                <Space
                  config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                ></Space>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'firstName',
                    label: 'Prénom',
                    rules: [{ required: true }],
                  }}
                >
                  <Input
                    config={{
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'Jean',
                    }}
                  />
                </Form.Item>
                <Space
                  config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                ></Space>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'lastName',
                    label: 'Nom de famille',
                    rules: [{ required: true }],
                  }}
                >
                  <Input
                    config={{
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'Clavin',
                    }}
                  />
                </Form.Item>
              </Row>
              <Space
                config={{ count: spaceBeetwenTitle, way: 'vertical' }}
              ></Space>
              <Write
                data={{ item: "Identité profesionnelle de l'agent" }}
                config={{
                  mode: 'title-small',
                }}
              ></Write>

              <Row>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'fpsAgentId',
                    label:
                      "Identifiant agent figurant sur le FPS reçu par l'usager",
                    rules: [
                      {
                        required: true,
                        pattern: /^\d{3}$/,
                      },
                    ],
                  }}
                >
                  <Input
                    config={{
                      type: { name: 'number', min: 100 },
                      height: 'xlarge',
                      width: 'full',
                      placeholder: '154',
                    }}
                  />
                </Form.Item>
                <Space
                  config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                ></Space>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'fpsAgentName',
                    label: "Pseudonyme de l'agent pour l'ANTAI",
                    rules: [{ required: true }],
                  }}
                >
                  <Input
                    config={{
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'myAgentName',
                    }}
                  />
                </Form.Item>
              </Row>
              <Space
                config={{ count: spaceBeetwenTitle, way: 'vertical' }}
              ></Space>
              <Write
                data={{ item: 'Informations de connexion' }}
                config={{
                  mode: 'title-small',
                }}
              ></Write>

              <Row>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'username',
                    label: 'Adresse email',
                    rules: [{ required: true }, { validator: isValidEmail }],
                  }}
                >
                  <Input
                    config={{
                      height: 'xlarge',
                      width: 'full',
                      placeholder: 'control@user.com',
                    }}
                  />
                </Form.Item>
                <Space
                  config={{ count: spaceBeetwenInput, way: 'horizontal' }}
                ></Space>
                <Form.Item
                  config={{
                    way: 'vertical',
                    name: 'password',
                    label:
                      'Mot de passe (min: 1, maj: 1, chiffre: 1, caract. spéciaux: 1, taille: 8)',
                    rules: [
                      { required: true },
                      {
                        validator: minLengthPasswordRule,
                      },
                      {
                        validator: uppercaseLetterRule,
                      },
                      {
                        validator: lowercaseLetterRule,
                      },
                      {
                        validator: digitRule,
                      },
                      {
                        validator: specialCharRule,
                      },
                    ],
                  }}
                >
                  <Input
                    config={{
                      type: { name: 'password' },
                      height: 'xlarge',
                      width: 'full',
                    }}
                  />
                </Form.Item>
              </Row>
            </Form.Structure>
          </Form>
        )}
      </Zone.Area>
    </Zone>
  );
};
