import {
  Col,
  Row,
  Space,
  Statistic,
  useMyUrl,
  useQuery,
  Widget,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QuerySiteArgs } from '../../../../../client/graphql';
import { AveragePowerChart } from '../../../../charts/averagePower.chart';
import { BalanceAveragePowerChart } from '../../../../charts/balanceAveragePower.chart';
import { PeripheralTimeChart } from '../../../../charts/peripheralTime.chart';
import { TensionChart } from '../../../../charts/tension.chart';
import { WeatherChart } from '../../../../charts/weather.chart';
import { siteEnergieGql } from '../../gql/tab/energie.gql';
import { energieGlobalRender } from '../../render/energie.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsEnergy = () => {
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  const { data, loading, error, refetch } = useQuery<
    { site: Query['site'] },
    QuerySiteArgs
  >(siteEnergieGql, {
    variables: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
  });

  const site = useMemo(() => {
    if (!data) return null;

    const {
      site: { energies, ...rest },
    } = data;

    return {
      ...rest,
      ...energieGlobalRender(energies),
    };
  }, [data]);

  return (
    <Col config={{ scrollY: true }}>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />
      <Row>
        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Charge' }}
        >
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: site?.stats?.prod }}
          />
        </Widget>
        <Space config={{ count: 2, way: 'horizontal' }} />
        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Consommation' }}
        >
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: site?.stats?.conso }}
          />
        </Widget>
        <Space config={{ count: 2, way: 'horizontal' }} />

        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Balance' }}
        >
          <Statistic
            config={{
              precision: 2,
              way: true,
              suffix: 'mW',
              explain: 'Puissance moyenne sur la période sélectionnée',
            }}
            data={{ value: site?.stats?.balance }}
          />
        </Widget>
      </Row>
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Météo' }}
      >
        <WeatherChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={site?.meteo || []}
        ></WeatherChart>
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Bilan de consommation (puissance moyenne)' }}
      >
        <BalanceAveragePowerChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={site?.tMachine || []}
        ></BalanceAveragePowerChart>
      </Widget>

      <Space config={{ count: 2 }} />
      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Tension' }}
      >
        <TensionChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={site?.tensions || []}
        />
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Puissance moyenne' }}
      >
        <AveragePowerChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={site?.puissance || []}
        ></AveragePowerChart>
      </Widget>

      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Temps périphériques' }}
      >
        <PeripheralTimeChart
          config={{
            startDate: getParamsUrl?.startDate,
            endDate: getParamsUrl?.stopDate,
          }}
          data={site?.tPeriph || []}
        />
      </Widget>
    </Col>
  );
};
