import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  Mutation,
  MutationUpdateOperatorArgs,
  OperatorEntity,
} from '../../../client/graphql';
import { base64ToUrl } from '../../common/base64Url';
import { updateOperatorGql } from './gql/updateOperator.gql';
import { OperatorStep } from './steps/operator.step';

export type UpdateOperatorWizardContext = {
  operator: {
    operatorId: string;
    name: string | null;
    code: string | null;
    operatorCode: string | null;
    email: string | null;
    phone: string | null;
    logoMain: Array<[string, string]> | null;
    logoSecondary: Array<[string, string]> | null;
    translation: OperatorEntity['translation'] | null;
  };
};
export const UpdateOperatorWizard = () => {
  const { WizardStore } = useRootStore();

  const [updateOperator, updateOperatorState] = useMutation<
    Mutation['updateOperator'],
    MutationUpdateOperatorArgs
  >(updateOperatorGql);

  const state = useMemo(() => {
    if (updateOperatorState.loading) return undefined;
    if (updateOperatorState.error) return false;
    if (updateOperatorState.data) return true;
  }, [updateOperatorState]);

  const {
    operatorId,
    name,
    code,
    operatorCode,
    email,
    phone,
    logoMain,
    logoSecondary,
    translation,
  } = WizardStore.selected!.store;

  return (
    <Wizard<UpdateOperatorWizardContext>
      config={{
        title: 'Mettre à jour un opérateur',
        withValidation: {
          state,
          success: "L'opérateur a été mis à jour avec succès",
          error: "Erreur lors de la mise à jour de l'opérateur",
        },
      }}
      data={{
        defaultContext: {
          operator: {
            operatorId,
            name,
            code,
            operatorCode,
            email,
            phone,
            logoMain:
              logoMain &&
              typeof logoMain === 'string' &&
              Array.isArray(logoMain?.split('/'))
                ? [[logoMain.split('/').reverse()[0], logoMain]]
                : null,
            logoSecondary:
              logoSecondary &&
              typeof logoSecondary === 'string' &&
              Array.isArray(logoSecondary?.split('/'))
                ? [[logoSecondary.split('/').reverse()[0], logoSecondary]]
                : null,
            translation,
          },
        },
      }}
      handleEvent={{
        done: async ({ operator }) => {
          const checkImageType = async (
            image: null | Array<[string, string]>,
          ) => {
            return image && Array.isArray(image)
              ? image?.[0]?.[1].indexOf(';base64,') !== -1
                ? await base64ToUrl(image?.[0])
                : image?.[0]?.[1]
              : null;
          };

          await updateOperator({
            variables: {
              input: {
                operatorId: operator.operatorId!,
                display: {
                  logoMain: await checkImageType(operator.logoMain),
                  logoSecondary: await checkImageType(operator.logoSecondary),
                },
                name: operator.name!,
                email: operator.email,
                phone: operator.phone,
                translation:
                  operator?.translation?.map(({ name, lang }: any) => ({
                    name,
                    lang,
                  })) || [],
              },
            },
          });
        },

        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Opérateur', component: OperatorStep }} />
    </Wizard>
  );
};
