import { Code, Page, Widget, Zone, useQuery } from '@gimlite/watermelon';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryBucketArgs } from '../../../../client/graphql';
import { bucketGql } from '../gql/bucket.gql';

export const BucketDetails = () => {
  const { bucketId } = useParams();
  const [read, setRead] = useState<any>();

  const { data, loading, error, refetch } = useQuery<
    { bucket: Query['bucket'] },
    QueryBucketArgs
  >(bucketGql, {
    variables: { bucketId: bucketId! },
  });

  const bucket = useMemo(() => {
    if (!data) return null;

    return data.bucket;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['code']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'code' }}>
          <Widget
            state={{
              error: error,
              loading: loading,
              refetch: refetch,
            }}
            config={{ title: 'Bucket debug' }}
          >
            <Code
              data={{
                defaultValue: bucket
                  ? JSON.stringify(bucket, null, 4)
                  : undefined,
              }}
              config={{ yMax: '100%', lang: 'json', readOnly: true }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
