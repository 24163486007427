import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  Mutation,
  MutationCreateAdminUsersArgs,
} from '../../../client/graphql';
import { createAdminUserGql } from './gql/createAdminUser.gql';
import { CredentialsStep } from './steps/credentials.step';

export type CreateAdminUserWizardContext = any;

export const CreateAdminUserWizard = () => {
  const { WizardStore } = useRootStore();

  const [createAdminUsers, createAdminUsersState] = useMutation<
    Mutation['createAdminUsers'],
    MutationCreateAdminUsersArgs
  >(createAdminUserGql);

  const state = useMemo(() => {
    if (createAdminUsersState.loading) return undefined;
    if (createAdminUsersState.error) return false;
    if (createAdminUsersState.data) return true;
  }, [createAdminUsersState]);

  return (
    <Wizard<CreateAdminUserWizardContext>
      config={{
        title: 'Créer un administrateur',
        withValidation: {
          state,
          success: "L'administrateur a été créé avec succès",
          error: "Erreur lors de la création de l'administrateur",
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value) => {
          try {
            await createAdminUsers({
              variables: {
                input: value,
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
    </Wizard>
  );
};
