import {
  FilterExpanded,
  Page,
  Table,
  useMyUrl,
  useQuery,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Query, QueryPaymentContractsArgs } from '../../../../client/graphql';
import { contractsGql } from '../gql/contracts.gql';

export const ContractsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { paymentContracts: Query['paymentContracts'] },
    QueryPaymentContractsArgs
  >(contractsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const contracts = useMemo(() => {
    if (!data) return null;

    return data.paymentContracts;
  }, [data]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['filter'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'filter' }}>
          <FilterExpanded
            data={{ value: getParamsUrl }}
            handleEvent={{
              submit: (data: any) => {
                setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
              },
            }}
          >
            <FilterExpanded.Fields></FilterExpanded.Fields>
            <FilterExpanded.Actions></FilterExpanded.Actions>
          </FilterExpanded>
        </Zone.Area>

        <Zone.Area config={{ area: 'table' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: 'Liste des contrats bancaires' }}
          >
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  console.log({ value });
                },
              }}
              data={
                contracts
                  ? {
                      list: contracts?.list.map(({ updatedAt, ...rest }) => {
                        return {
                          updatedAt:
                            DateTime.fromISO(updatedAt).toFormat(
                              'dd/MM/yy HH:mm:ss',
                            ),
                          ...rest,
                        };
                      }),
                      paging: contracts?.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'Tag',
                    key: 'tag',
                  },
                  {
                    title: 'PSP',
                    key: 'psp',
                  },
                  {
                    title: 'Updated At',
                    key: 'updatedAt',
                  },
                ],
              }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
