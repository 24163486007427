import { Table as TableAnt } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { useId, useMemo, useState } from 'react';
import type {
  PagingInType,
  PagingOutType,
} from '../../components/pagination/pagination.component';
import { usePagination } from '../../hook/usePagination/usePagination.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { Size, Trigger } from '../../types/component.type';
import { Empty } from '../empty/empty.component';
import { Input } from '../input/input.component';
import { SkeletonLine } from '../skeleton/skeleton.component';
import { Space } from '../space/space.component';
import './table.component.scss';

const loader = (id: string) => {
  return (
    <div className="table__skeleton">
      {Array.from({ length: 15 }).map((_, index) => {
        return (
          <div
            className="table__skeleton__item"
            key={`${id}-table__skeleton__item-${index}`}
          >
            <SkeletonLine />
            <Space config={{ count: 0.5, way: 'vertical' }}></Space>
          </div>
        );
      })}
    </div>
  );
};

export declare namespace TableType {
  type Props<T extends { _id: string }> = {
    className?: string;

    handleEvent?: {
      paging?: (value: PagingOutType) => void;
      read?: (value: TableType.Data.Line<T>) => void;
      update?: (value: TableType.Data.Line<T>) => void;
      render?: (
        value: Array<TableType.Data.Line<T>>,
      ) => Array<TableType.Data.Line<T>>;
      click?: () => void;
    };
    data?: {
      list?: TableType.Data.Line<T>[];
      paging?: PagingInType;
    };
    config: {
      cellPadding?: boolean;
      headColor?: boolean;
      alternateColor?: boolean;
      columns: TableType.Config.Column<T>[];
      border?: boolean;
      actions?: {
        read?: boolean;
        update?: string[];
        export?: (item: TableType.Data.Line<T>) => void;
      };
      pagination?: Extract<Trigger, 'click' | 'scroll' | 'none'>;
      testId?: string;
    };
  };

  namespace Data {
    type Line<T> = {
      key?: string;
      $expandable?: React.ReactNode;
    } & T;
  }

  namespace Config {
    type Column<T extends Record<string, unknown>> = {
      title: string;
      key: keyof T | 'action';
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
      $expandable?: React.ReactNode;
      sort?: {
        defaultSortOrder?: 'descend' | 'ascend' | null;
        sorter: (a: T, b: T) => number | boolean;
      };
      render?: (data: T) => string | React.ReactNode;
    };
  }
}

export const Table = <T extends { _id: string }>({
  className = '',
  handleEvent: { read, update, paging, render } = {},
  config: {
    alternateColor = false,
    columns = [],
    actions = {},
    pagination = 'click',
    border = false,
    cellPadding = true,
    headColor = false,
    testId,
  },

  data,
}: TableType.Props<T>) => {
  const id = useId();
  const dataList = data?.list;
  const dataPaging = data?.paging;
  const { t, lang } = useTranslation();
  const [currentUpdateLine, setCurrentUpdateLine] =
    useState<TableType.Data.Line<T> | null>();
  const [selected, setSelected] = useState<string | null>(null);

  const {
    saveData,
    listContainRef,
    nodePagination,
    nodeScroll,
    dataListIsEmpty,
    setSaveData,
  } = usePagination<TableType.Data.Line<T>>({
    dataList,
    dataPaging,
    paging,
    pagination,
    render,
    formatter: (list) =>
      list.map(({ _id, ...rest }) => ({
        ...rest,
        _id,
        key: _id,
      })) as TableType.Data.Line<T>[],
  });

  const updateActionIsActive = !!(
    actions?.update &&
    Array.isArray(actions?.update) &&
    actions?.update?.length > 0
  );

  const readActionIsActive = !!actions?.read;
  const exportActionDeclared = 'export' in actions;
  const singleActionDeclare = !!(actions && Object.keys(actions).length === 1);
  const multipleActionsDeclare = !!(actions && Object.keys(actions).length > 1);

  const columnsFormatted = useMemo(() => {
    if (!columns) return [];

    const buildColumns = columns.map(({ title, key, size, sort, render }) => {
      return {
        title,
        key,
        dataIndex: key,
        render: (value: string, line: TableType.Data.Line<T>) => {
          if (
            key &&
            typeof key === 'string' &&
            updateActionIsActive &&
            actions?.update?.includes(key) &&
            currentUpdateLine?._id === line?._id
          ) {
            const renderFormatted = render?.(line);

            return (
              <Input
                handleEvent={{
                  input: (valueInput) => {
                    setCurrentUpdateLine({ ...line, [key]: valueInput });
                  },
                }}
                data={{
                  defaultValue:
                    renderFormatted === 'string'
                      ? (renderFormatted as string)
                      : value,
                }}
                config={{ width: 'full' }}
              />
            );
          } else {
            return (
              <span
                className={`${line?.key === selected ? 'tableSelected' : ''}`}
              >
                {render ? render(line) : value}
              </span>
            );
          }
        },
        ...sort,
      };
    });

    if (
      (singleActionDeclare && !readActionIsActive) ||
      multipleActionsDeclare
    ) {
      buildColumns.push({
        title: t('watermelon-actions'),
        dataIndex: 'action',
        key: 'action',
        render: (value: string, line: TableType.Data.Line<T>) => {
          const lineIsBeingUpdated = currentUpdateLine?._id === line?._id;

          return (
            <div className="table__actions">
              {updateActionIsActive &&
                (lineIsBeingUpdated ? (
                  <>
                    <a
                      className="table__actions__item"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentUpdateLine(() => null);
                      }}
                    >
                      {t('watermelon-cancel')}
                    </a>

                    <a
                      className="table__actions__item"
                      onClick={(e) => {
                        e.stopPropagation();
                        update?.(currentUpdateLine);

                        setSaveData((saveData) =>
                          saveData
                            ? [
                                ...saveData.map((e) =>
                                  currentUpdateLine &&
                                  e._id === currentUpdateLine._id
                                    ? currentUpdateLine
                                    : e,
                                ),
                              ]
                            : [],
                        );

                        setCurrentUpdateLine(() => null);
                      }}
                    >
                      {t('watermelon-submit')}
                    </a>
                  </>
                ) : (
                  <a
                    className="table__actions__item"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentUpdateLine(() => line);
                    }}
                  >
                    {t('watermelon-edit')}
                  </a>
                ))}

              {exportActionDeclared && !lineIsBeingUpdated && (
                <a
                  className="table__actions__item"
                  onClick={(e) => {
                    e.stopPropagation();
                    actions?.export?.(line);
                  }}
                >
                  {t('watermelon-export')}
                </a>
              )}
            </div>
          );
        },
      });
    }

    return buildColumns;
  }, [columns, currentUpdateLine, actions, selected, lang]);

  return !dataList || !dataPaging || !saveData ? (
    loader(id)
  ) : (
    <div
      className={`table 
      ${cellPadding ? 'table--cellPadding' : 'table--noCellPadding'} 
      ${border ? 'table--border' : 'table--noBorder'} 
      ${headColor ? 'table--headColor' : 'table--noHeadColor'} 
      ${className}`}
    >
      <div
        ref={listContainRef}
        className={`
          table__contain 
          ${readActionIsActive ? 'table-read' : ''}
          ${alternateColor ? 'alternateColor' : ''}
        `}
      >
        {!dataListIsEmpty ? (
          <TableAnt<TableType.Data.Line<T>>
            dataSource={saveData}
            size="middle"
            columns={columnsFormatted as ColumnType<T>[]}
            pagination={false}
            onRow={
              readActionIsActive
                ? (record) => ({
                    onClick: () => {
                      const { key } = record;
                      setSelected(key!);
                      read && read?.(record);
                    },
                  })
                : undefined
            }
            showSorterTooltip={false}
            expandable={
              dataList &&
              dataList.findIndex((element) => element?.$expandable) !== -1
                ? {
                    expandedRowRender: (record) => (
                      <>{record.$expandable || null}</>
                    ),
                    rowExpandable: (record) => !!record.$expandable,
                  }
                : undefined
            }
            data-testid={testId}
          />
        ) : (
          <Empty config={{ mode: { name: 'noData' } }}></Empty>
        )}

        {nodeScroll}
      </div>

      {nodePagination}
    </div>
  );
};
