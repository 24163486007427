import {
  Button,
  FilterExpanded,
  Input,
  Page,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
  Form,
  useRootStore,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Query, QueryClientsArgs } from '../../../../client/graphql';
import { clientsGql } from '../gql/clients.gql';

export const ClientsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const navigate = useNavigate();
  const { WizardStore } = useRootStore();

  const { data, loading, error, refetch } = useQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const clients = useMemo(() => {
    if (!data) return null;

    return data.clients;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{
          error: error,
          loading: loading,
          refetch: refetch,
        }}
        config={{ title: 'Liste des clients' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          {/* FILTERS */}
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'slug',
                    label: 'Nom du client',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.name }} />
                </Form.Item>
              </FilterExpanded.Fields>

              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
                <Button
                  config={{
                    text: 'Créer un client',
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () => WizardStore.select({ id: 'createClient' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          {/* TABLE */}
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/onstreet/clients/${value._id}/global`);
                },
              }}
              data={
                clients
                  ? { list: clients.list, paging: clients.paging }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Name',
                    key: 'slug',
                  },
                  {
                    title: 'Siret',
                    key: 'siret',
                  },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
