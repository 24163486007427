import {
  Badge,
  Button,
  FilterExpanded,
  Image,
  Page,
  Row,
  Table,
  useMyUrl,
  useQuery,
  useRootStore,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Query, QueryOperatorsArgs } from '../../../../client/graphql';
import { operatorsGql } from '../gql/operators.gql';
import { useNavigate } from 'react-router-dom';

export const OperatorsSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { operators: Query['operators'] },
    QueryOperatorsArgs
  >(operatorsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const operators = useMemo(() => {
    if (!data) return null;

    return data.operators;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des opérateurs' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded data={{ value: getParamsUrl }}>
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  handleEvent={{
                    click: () => {
                      WizardStore.select({ id: 'createOperator' });
                    },
                  }}
                  config={{
                    text: 'Créer un opérateur',
                    type: { value: 'button' },
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/offstreet/operators/${value._id}`);
                },
              }}
              data={
                operators
                  ? {
                      list: operators?.list.map(
                        ({
                          display,
                          _id,
                          translation,
                          name,
                          code,
                          operatorCode,
                          email,
                          phone,
                          ...rest
                        }) => {
                          return {
                            ...rest,
                            translation,
                            name,
                            code,
                            operatorCode,
                            email,
                            phone,
                            _id,
                            logo: display?.logoMain ? (
                              <Image
                                data={{ src: display.logoMain }}
                                config={{ size: 'cell' }}
                              />
                            ) : (
                              <Badge
                                config={{
                                  color: 'disabled',
                                  text: 'Non spécifié',
                                }}
                              />
                            ),
                            action: (
                              <Row>
                                <Button
                                  handleEvent={{
                                    click: () => {
                                      WizardStore.select({
                                        id: 'updateOperator',
                                        store: {
                                          operatorId: _id,
                                          name,
                                          code,
                                          operatorCode,
                                          email,
                                          phone,
                                          logoMain: display?.logoMain,
                                          logoSecondary: display?.logoSecondary,
                                          translation,
                                        },
                                      });
                                    },
                                  }}
                                  config={{
                                    text: 'Modifier',
                                    color: 'warn',
                                    size: 'small',
                                    stopPropagation: true,
                                  }}
                                ></Button>
                              </Row>
                            ),
                          };
                        },
                      ),
                      paging: operators?.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Logo',
                    key: 'logo',
                  },
                  {
                    title: 'Name',
                    key: 'name',
                  },
                  {
                    title: 'Code',
                    key: 'code',
                  },
                  {
                    title: 'Operator code',
                    key: 'operatorCode',
                  },
                  {
                    title: 'Contract count',
                    key: 'operatorContractCount',
                  },
                  {
                    title: 'Actions',
                    key: 'action',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
