import {
  Checkbox,
  getCSS,
  Radio,
  RadioGroup,
  Trigger,
} from '@gimlite/watermelon';
import React, { useCallback, useId, useMemo, useState } from 'react';
import {
  usePagination,
  UsePaginationType,
} from '../../hook/usePagination/usePagination.hook';
import { Empty } from '../empty/empty.component';
import { type PagingInType } from '../pagination/pagination.component';
import { SkeletonLine } from '../skeleton/skeleton.component';
import { Space } from '../space/space.component';
import { Write } from '../write/write.component';
import './list.component.scss';

const loader = (id: string) => {
  return (
    <div className="listflat__skeleton">
      {Array.from({ length: 15 }).map((_, index) => {
        return (
          <div
            className="listflat__skeleton__item"
            key={`${id}-listflat__skeleton__item-${index}`}
          >
            <SkeletonLine />
            <Space config={{ count: 0.5, way: 'vertical' }}></Space>
          </div>
        );
      })}
    </div>
  );
};

declare namespace ListHeaderType {
  type Props<T extends string> = {
    header?: ListHeaderType.Header;
    filter?: ListHeaderType.Filter;
    columns:
      | ListFlatInfoType.Config.Column<T>[]
      | ListFlatMultipleCheckType.Config.Column<T>[]
      | ListFlatSingleCheckType.Config.Column<T>[];
  };

  type Filter = React.ReactNode;
  type Header = boolean;
}

const ListHeader = <T extends string>({
  header,
  columns,
  filter,
}: ListHeaderType.Props<T>) => {
  return header || filter ? (
    <div className="list-header">
      {header && columns.find(({ label }) => !!label) && (
        <div
          className="list-header__contain"
          style={{
            gridTemplateColumns: ` ${columns
              .map(({ size = 'min-content' }) => size)
              .join(' ')}`,
          }}
        >
          {columns.map(({ label = '' }, index) => (
            <div
              key={`list-header-column-${index}`}
              className="list-header__contain__item"
            >
              <Write
                data={{ item: label }}
                config={{ mode: 'cell-title', color: 'label' }}
              />
            </div>
          ))}
        </div>
      )}
      {filter && <div className="list-header__filter">{filter}</div>}
    </div>
  ) : (
    <></>
  );
};

export declare namespace ListFlatInfoType {
  type Props<T extends string> = {
    className?: string;
    data: {
      list?: ListFlatInfoType.Data.Line<T>[];
      paging?: PagingInType;
      defaultSelected?: string;
    };
    config: {
      columns: ListFlatInfoType.Config.Column<T>[];
      yMax?: number | string;
      pagination?: Extract<Trigger, 'click' | 'scroll' | 'none'>;
      border?: boolean;
      header?: ListHeaderType.Header;
      filter?: ListHeaderType.Filter;
    };
    handleEvent?: {
      click?: ListFlatInfoType.HandleEvent.Click['function'];
      paging?: ListFlatInfoType.HandleEvent.Paging['function'];
    };
  };

  namespace Data {
    type Line<T extends string> = {
      _id: string;
      items: Record<T, ListFlatInfoType.Data.Item>;
    };

    type Item = React.ReactNode;
  }

  namespace Config {
    type Column<T extends string> = {
      key: T;
      label?: string;
      size?: string;
    };
  }

  namespace HandleEvent {
    type Paging = UsePaginationType.HandleEvent.Paging;

    type Click = {
      value1: string;
      return: void;
      function: (
        value: ListFlatInfoType.HandleEvent.Click['value1'],
      ) => ListFlatInfoType.HandleEvent.Click['return'];
    };
  }
}

export const ListFlatInfo = <T extends string>({
  data: {
    list: dataList,
    paging: dataPaging,
    defaultSelected: dataDefaultSelected,
  } = {},
  config: {
    yMax = 'initial',
    pagination = 'click',
    columns,
    border = false,
    header = false,
    filter,
  },
  handleEvent: { click, paging } = {},
  className = '',
}: ListFlatInfoType.Props<T>) => {
  const id = useId();
  const [selected, setSelected] = useState<string | null>(
    dataDefaultSelected || null,
  );

  const { saveData, listContainRef, nodePagination, nodeScroll } =
    usePagination<ListFlatInfoType.Data.Line<T>>({
      dataList,
      dataPaging,
      paging,
      pagination,
    });

  const formattedList = useMemo(() => {
    if (!saveData || !columns) return [];
    return saveData.map(({ _id, items }) => {
      return {
        _id,
        items: columns
          .reduce(
            (acc: string[], { key }) =>
              acc.includes(key) ? acc : [...acc, key],
            [],
          )
          .reduce(
            (acc: { key: string; node: React.ReactNode }[], key) =>
              items?.[key] ? [...acc, { key, node: <> {items[key]}</> }] : acc,
            [],
          ),
      };
    });
  }, [columns, saveData]);

  return dataList ? (
    <div
      className={`
        listflat
        ${border ? 'listflat--border' : ''} 
        ${className}
        `}
    >
      <ListHeader<T> header={header} columns={columns} filter={filter} />
      <div
        ref={listContainRef}
        className="listflat__contain"
        style={{ maxHeight: yMax }}
      >
        {formattedList.length > 0 ? (
          formattedList.map(({ _id, items }, index) => (
            <div
              key={`${id}-listflat__contain__line-${_id}-${index}`}
              onClick={() => {
                click?.(_id);
                if (click) {
                  setSelected(_id);
                }
              }}
              style={{
                gridTemplateColumns: ` ${columns
                  .map(({ size = 'min-content' }) => size)
                  .join(' ')}`,
                gridTemplateAreas: `"${columns
                  .map(({ key }) => key)
                  .join(' ')}"`,
                gap: `calc(${2} * ${getCSS('--space')})`,
              }}
              className={`listflat__contain__line ${
                click ? 'listflat__contain__line--click' : ''
              }
              ${selected === _id ? 'listflat__contain__line--selected' : ''}
            `}
            >
              {items.map(({ key, node }, index) => (
                <div
                  key={`list-cell-${index}`}
                  className="listflat__contain__line__cell"
                  style={{ gridArea: key }}
                >
                  {node}
                </div>
              ))}
            </div>
          ))
        ) : (
          <Empty config={{ mode: { name: 'noData' } }}></Empty>
        )}

        {nodeScroll}
      </div>

      {nodePagination}
    </div>
  ) : (
    loader(id)
  );
};

export declare namespace ListFlatMultipleCheckType {
  type Props<T extends string> = {
    className?: string;
    data: {
      list?: ListFlatMultipleCheckType.Data.Line<T>[];
      checkList: ListFlatMultipleCheckType.Data.Line<T>['_id'][];
      paging?: PagingInType;
    };
    config: {
      disabled?: boolean;
      columns: ListFlatMultipleCheckType.Config.Column<T>[];
      yMax?: number | string;
      pagination?: Extract<Trigger, 'click' | 'scroll' | 'none'>;
      border?: boolean;
      showOnlyCheck?: boolean;
      header?: ListHeaderType.Header;
      filter?: ListHeaderType.Filter;
    };
    handleEvent?: {
      click?: ListFlatMultipleCheckType.HandleEvent.Click['function'];
      paging?: ListFlatMultipleCheckType.HandleEvent.Paging['function'];
      checkList?: ListFlatMultipleCheckType.HandleEvent.CheckList<T>['function'];
    };
  };

  namespace Data {
    type Line<T extends string> = {
      _id: string;
      items: Record<T, ListFlatMultipleCheckType.Data.Item>;
    };

    type Item = React.ReactNode;
  }

  namespace Config {
    type Column<T extends string> = {
      key: T;
      label?: string;
      size?: string;
    };
  }

  namespace HandleEvent {
    type Paging = UsePaginationType.HandleEvent.Paging;

    type Click = {
      value1: string;
      return: void;
      function: (
        value: ListFlatMultipleCheckType.HandleEvent.Click['value1'],
      ) => ListFlatMultipleCheckType.HandleEvent.Click['return'];
    };

    type CheckList<T extends string> = {
      value1: Array<ListFlatMultipleCheckType.Data.Line<T>['_id']>;
      return: void;
      function: (
        value: ListFlatMultipleCheckType.HandleEvent.CheckList<T>['value1'],
      ) => ListFlatMultipleCheckType.HandleEvent.CheckList<T>['return'];
    };
  }
}

export const ListFlatMultipleCheck = <T extends string>({
  data: { list: dataList, checkList: dataCheckList = [], paging: dataPaging },
  config: {
    yMax = 'initial',
    pagination = 'click',
    columns,
    border = false,
    showOnlyCheck = false,
    disabled = false,
    header = false,
    filter,
  },
  handleEvent: { click, paging, checkList } = {},
  className = '',
}: ListFlatMultipleCheckType.Props<T>) => {
  const id = useId();

  const { saveData, listContainRef, nodePagination, nodeScroll } =
    usePagination<ListFlatMultipleCheckType.Data.Line<T>>({
      dataList,
      dataPaging,
      pagination,
      paging,
    });

  const updateCheck = useCallback(
    (
      _id: ListFlatMultipleCheckType.Data.Line<T>['_id'],
      action: 'add' | 'remove',
    ) => {
      switch (action) {
        case 'add':
          checkList?.([...dataCheckList, _id]);
          break;
        case 'remove':
          const newList = dataCheckList.filter(
            (currentId) => currentId !== _id,
          );
          checkList?.(newList);
          break;
      }
    },
    [dataCheckList],
  );

  const formattedList = useMemo(() => {
    if (!saveData || !columns) return [];

    return saveData.map(({ _id, items }) => {
      return {
        _id,
        items: columns.reduce((acc: React.ReactNode[], { key }) => {
          if (items?.[key]) return [...acc, <>{items[key]}</>];
          return [...acc, <div></div>];
        }, []),
      };
    });
  }, [columns, saveData, showOnlyCheck]);

  return dataList ? (
    <div
      className={`listflat ${border ? 'listflat--border' : ''} ${className}`}
    >
      <ListHeader<T> header={header} columns={columns} filter={filter} />
      <div
        ref={listContainRef}
        className="listflat__contain"
        style={{ maxHeight: yMax }}
      >
        {formattedList.length > 0 ? (
          formattedList
            .filter(({ _id }) =>
              showOnlyCheck
                ? !!dataCheckList.find((value) => _id === value)
                : true,
            )
            .map(({ _id, items }, index) => {
              return (
                <div
                  key={`${id}-${_id}-${index}`}
                  onClick={() => {
                    click?.(_id);
                  }}
                  style={{
                    gridTemplateColumns: `min-content ${columns
                      .map(({ size = 'min-content' }) => size)
                      .join(' ')}`,
                  }}
                  className={`listflat__contain__line ${
                    click ? 'listflat__contain__line--click' : ''
                  }`}
                >
                  <div>
                    <Checkbox
                      config={{ disabled, size: 'large' }}
                      data={{
                        value: !!dataCheckList.find((value) => _id === value),
                      }}
                      handleEvent={{
                        check: (isChecked) =>
                          updateCheck(_id, isChecked ? 'add' : 'remove'),
                      }}
                    />
                  </div>
                  {items.map((cell, index) => (
                    <div
                      key={`${id}-list-cell-${index}`}
                      className="listflat__contain__line__cell"
                    >
                      {cell}
                    </div>
                  ))}
                </div>
              );
            })
        ) : (
          <Empty config={{ mode: { name: 'noData' } }}></Empty>
        )}

        {nodeScroll}
      </div>

      {nodePagination}
    </div>
  ) : (
    loader(id)
  );
};

export declare namespace ListFlatSingleCheckType {
  type Props<T extends string> = {
    className?: string;
    data: {
      list?: ListFlatSingleCheckType.Data.Line<T>[];
      paging?: ListFlatSingleCheckType.Data.Paging;
      checkItem?: ListFlatSingleCheckType.Data.Line<T>['_id'] | null;
    };
    config: {
      columns: ListFlatSingleCheckType.Config.Column<T>[];
      yMax?: number | string;
      pagination?: Extract<Trigger, 'click' | 'scroll' | 'none'>;
      border?: boolean;
      showOnlyCheck?: boolean;
      header?: ListHeaderType.Header;
      filter?: ListHeaderType.Filter;
    };
    handleEvent?: {
      click?: ListFlatSingleCheckType.HandleEvent.Click['function'];
      paging?: ListFlatSingleCheckType.HandleEvent.Paging['function'];
      checkItem?: ListFlatSingleCheckType.HandleEvent.CheckItem<T>['function'];
    };
  };

  namespace Data {
    type Line<T extends string> = {
      _id: string;
      items: Record<T, ListFlatSingleCheckType.Data.Item>;
    };

    type Paging = PagingInType;

    type Item = React.ReactNode;
  }

  namespace Config {
    type Column<T extends string> = {
      key: T;
      label?: string;
      size?: string;
    };
  }

  namespace HandleEvent {
    type Paging = UsePaginationType.HandleEvent.Paging;

    type Click = {
      value1: string;
      return: void;
      function: (
        value: ListFlatSingleCheckType.HandleEvent.Click['value1'],
      ) => ListFlatSingleCheckType.HandleEvent.Click['return'];
    };

    type CheckItem<T extends string> = {
      value1: ListFlatSingleCheckType.Data.Line<T>['_id'];
      return: void;
      function: (
        value: ListFlatSingleCheckType.HandleEvent.CheckItem<T>['value1'],
      ) => ListFlatSingleCheckType.HandleEvent.CheckItem<T>['return'];
    };
  }
}

export const ListFlatSingleCheck = <T extends string>({
  data: { list: dataList, checkItem: dataCheckItem, paging: dataPaging },
  config: {
    yMax = 'initial',
    pagination = 'click',
    columns,
    border = false,
    showOnlyCheck = false,
    header = false,
    filter,
  },
  handleEvent: { click, paging, checkItem } = {},
  className = '',
}: ListFlatSingleCheckType.Props<T>) => {
  const id = useId();

  const { saveData, listContainRef, nodePagination, nodeScroll } =
    usePagination<ListFlatMultipleCheckType.Data.Line<T>>({
      dataList,
      dataPaging,
      pagination,
      paging,
    });

  const updateCheck = useCallback(
    (_id: ListFlatSingleCheckType.Data.Line<T>['_id']) => {
      checkItem?.(_id);
    },
    [],
  );

  const formattedList = useMemo(() => {
    if (!saveData || !columns) return [];

    return saveData.map(({ _id, items }) => {
      return {
        _id,
        items: columns.reduce((acc: React.ReactNode[], { key }) => {
          if (items?.[key]) return [...acc, <>{items[key]}</>];
          return [...acc, <div></div>];
        }, []),
      };
    });
  }, [columns, saveData, showOnlyCheck]);

  return dataList ? (
    <div
      className={`
        listflat
        ${border ? 'listflat--border' : ''} 
        ${className}
      `}
    >
      <ListHeader<T> header={header} columns={columns} filter={filter} />
      <RadioGroup
        className="listflat-radiogroup"
        data={{ selected: dataCheckItem }}
        handleEvent={{
          change: (_id) => {
            if (_id) updateCheck(_id);
          },
        }}
        config={{ size: 'large' }}
      >
        <div
          ref={listContainRef}
          className="listflat__contain"
          style={{ maxHeight: yMax }}
        >
          {formattedList.length > 0 ? (
            formattedList
              .filter(({ _id }) => {
                return showOnlyCheck ? dataCheckItem === _id : true;
              })
              .map(({ _id, items }, index) => {
                return (
                  <div
                    key={`${id}-${_id}-${index}`}
                    onClick={() => {
                      click?.(_id);
                    }}
                    style={{
                      gridTemplateColumns: `min-content ${columns
                        .map(({ size = 'min-content' }) => size)
                        .join(' ')}`,
                    }}
                    className={`listflat__contain__line ${
                      click ? 'listflat__contain__line--click' : ''
                    }`}
                  >
                    <div>
                      <Radio
                        data={{
                          value: _id,
                        }}
                      />
                    </div>
                    {items.map((cell, index) => (
                      <div
                        key={`${id}-list-cell-${index}`}
                        className="listflat__contain__line__cell"
                      >
                        {cell}
                      </div>
                    ))}
                  </div>
                );
              })
          ) : (
            <Empty config={{ mode: { name: 'noData' } }}></Empty>
          )}
          {nodeScroll}
        </div>
      </RadioGroup>
      {nodePagination}
    </div>
  ) : (
    loader(id)
  );
};
