import {
  AutoComplete,
  Form,
  Input,
  Row,
  Select,
  Space,
  useLazyQuery,
  WizardItemType,
} from '@gimlite/watermelon';
import {
  Query,
  QueryUpsArgs,
  QueryUpsListArgs,
} from '../../../../client/graphql';
import { CreateControlUserWizardContext } from '../createControlUser.wizard';
import { upsGql } from '../gql/ups.gql';
import { upsListGql } from '../gql/upsList.gql';
import { useEffect } from 'react';

export const DetailsStep = ({
  context: { user, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateControlUserWizardContext>) => {
  const [upsList, upsListState] = useLazyQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    notification: {
      error: 'Erreur lors de la recherche des parkings',
    },
  });

  const [ups, upsState] = useLazyQuery<{ ups: Query['ups'] }, QueryUpsArgs>(
    upsGql,
    {
      notification: {
        error: 'Erreur lors de la lecture du parking',
      },
    },
  );

  useEffect(() => {
    if (upsListState.data) {
      submit({
        listParking: upsListState.data.upsList.list,
      });
    }
  }, [upsListState.data]);

  useEffect(() => {
    if (upsState.data) {
      submit({
        selectParking: { _id: upsState.data.ups._id },
      });
    }
  }, [upsState.data]);

  return (
    <Form
      data={{ value: user }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ user: value });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'upsId',
              label: 'UPS',

              rules: [{ required: true }],
            }}
          >
            <AutoComplete
              handleEvent={{
                input: (value) => {
                  upsList({
                    variables: {
                      codePattern: value,
                    },
                  });
                },
                select(item) {
                  if (item?.value) {
                    ups({
                      variables: {
                        upsId: item.value,
                      },
                    });
                  }
                },
              }}
              data={{
                items: listParking.map(({ code, _id }) => ({
                  value: _id,
                  label: code,
                })),
              }}
              config={{
                search: {
                  caseSensitive: false,
                  accentSensitive: false,
                  pattern: 'start',
                },
                placeholder: 'plan-les-ouates',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'firstName',
              label: 'Prénom',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{ placeholder: 'Jhon', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'lastName',
              label: 'Nom de famille',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Doe',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'fpsAgentId',
              label: "Identifiant de l'agent",
              rules: [
                {
                  required: true,
                  pattern: /^\d{3}$/,
                },
              ],
            }}
          >
            <Input
              config={{
                placeholder: '245',
                type: { name: 'number', min: 100 },
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'fpsAgentName',
              label: "Nom de l'agent",
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Mr smith',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>

        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'lang',
              label: 'Langue',
              rules: [{ required: true }],
            }}
          >
            <Select
              data={{
                items: [
                  { label: 'FR', value: 'fr_FR' },
                  { label: 'EN', value: 'en_EN' },
                  { label: 'DE', value: 'de_DE' },
                  { label: 'IT', value: 'it_IT' },
                ],
              }}
              config={{
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
