import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  Mutation,
  MutationUpdateControlUsersArgs,
  UpsEntity,
} from '../../../client/graphql.js';
import { updateControlUsersGql } from './gql/updateControlUser.gql';
import { CameraStep } from './steps/parking.step';

export type AddParkingInControlUserWizardContext = {
  user: {
    _id: string | null;
    ups: string[];
  };
  listParking: Array<UpsEntity>;
  selectParking: {
    _id: string | null;
  };
};

export const AddParkingInControlUserWizard = () => {
  const { WizardStore } = useRootStore();
  const { user } = WizardStore?.selected?.store;

  const [updateControlUsers, updateControlUsersState] = useMutation<
    Mutation['updateControlUsers'],
    MutationUpdateControlUsersArgs
  >(updateControlUsersGql);

  const state = useMemo(() => {
    if (updateControlUsersState.loading) return undefined;
    if (updateControlUsersState.error) return false;
    if (updateControlUsersState.data) return true;
  }, [updateControlUsersState]);

  return (
    <Wizard<AddParkingInControlUserWizardContext>
      config={{
        title: "Ajouter un parking à l'agent",
        withValidation: {
          state,
          success: 'Le parking a été ajouté avec succès',
          error: "Une erreur est survenue lors de l'ajout du parking",
        },
      }}
      data={{
        defaultContext: {
          user,
          listParking: [],
          selectParking: {
            _id: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ user, selectParking }) => {
          try {
            await updateControlUsers({
              variables: {
                input: {
                  ups: [...user.ups, selectParking._id!].filter(Boolean),
                  userId: user._id!,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Camera', component: CameraStep }} />
    </Wizard>
  );
};
