import {
  Code,
  Page,
  Widget,
  Zone,
  useLazyQuery,
  useMyUrl,
  useQuery,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Query,
  QueryFragmentArgs,
  QueryFragmentByRefArgs,
} from '../../../../client/graphql';
import { fragmentByRefGql, fragmentGql } from '../gql/fragments.gql';

export const FragmentDetails = () => {
  const { fragmentId, fragmentRef } = useParams();
  const { getParamsUrl } = useMyUrl();

  const [fragmentsCall, { data, loading, error, refetch }] = useLazyQuery<
    { fragment: Query['fragment'] } | { fragmentByRef: Query['fragmentByRef'] },
    QueryFragmentArgs | QueryFragmentByRefArgs
  >(fragmentId ? fragmentGql : fragmentByRefGql);

  const fragment = useMemo(() => {
    if (!data) return null;

    if ('fragment' in data) {
      return data.fragment;
    }

    return data.fragmentByRef;
  }, [data]);

  useEffect(() => {
    const query: any = {};

    if (fragmentId) {
      query.fragmentId = fragmentId;
    }

    if (fragmentRef) {
      query.fragmentRef = fragmentRef;
    }

    fragmentsCall({
      variables: query,
      query: fragmentId ? fragmentGql : fragmentByRefGql,
    });
  }, [fragmentId, fragmentRef]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['code']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'code' }}>
          <Widget
            state={{
              error: error,
              loading: loading,
              refetch: refetch,
            }}
            config={{ title: 'Fragment debug' }}
          >
            <Code
              data={{
                defaultValue: JSON.stringify(fragment, null, 4),
              }}
              config={{ yMax: '100%', lang: 'json', readOnly: true }}
            />
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
