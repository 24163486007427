import { message } from 'antd';
import { MessageInstance, NoticeType } from 'antd/es/message/interface';
import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { logWithState } from '../functions/console.function';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
const mapMode: Record<NotificationModeType, NoticeType> = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  LOADING: 'loading',
};

export type NotificationModeType =
  | 'ERROR'
  | 'WARNING'
  | 'SUCCESS'
  | 'INFO'
  | 'LOADING';
export type NotificationContentType = string;

export type NotificationStoreContextType = {
  notif: {
    mode: NotificationModeType;
    content: NotificationContentType;
    key?: string;
  };
  instance: MessageInstance;
};

export class NotificationStoreMobX {
  rootStore: RootStoreMobX;
  @observable public load: boolean = false;
  @observable public instance: NotificationStoreContextType['instance'] | null =
    null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init({ instance }: { instance: MessageInstance }) {
    this.load = false;
    this.instance = instance;
    this.load = true;
  }

  @action public emit({
    mode,
    content,
    key,
    duration,
  }: {
    mode: NotificationModeType;
    content: NotificationContentType;
    key?: string;
    duration?: number;
  }) {
    if (!this.instance) return;

    this.instance.open({
      duration: duration || 3,
      type: mapMode[mode],
      content,
      style: {
        textAlign: 'end',
        marginRight: '10px',
      },
      key,
    });
  }

  @action public destroy(key?: string) {
    if (!this.instance) return;
    this.instance.destroy(key);
  }
}

export const ConfigNotification = observer(
  ({ children }: { children: React.ReactNode }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const { NotificationStore } = useRootStore();

    useEffect(() => {
      if (!NotificationStore.load && messageApi) {
        logWithState({ state: 'INFO', value: 'ConfigNotification init' });
        NotificationStore.init({ instance: messageApi });
      }

      if (NotificationStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigNotification load' });
      }
    }, [NotificationStore.load, messageApi]);

    return NotificationStore.load ? (
      <>
        {contextHolder}
        {children}
      </>
    ) : null;
  },
);
