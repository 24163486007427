import {
  baseFormattedDate,
  Button,
  DatePicker,
  FilterExpanded,
  Form,
  Page,
  Select,
  Table,
  useMyUrl,
  useQuery,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Query, QuerySessionsArgs } from '../../../../client/graphql';
import { sessionsGql } from '../gql/sessions.gql';

const selectOptionsSessions = [
  { label: 'CREATED', value: 'CREATED' },
  {
    label: 'ENTRY_AUTHORIZED',
    value: 'ENTRY_AUTHORIZED',
  },
  { label: 'ENTRY_DENIED', value: 'ENTRY_DENIED' },
  { label: 'ENTERED', value: 'ENTERED' },
  {
    label: 'EXIT_AUTHORIZE_PENDING',
    value: 'EXIT_AUTHORIZE_PENDING',
  },
  {
    label: 'EXIT_AUTHORIZED',
    value: 'EXIT_AUTHORIZED',
  },
  { label: 'EXIT_DENIED', value: 'EXIT_DENIED' },
  { label: 'EXITED', value: 'EXITED' },
  {
    label: 'AUTHORIZE_PEDESTRIAN',
    value: 'AUTHORIZE_PEDESTRIAN',
  },
  {
    label: 'PAYMENT_NOTIFIED',
    value: 'PAYMENT_NOTIFIED',
  },
];

export const SessionsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const { lang } = useTranslation();
  const { data, loading, error, refetch } = useQuery<
    { sessions: Query['sessions'] },
    QuerySessionsArgs
  >(sessionsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const sessions = useMemo(() => {
    if (!data) return null;

    return data.sessions;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des sessions' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'states',
                    label: 'States',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.states,
                      items: selectOptionsSessions,
                    }}
                    config={{
                      mode: 'multiple',
                    }}
                  />
                </Form.Item>
                <Form.Item
                  config={{
                    name: 'beginEnd',
                    label: 'Période de stationnement',
                  }}
                >
                  <DatePicker
                    config={{
                      mode: 'range',
                    }}
                    data={{ defaultValue: getParamsUrl?.beginEnd }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              _id: string;
              consumer?: string | null;
              state?: string | null;
              logicalId?: string | null;
              segment?: string | null;
              entryDate?: string | null;
              exitDate?: string | null;
            }>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={
                sessions
                  ? {
                      list: sessions.list.map(
                        ({ exitDate, entryDate, ...rest }) => {
                          return {
                            entryDate: entryDate
                              ? baseFormattedDate({ date: entryDate, lang })
                              : null,
                            exitDate: exitDate
                              ? baseFormattedDate({ date: exitDate, lang })
                              : null,
                            ...rest,
                          };
                        },
                      ),
                      paging: sessions.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'LogicalId',
                    key: 'logicalId',
                  },
                  {
                    title: 'Segment',
                    key: 'segment',
                  },
                  {
                    title: 'EntryDate',
                    key: 'entryDate',
                  },
                  {
                    title: 'ExitDate',
                    key: 'exitDate',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
