import axios from 'axios';

const base64ToBlob = (base64: string) => {
  const splitDataURI = base64.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

export const base64ToUrl = async ([fileName, base64]: [
  string,
  string,
]): Promise<string | null> => {
  const blob = base64ToBlob(base64);

  const formData = new FormData();
  formData.append('file', blob, 'image.png');

  for (const pair of formData.entries()) {
    console.log(pair[0], pair[1]);
  }

  const params = {
    tag: fileName,
    path: fileName,
    bucket: 'yoonite-resources',
    owner: 'iem',
  };

  const appendField = (name: string, data: any) => {
    if (Array.isArray(data)) {
      data.forEach((v) => appendField(name, v));
    } else if (typeof data === 'object') {
      Object.entries(data).forEach(([k, v]) => appendField(`${name}[${k}]`, v));
    } else formData.append(name, data);
  };

  Object.entries(params).forEach(([key, value]) => appendField(key, value));

  const response = await axios.post(
    `${process.env.REACT_APP_HOST_BFF_RESOURCES || 'http://localhost:3217'}/resources/upload`,
    formData,
  );

  return response.data?.resource?.url || null;
};
