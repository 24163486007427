import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { NavDesktopType } from '../components/nav/nav.component';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type MenuStoreContextType = {
  menu: NavDesktopType.Data[];
};

export class MenuStoreMobX {
  rootStore: RootStoreMobX;
  @observable public load: boolean = false;
  @observable public menu: MenuStoreContextType['menu'] = [];

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init() {
    this.load = true;
  }

  @action public setMenu(menu: MenuStoreContextType['menu']) {
    if (this.load) {
      this.menu = menu;
    }
  }
}

export const ConfigMenu = observer(
  ({ children }: { children: React.ReactNode }) => {
    const { MenuStore } = useRootStore();

    useEffect(() => {
      if (!MenuStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigMenu init' });
        MenuStore.init();
      }

      if (MenuStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigMenu load' });
      }
    }, [MenuStore.load]);

    return MenuStore.load ? children : null;
  },
);
