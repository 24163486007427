import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useMemo } from 'react';
import { Mutation, MutationUpdateSiteArgs } from '../../../client/graphql';
import { updateSiteGql } from './gql/updateSite.gql';
import { SingleStep } from './steps/single.step';

export type UpdateSiteWizardContext = any;

export const UpdateSiteWizard = () => {
  const { WizardStore } = useRootStore();

  const { siteId } = WizardStore.selected?.store;
  const [updateSite, updateSiteState] = useMutation<
    Mutation['updateSite'],
    MutationUpdateSiteArgs
  >(updateSiteGql);

  const state = useMemo(() => {
    if (updateSiteState.loading) return undefined;
    if (updateSiteState.error) return false;
    if (updateSiteState.data) return true;
  }, [updateSiteState]);

  return (
    <Wizard<UpdateSiteWizardContext>
      config={{
        title: 'Mettre à jour un site',
        withValidation: {
          state,
          success: 'La mise à jour du site a été réalisée avec succès',
          error: 'Erreur lors de la mise à jour du site',
        },
      }}
      data={{
        defaultContext: { siteId },
      }}
      handleEvent={{
        done: async (data) => {
          try {
            const payload = { siteId, ...data };

            const { latitude, longitude, ...rest } = payload;

            // Parse coordinates.
            const coordinates =
              latitude && longitude
                ? [parseFloat(latitude), parseFloat(longitude)]
                : undefined;

            const input = {
              ...rest,
              siteId,
              coordinates,
            };

            await updateSite({ variables: { input } });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Site', component: SingleStep }} />
    </Wizard>
  );
};
