import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Socket, io } from 'socket.io-client';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type SocketStoreContextType = {
  uri: string;
  client: Socket;
  connected: boolean;
};

export class SocketStoreMobX {
  rootStore: RootStoreMobX;
  @observable public load: boolean = false;
  @observable public uri: SocketStoreContextType['uri'] | null = null;
  @observable public client: SocketStoreContextType['client'] | null = null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public setClient({
    uri,
    token,
  }: {
    uri: SocketStoreContextType['uri'];
    token: string | null;
  }) {
    if (token) {
      this.client = io(uri, {
        autoConnect: true,
        secure: true,
        reconnection: true,
        auth: (cb) => cb({ token }),
      });
    }
  }

  @action public setLoad(load: boolean) {
    this.load = load;
  }

  @action public init({
    uri,
    token,
  }: {
    uri: SocketStoreContextType['uri'];
    token: string | null;
  }) {
    this.load = false;
    this.uri = uri;
    this.setLoad(true);
  }
}

export const ConfigSocket = observer(
  ({
    children,
    uri,
  }: {
    children: React.ReactNode;
    uri: SocketStoreContextType['uri'];
  }) => {
    const { SocketStore, AuthStore } = useRootStore();

    useEffect(() => {
      if (!SocketStore.load && uri) {
        logWithState({ state: 'INFO', value: 'ConfigSocket init' });
        SocketStore.init({ uri, token: AuthStore.token });
      }

      if (SocketStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigSocket load' });
      }
    }, [SocketStore.load && SocketStore.client && uri && AuthStore.token]);

    useEffect(() => {
      if (AuthStore.token) {
        SocketStore.setClient({ uri, token: AuthStore.token });
      }
    }, [AuthStore.token]);

    useEffect(() => {
      if (SocketStore.client && !SocketStore.client.connected) {
        SocketStore.client.onAny((event, ...args) => {
          console.log('WS', event, args);
        });

        SocketStore.client.on('connect_error', (error) => {
          logWithState({
            state: 'ERROR',
            value: 'Erreur de connexion WebSocket',
            payload: error,
            size: 'large',
          });
        });

        SocketStore.client.on('connect', () => {
          logWithState({
            state: 'SUCCESS',
            value: 'Connexion WebSocket',
            size: 'large',
          });
        });

        SocketStore.client.on('disconnect', () => {
          logWithState({
            state: 'INFO',
            value: 'Déconnexion WebSocket',
            size: 'large',
          });
        });
      }
    }, [SocketStore.client]);

    return SocketStore.load ? children : null;
  },
);
