import { gql } from '@apollo/client';

export const upsGql = gql`
  query ups($upsId: String!) {
    ups(upsId: $upsId) {
      _id
      code
      center
      occupancy
      capacity
      cameras {
        _id
        code
        position
        ups
        description
      }
      translation {
        lang
        name
        description
      }
      address {
        streetNumber
        streetNumberBis
        streetType
        streetName
        specialPlace
        postalCode
        addressLocality
        addressSubRegion
        addressCountry
        postOfficeBoxNumber
      }
      config {
        fps {
          maximumDuration
          maximumAmount
          system
          notificationAuthority
          reducedPriceDuration
          finePrice
          reducedFinePrice
          recourseOrganization {
            address {
              streetNumber
              streetNumberBis
              streetType
              streetName
              specialPlace
              postalCode
              addressLocality
              addressSubRegion
              addressCountry
              postOfficeBoxNumber
            }
            name
            organizationId
            url
          }
        }
        prestoScan {
          entryTolerancePeriod
          exitTolerancePeriod
          isVerbalizationAllowed
          showOccupancy
          deletionDelay {
            incomplete
            complete
            toCheck
            unfined
            fined
          }
          outrageTreshold {
            danger
            warning
            valid
          }
        }
      }
    }
  }
`;
