import {
  Page,
  Tab,
  Table,
  Widget,
  Zone,
  useMutation,
  useQuery,
} from '@gimlite/watermelon';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Mutation,
  MutationUpdateTranslationArgs,
  Query,
  QueryTranslationArgs,
} from '../../../../client/graphql';
import { translationGql } from '../gql/translation.gql';
import { updateTranslationGql } from '../gql/updateTranslation.gql';
import { translationsRender } from '../projection/translations.render';

export const TranslationsDetails = () => {
  const { dictionaryKey } = useParams();
  const [defaultLang, setDefaultLang]: any = useState({ list: undefined });

  const translationsQuery = useQuery<
    { translation: Query['translation'] },
    QueryTranslationArgs
  >(translationGql, {
    variables: { dictionaryKey: dictionaryKey! },
  });

  const [updateTranslation, updateTranslationState] = useMutation<
    Mutation['updateTranslation'],
    MutationUpdateTranslationArgs
  >(updateTranslationGql, {
    notification: {
      loading: 'Mise à jour de la traduction en cours ...',
      success: 'La traduction a été mise à jour avec succès',
      error: 'Erreur lors de la mise à jour de la traduction',
    },
  });

  const translation = useMemo(() => {
    if (!translationsQuery.data) return null;

    return translationsRender(translationsQuery.data.translation);
  }, [translationsQuery.data]);

  useEffect(() => {
    setDefaultLang(translation?.[0]);
  }, [translation]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['details']],
          rows: ['1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Widget.Group
            state={{
              loading: translationsQuery.loading,
              error: translationsQuery.error,
              refetch: translationsQuery.refetch,
            }}
            config={{ title: 'Liste des traductions' }}
          >
            {defaultLang?.list?.length > 0 && (
              <Zone
                config={{
                  zones: [['tabs'], ['table']],
                  rows: ['min-content', '1fr'],
                  columns: ['1fr'],
                }}
              >
                <Zone.Area config={{ area: 'tabs' }}>
                  <Widget>
                    <Tab
                      data={{
                        items: translation
                          ? translation
                              ?.filter(({ key, label }) => key && label)
                              ?.map(({ key, label }) => ({
                                key: key!,
                                label: label!,
                              }))
                          : [],
                        selected: defaultLang?.key,
                      }}
                      handleEvent={{
                        option: (value) => {
                          const findIndexOption = translation?.findIndex(
                            (item) => item?.key === value,
                          );

                          if (findIndexOption) {
                            setDefaultLang(translation?.[findIndexOption]);
                          }
                        },
                      }}
                    />
                  </Widget>
                </Zone.Area>
                <Zone.Area config={{ area: 'table' }}>
                  <Widget>
                    <Table<any>
                      handleEvent={{
                        update: (value: any) => {
                          const { translationKey, translationValue } = value;

                          updateTranslation({
                            variables: {
                              translations: {
                                [translationKey]: translationValue,
                              },
                              lang: defaultLang?.key,
                              dictionaryKey: dictionaryKey!,
                            },
                          });
                        },
                      }}
                      data={{
                        list: defaultLang?.list,
                        //TODO To fix as paging is curently required
                        paging: { current: 1, count: 1, limit: 1 },
                      }}
                      config={{
                        pagination: 'none',
                        columns: [
                          {
                            title: 'Etat',
                            key: 'state',
                          },
                          {
                            title: 'Traduction',
                            key: 'translationKey',
                          },
                          {
                            title: 'Valeur',
                            key: 'translationValue',
                          },
                        ],
                        actions: {
                          update: ['translationValue'],
                        },
                      }}
                    />
                  </Widget>
                </Zone.Area>
              </Zone>
            )}
          </Widget.Group>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
