import {
  Button,
  FilterExpanded,
  Form,
  Input,
  Page,
  Select,
  Table,
  useMutation,
  useMyUrl,
  useQuery,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import {
  Mutation,
  MutationUpdateCardArgs,
  Query,
  QueryCardsArgs,
} from '../../../client/graphql';
import { searchCardsGql } from './gql/search-cards.gql';
import { updateServiceCardGql } from './gql/update-card.gql';

export const ServiceCards = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { cards: Query['cards'] },
    QueryCardsArgs
  >(searchCardsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const [updateCard, updateCardState] = useMutation<
    Mutation['updateCard'],
    MutationUpdateCardArgs
  >(updateServiceCardGql);

  const cards = useMemo(() => {
    if (!data) return null;

    return data.cards;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{
          error: error,
          loading: loading,
          refetch: refetch,
        }}
        config={{ title: 'Liste des cartes de service' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) =>
                  setParamsUrl({
                    ...getParamsUrl,
                    ...data,
                    page: 1,
                  }),
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'cardIdSearch',
                    label: 'ID',
                  }}
                >
                  <Input
                    data={{
                      value: getParamsUrl?.cardIdSearch || '',
                      defaultValue: '',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'type',
                    label: 'Type',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: '',
                      value: getParamsUrl?.type,
                      items: [
                        { label: '', value: '' },
                        { label: 'master', value: 'master' },
                        { label: 'action', value: 'action' },
                        { label: 'collect', value: 'collect' },
                        { label: 'unknown', value: 'unknown' },
                      ],
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'customerId',
                    label: 'Customer',
                  }}
                >
                  <Input
                    data={{
                      value: getParamsUrl?.customerId || '',
                      defaultValue: '',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'commentSearch',
                    label: 'Comment',
                  }}
                >
                  <Input
                    data={{
                      value: getParamsUrl?.commentSearch,
                    }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>

              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: 'Rechercher',
                    type: { value: 'submit' },
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => {
                      setParamsUrl({});
                      window.location.reload();
                    },
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              _id: string;
              cardId?: string | null;
              type: string;
              customerId?: string | null;
              host?: string | null;
              status?: string | null;
              comment?: string | null;
              pincode: string;
              dt: string;
            }>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({
                    ...getParamsUrl,
                    ...value,
                    cardIdSearch: getParamsUrl?.cardIdSearch
                      ? getParamsUrl.cardIdSearch.toString()
                      : undefined,
                    customerId: getParamsUrl?.customerId
                      ? getParamsUrl.customerId.toString()
                      : undefined,
                  });
                },
                update: ({ _id, pincode, comment }) => {
                  updateCard({
                    variables: {
                      id: _id,
                      comment,
                      pincode: !!pincode,
                    },
                  });
                },
              }}
              config={{
                pagination: 'click',
                columns: [
                  { title: 'ID', key: 'cardId' },
                  { title: 'Type', key: 'type' },
                  { title: 'Customer', key: 'customerId' },
                  { title: 'Host', key: 'host' },
                  { title: 'Status', key: 'status' },
                  { title: 'Comment', key: 'comment' },
                  { title: 'Pincode', key: 'pincode' },
                  { title: 'Creation date', key: 'dt' },
                ],
                actions: {
                  update: ['comment'],
                },
              }}
              data={
                cards
                  ? {
                      list: cards.list.map((card) => ({
                        ...card,
                        pincode: card.pincode ? 'Yes' : 'No',
                      })),
                      paging: cards.paging,
                    }
                  : undefined
              }
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
