import {
  AutoComplete,
  Form,
  useLazyQuery,
  WizardItemType,
} from '@gimlite/watermelon';
import {
  Query,
  QueryUpsArgs,
  QueryUpsListArgs,
} from '../../../../client/graphql';
import { AddParkingInControlUserWizardContext } from '../addParkingInControlUser.wizard';
import { upsGql } from '../gql/ups.gql';
import { upsListGql } from '../gql/upsList.gql';
import { useEffect } from 'react';

export const CameraStep = ({
  context: { user, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<AddParkingInControlUserWizardContext>) => {
  const [upsList, upsListState] = useLazyQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    notification: {
      error: 'Erreur lors de la recherche des parkings',
    },
  });

  const [ups, upsState] = useLazyQuery<{ ups: Query['ups'] }, QueryUpsArgs>(
    upsGql,
    {
      notification: {
        error: 'Erreur lors de la lecture du parking',
      },
    },
  );

  useEffect(() => {
    if (upsListState.data) {
      submit({
        listParking: upsListState.data.upsList.list,
      });
    }
  }, [upsListState.data]);

  useEffect(() => {
    if (upsState.data) {
      submit({
        selectParking: { _id: upsState.data.ups._id },
      });
    }
  }, [upsState.data]);

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {},
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Form.Item
          config={{
            way: 'vertical',
            name: '_id',
            label: 'UPS',
            rules: [{ required: true }],
          }}
        >
          <AutoComplete
            handleEvent={{
              input: (value) => {
                upsList({
                  variables: {
                    codePattern: value,
                  },
                });
              },
              select(item) {
                if (item?.value) {
                  ups({ variables: { upsId: item.value } });
                }
              },
            }}
            data={{
              items: listParking
                .filter(({ _id }) => !user.ups.includes(_id))
                .map(({ code, _id }) => ({
                  value: _id,
                  label: code,
                })),
            }}
            config={{
              search: {
                caseSensitive: false,
                accentSensitive: false,
                pattern: 'start',
              },

              placeholder: 'plan-les-ouates',
              height: 'xlarge',
              width: 'full',
            }}
          />
        </Form.Item>
      </Form.Structure>
    </Form>
  );
};
