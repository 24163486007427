import {
  Form,
  Input,
  isValidCode,
  isValidEmail,
  isValidOperatorCode,
  isValidPhoneNumber,
  Row,
  Upload,
  WizardItemType,
} from '@gimlite/watermelon';

import { CreateOperatorWizardContext } from '../createOperator.wizard';

export const OperatorStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateOperatorWizardContext>) => {
  return (
    <Form
      data={{
        value: context.operator,
      }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ operator: value });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'name',
              label: 'Nom',
              way: 'vertical',
              rules: [{ required: true }],
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'code',
              label: 'Code',
              way: 'vertical',
              rules: [{ required: true }, { validator: isValidCode }],
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'operatorCode',
              label: 'Operator code',
              way: 'vertical',
              rules: [{ required: true }, { validator: isValidOperatorCode }],
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'email',
              label: 'Email',
              way: 'vertical',
              rules: [{ validator: isValidEmail }],
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'phone',
              label: 'Phone',
              way: 'vertical',
              rules: [{ validator: isValidPhoneNumber }],
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'logoMain',
              label: 'Logo principal',
              way: 'vertical',
              rules: [],
            }}
          >
            <Upload config={{ maxFileUpload: 1 }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'logoSecondary',
              label: 'Logo secondaire',
              way: 'vertical',
              rules: [],
            }}
          >
            <Upload config={{ maxFileUpload: 1 }} />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
