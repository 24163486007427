import {
  AutoComplete,
  Form,
  Input,
  Row,
  Select,
  Space,
  useLazyQuery,
  WizardItemType,
} from '@gimlite/watermelon';
import {
  Query,
  QueryUpsArgs,
  QueryUpsListArgs,
} from '../../../../client/graphql';
import { CreateCameraWizardContext } from '../createCamera.wizard';
import { upsGql } from '../gql/ups.gql';
import { upsListGql } from '../gql/upsList.gql';
import { useEffect } from 'react';

export const CameraStep = ({
  context: { camera, listParking },
  validate,
  submit,
}: WizardItemType.Config.Component<CreateCameraWizardContext>) => {
  const [upsList, upsListState] = useLazyQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    notification: {
      error: 'Erreur lors de la recherche des parkings',
    },
  });

  const [ups, upsState] = useLazyQuery<{ ups: Query['ups'] }, QueryUpsArgs>(
    upsGql,
    {
      notification: {
        error: 'Erreur lors de la lecture du parking',
      },
    },
  );

  useEffect(() => {
    if (upsListState.data) {
      submit({
        listParking: upsListState.data.upsList.list,
      });
    }
  }, [upsListState.data]);

  useEffect(() => {
    if (upsState.data) {
      submit({
        selectParking: { _id: upsState.data.ups._id },
      });
    }
  }, [upsState.data]);

  return (
    <Form
      data={{ value: camera }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit({ camera: { ...value } });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'ups',
              label: 'UPS',
              rules: [{ required: true }],
            }}
          >
            <AutoComplete
              handleEvent={{
                input: (value) => {
                  upsList({
                    variables: {
                      codePattern: value,
                    },
                  });
                },
                select(item) {
                  if (item?.value) {
                    ups({
                      variables: {
                        upsId: item.value,
                      },
                    });
                  }
                },
              }}
              data={{
                items: listParking.map(({ code, _id }) => ({
                  value: _id,
                  label: code,
                })),
              }}
              config={{
                search: {
                  caseSensitive: false,
                  accentSensitive: false,
                  pattern: 'start',
                },
                height: 'xlarge',
                width: 'full',
                placeholder: 'plan-les-ouates',
              }}
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'code',
              label: 'Code',
              rules: [{ required: true }],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              way: 'vertical',
              name: 'position',
              label: 'Position',
              rules: [{ required: true }],
            }}
          >
            <Select
              config={{ height: 'xlarge', width: 'full' }}
              data={{
                items: [
                  { label: 'Intérieur', value: 'inside' },
                  { label: 'Extérieur', value: 'outside' },
                ],
              }}
            />
          </Form.Item>
          <Space config={{ way: 'horizontal', count: 2 }} />
          <Form.Item
            config={{
              name: 'description',
              label: 'Description',
              way: 'vertical',
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
