import { Row } from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Upload } from '@gimlite/watermelon/components/upload/upload.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import {
  isValidEmail,
  isValidPhoneNumber,
} from '@gimlite/watermelon/functions/validator.function';
import { UpdateOperatorWizardContext } from '../updateOperator.wizard';

export const OperatorStep = ({
  context: { operator },
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateOperatorWizardContext>) => {
  return (
    <Form
      data={{
        value: operator,
      }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit({ operator: { ...operator, ...value } });
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'name',
              label: 'Nom',
              rules: [{ required: true }],
              way: 'vertical',
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'code',
              label: 'Code',
              rules: [{ required: true }],
              way: 'vertical',
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'operatorCode',
              label: 'Operator code',
              rules: [{ required: true }],
              way: 'vertical',
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'email',
              label: 'Email',
              rules: [{ validator: isValidEmail }],
              way: 'vertical',
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
          <Form.Item
            config={{
              name: 'phone',
              label: 'Phone',
              rules: [{ validator: isValidPhoneNumber }],
              way: 'vertical',
            }}
          >
            <Input config={{ width: 'full', height: 'xlarge' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'logoMain',
              label: 'Logo principal',
              rules: [],
              way: 'vertical',
            }}
          >
            <Upload />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              name: 'logoSecondary',
              label: 'Logo secondaire',
              rules: [],
              way: 'vertical',
            }}
          >
            <Upload />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
