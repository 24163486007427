import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useCallback, useMemo } from 'react';
import {
  Mutation,
  MutationCreateOperatorArgs,
  OperatorEntity,
} from '../../../client/graphql';
import { createOperatorGql } from './gql/createOperator.gql';
import { OperatorStep } from './steps/operator.step';
import axios from 'axios';
import { base64ToUrl } from '../../common/base64Url';

export type CreateOperatorWizardContext = {
  operator: {
    name: string | null;
    code: string | null;
    operatorCode: string | null;
    email: string | null;
    phone: string | null;
    logoMain: Array<[string, string]> | null;
    logoSecondary: Array<[string, string]> | null;
    translation: OperatorEntity['translation'] | null;
  };
};
export const CreateOperatorWizard = () => {
  const { WizardStore } = useRootStore();

  const [createOperator, createOperatorState] = useMutation<
    Mutation['createOperator'],
    MutationCreateOperatorArgs
  >(createOperatorGql);

  const state = useMemo(() => {
    if (createOperatorState.loading) return undefined;
    if (createOperatorState.error) return false;
    if (createOperatorState.data) return true;
  }, [createOperatorState]);

  return (
    <Wizard<CreateOperatorWizardContext>
      config={{
        title: 'Créer un opérateur',
        withValidation: {
          state,
          success: "L'opérateur a été créé avec succès",
          error: "Erreur lors de la création de l'opérateur",
        },
      }}
      data={{
        defaultContext: {
          operator: {
            name: null,
            code: null,
            operatorCode: null,
            email: null,
            phone: null,
            logoMain: null,
            logoSecondary: null,
            translation: null,
          },
        },
      }}
      handleEvent={{
        done: async ({
          operator: {
            name,
            logoMain,
            logoSecondary,
            code,
            operatorCode,
            email,
            phone,
            translation,
          },
        }) => {
          try {
            const display: {
              logoMain: string | null;
              logoSecondary: string | null;
            } = {
              logoMain: logoMain ? await base64ToUrl(logoMain[0]) : null,
              logoSecondary: logoSecondary
                ? await base64ToUrl(logoSecondary[0])
                : null,
            };

            await createOperator({
              variables: {
                input: {
                  translation: [
                    {
                      lang: 'fr_FR',
                      name: name!,
                    },
                    {
                      lang: 'en_EN',
                      name: name!,
                    },
                    {
                      lang: 'it_IT',
                      name: name!,
                    },
                    {
                      lang: 'de_DE',
                      name: name!,
                    },
                  ],
                  display,
                  displayBucket: display,
                  name: name!,
                  code: code!,
                  operatorCode: operatorCode!,
                  email: email || undefined,
                  phone: phone || undefined,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Opérateur', component: OperatorStep }} />
    </Wizard>
  );
};
