import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useMemo } from 'react';
import {
  Mutation,
  MutationCreateControlUsersArgs,
  UpsEntity,
} from '../../../client/graphql';
import { createControlUserGql } from './gql/createControlUser.gql';
import { CredentialsStep } from './steps/credentials.step';
import { DetailsStep } from './steps/details.step';

export const timeoutOnSearch = 200;

export type CreateControlUserWizardContext = {
  credentials: {
    username: string | null;
    password: string | null;
  };
  listParking: Array<UpsEntity>;
  selectParking: {
    _id: string | null;
  };
  user: {
    firstName: string | null;
    lastName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    upsId: string | null;
    lang: string;
  };
};

export const CreateControlUserWizard = () => {
  const { WizardStore } = useRootStore();

  const [createControlUsersGql, createControlUsersState] = useMutation<
    Mutation['createControlUsers'],
    MutationCreateControlUsersArgs
  >(createControlUserGql);

  const state = useMemo(() => {
    if (createControlUsersState.loading) return undefined;
    if (createControlUsersState.error) return false;
    if (createControlUsersState.data) return true;
  }, [createControlUsersState]);

  return (
    <Wizard<CreateControlUserWizardContext>
      config={{
        title: 'Créer un agent',
        withValidation: {
          state,
          success: "L'agent a été créé avec succès",
          error: "Erreur lors de la création de l'agent",
        },
      }}
      data={{
        defaultContext: {
          credentials: {
            username: null,
            password: null,
          },
          listParking: [],
          selectParking: {
            _id: null,
          },
          user: {
            firstName: null,
            lastName: null,
            fpsAgentId: null,
            fpsAgentName: null,
            lang: 'fr_FR',
            upsId: null,
          },
        },
      }}
      handleEvent={{
        done: async ({ credentials, listParking, selectParking, user }) => {
          try {
            await createControlUsersGql({
              variables: {
                input: {
                  firstName: user.firstName!,
                  fpsAgentId: `${user.fpsAgentId}`,
                  fpsAgentName: user.fpsAgentName!,
                  lang: user.lang,
                  lastName: user.lastName!,
                  upsId: selectParking._id!,
                  password: credentials.password!,
                  username: credentials.username!,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item
        config={{ title: 'Credentials', component: CredentialsStep }}
      />
      <Wizard.Item config={{ title: 'Details', component: DetailsStep }} />
    </Wizard>
  );
};
