import { GetProp, Image as ImageAnt, Upload as UploadAnt } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { UploadProps } from 'antd/lib';
import { UploadChangeParam } from 'antd/lib/upload';
import { useCallback, useMemo, useState } from 'react';
import { FormItemType } from '../form/form.component';
import { Icon } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './upload.component.scss';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const natifToValue = (
  file: UploadFile<any>,
  base64: string,
): UploadType.Data.Value => {
  return [file.name, base64];
};

const valueToNatif = (value: UploadType.Data.Value): UploadFile<any> => {
  return {
    name: value[0],
    url: value[1],
    uid: value[0] + value[1],
    status: 'done',
    fileName: value[0],
  };
};

export declare namespace UploadType {
  type Props = {
    className?: string;
    data?: {
      value?: Array<UploadType.Data.Value | null>;
      defaultValue?: Array<UploadType.Data.Value | null>;
    };
    config?: {
      maxFileUpload?: number;
      acceptFileType?: string;
      disabled?: boolean;
      multiple?: boolean;
    };
    handleEvent?: {
      upload?: (value: Array<UploadType.Data.Value>) => void;
    };
  };

  namespace Data {
    type FileName = string;
    type FileUrl = string;
    type Value = [FileName, FileUrl];
  }
}

export const Upload = ({
  data,
  handleEvent: { upload } = {},
  config: {
    maxFileUpload = 1,
    acceptFileType = '*',
    disabled = false,
    multiple = false,
  } = {},
  className = '',
  value: valueFormItem,
  onChange: onChangeFormItem,
  ...formItemParams
}: FormItemType.Legacy<UploadType.Props>) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const fileListNatif = useMemo((): UploadFile<any>[] => {
    const dataFormatted =
      data?.value || data?.defaultValue || valueFormItem || [];
    return dataFormatted.map((value: [string, string]) => valueToNatif(value));
  }, [data, valueFormItem]);

  const handlePreview = useCallback(async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as FileType);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  }, []);

  const handleChange = useCallback(
    async ({ fileList }: UploadChangeParam<UploadFile<any>>) => {
      try {
        const values = await Promise.all(
          fileList.map(async (file) => {
            if (!file.originFileObj) return natifToValue(file, file.url!);
            const base64 = await getBase64(file.originFileObj);
            const value = natifToValue(file, base64);
            return value;
          }),
        );

        upload?.(values);
        onChangeFormItem?.(values);
      } catch (error) {
        return false;
      }
    },
    [],
  );

  return (
    <div className="upload">
      <UploadAnt
        disabled={disabled}
        accept={acceptFileType}
        listType="picture-card"
        fileList={fileListNatif}
        onChange={handleChange}
        onPreview={handlePreview}
        multiple={multiple}
        {...formItemParams}
      >
        {fileListNatif && fileListNatif.length >= maxFileUpload ? null : (
          <button className="upload__button">
            <Icon config={{ type: 'faPlusSolid' }} />
            <Write
              data={{ item: 'Upload' }}
              config={{ mode: 'value-small' }}
            ></Write>
          </button>
        )}
      </UploadAnt>
      {previewImage && (
        <ImageAnt
          wrapperStyle={{ display: 'none' }}
          preview={{
            visible: previewOpen,
            onVisibleChange: (visible) => setPreviewOpen(visible),
            afterOpenChange: (visible) => !visible && setPreviewImage(''),
          }}
          src={previewImage}
        />
      )}
    </div>
  );
};
