import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { Row } from '@gimlite/watermelon/components/row/row.component';
import { Select } from '@gimlite/watermelon/components/select/select.component';
import { Space } from '@gimlite/watermelon/components/space/space.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { CreatePmsTechnicalUserWizardContext } from '../createPmsTechnicalUser.wizard';

export const DetailsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePmsTechnicalUserWizardContext>) => {
  return (
    <Form
      data={{ value: context }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'firstName',
              label: 'Prénom',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{ placeholder: 'Jhon', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'lastName',
              label: 'Nom de famille',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{ placeholder: 'Doe', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'lang',
              label: 'Langue',
              rules: [{ required: true }],
            }}
          >
            <Select
              data={{
                items: [
                  { label: 'fr', value: 'fr_FR' },
                  { label: 'en', value: 'en_EN' },
                ],
              }}
              config={{ width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
