import { ConfigProvider as ConfigProviderAnt, theme as themeAnt } from 'antd';
import { Locale } from 'antd/es/locale';
import de_DE from 'antd/locale/de_DE';
import en_EN from 'antd/locale/en_GB';
import fr_FR from 'antd/locale/fr_FR';
import it_IT from 'antd/locale/it_IT';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Empty } from '../components/empty/empty.component';
import { getCSS } from '../functions/css.function';
import { useRootStore } from '../hook/useRootStore.hook';
import { useTranslation } from '../hook/useTranslation.hook';
import { Languages } from '../types/lang.type';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type AntStoreContextType = {
  langAvailables: Record<Languages, Locale>;
};

export class AntStoreMobX {
  rootStore: RootStoreMobX;
  @observable langAvailables: AntStoreContextType['langAvailables'] | null =
    null;
  @observable load: boolean = false;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action private setLangAvailables(
    langAvailables: AntStoreContextType['langAvailables'],
  ) {
    this.langAvailables = langAvailables;
  }

  @action public init({
    langAvailables,
  }: {
    langAvailables: AntStoreContextType['langAvailables'];
  }) {
    runInAction(() => {
      this.load = false;
      this.setLangAvailables(langAvailables);
      this.load = true;
    });
  }
}

export const ConfigAnt = observer(
  ({ children }: { children: React.ReactNode }) => {
    const { AntStore, ThemeStore } = useRootStore();
    const { lang } = useTranslation();

    useEffect(() => {
      if (!AntStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigAnt init' });

        AntStore.init({ langAvailables: { fr_FR, de_DE, en_EN, it_IT } });
      }

      if (AntStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigAnt load' });
      }
    }, [AntStore.load]);

    return AntStore.load && AntStore.langAvailables ? (
      <ConfigProviderAnt
        locale={
          lang ? AntStore.langAvailables[lang] : AntStore.langAvailables.en_EN
        }
        renderEmpty={() => <Empty />}
        theme={{
          algorithm:
            ThemeStore.theme === 'light'
              ? themeAnt.defaultAlgorithm
              : themeAnt.darkAlgorithm,
          token: {
            colorPrimary: getCSS('--color-primary'),
            colorLink: getCSS('--color-primary'),
            colorInfo: getCSS('--color-primary'),
            colorBgContainer: 'transparent',
            colorBgElevated: getCSS('--color-layout'),
            lineHeight: 1,
            colorBorder: getCSS('--color-border'),
            borderRadius: 6,
          },
          components: {
            Pagination: {
              itemActiveBg: 'transparent',
              itemBg: 'transparent',
              itemInputBg: 'transparent',
              itemLinkBg: 'transparent',
            },
            Segmented: {
              itemActiveBg: getCSS('--color-primary'),
              itemSelectedBg: getCSS('--color-primary'),
              itemHoverBg: getCSS('--color-primary'),
            },
            Steps: {
              iconSize: 40, //! En rem
            },
            Table: {
              bodySortBg: getCSS('--color-primary-alpha-5'),
              rowHoverBg: getCSS('--color-over'),
            },
          },
        }}
      >
        {children}
      </ConfigProviderAnt>
    ) : null;
  },
);
