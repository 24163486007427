import { useRootStore } from '@gimlite/watermelon';
import { observer } from 'mobx-react-lite';
import './debug.component.scss';

export declare namespace DebugType {
  type Props = {
    config: {
      active: boolean;
    };
  };
}

export const Debug = observer(({ config: { active } }: DebugType.Props) => {
  const { I18NStore } = useRootStore();

  return null;
});
