import { useMutation, useRootStore } from '@gimlite/watermelon';
import { Wizard } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useMemo } from 'react';
import {
  CameraPositionEnum,
  Mutation,
  MutationUpdateCameraArgs,
} from '../../../client/graphql.js';
import { updateCameraGql } from './gql/updateCamera.gql';
import { UpsEntity } from './gql/upsList.gql.js';
import { CameraStep } from './steps/camera.step';

export type UpdateCameraWizardContext = {
  camera: {
    _id: string | null;
    code: string | null;
    position: 'inside' | 'outside';
    ups: string | null;
    upsCode: string | null;
    description: string | null;
  };
  listParking: Array<{
    code: UpsEntity['code'];
    _id: UpsEntity['_id'];
  }>;
  selectParking: {
    _id: string | null;
  };
};

export const UpdateCameraWizard = () => {
  const { WizardStore } = useRootStore();
  const { _id, code, position, ups, upsCode, description } =
    WizardStore.selected!.store;

  const [updateCamera, updateCameraState] = useMutation<
    Mutation['updateCamera'],
    MutationUpdateCameraArgs
  >(updateCameraGql);

  const state = useMemo(() => {
    if (updateCameraState.loading) return undefined;
    if (updateCameraState.error) return false;
    if (updateCameraState.data) return true;
  }, [updateCameraState]);

  return (
    <Wizard<UpdateCameraWizardContext>
      config={{
        title: 'Modifier une caméra',
        withValidation: {
          state,
          success: 'La caméra a été mise à jour avec succès',
          error: 'Erreur lors de la mise à jour de la caméra',
        },
      }}
      data={{
        defaultContext: {
          camera: {
            _id,
            code,
            position,
            upsCode,
            description,
            ups: upsCode,
          },
          listParking: [
            {
              code: upsCode!,
              _id: ups!,
            },
          ],
          selectParking: {
            _id: ups,
          },
        },
      }}
      handleEvent={{
        done: async ({ camera, selectParking }) => {
          try {
            await updateCamera({
              variables: {
                input: {
                  cameraId: camera._id,
                  code: camera.code!,
                  description: camera.description!,
                  position: camera.position as CameraPositionEnum,
                  ups: selectParking._id,
                },
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Caméra', component: CameraStep }} />
    </Wizard>
  );
};
