import {
  baseFormattedDate,
  FilterExpanded,
  Page,
  Table,
  useMyUrl,
  useQuery,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Query, QueryBucketsArgs } from '../../../../client/graphql';
import { bucketsGql } from '../gql/buckets.gql';

export const BucketsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { lang } = useTranslation();

  const { data, loading, error, refetch } = useQuery<
    { buckets: Query['buckets'] },
    QueryBucketsArgs
  >(bucketsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const buckets = useMemo(() => {
    if (!data) return null;

    return data.buckets;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{
          error: error,
          loading: loading,
          refetch: refetch,
        }}
        config={{ title: 'Liste des buckets' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions></FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={
                buckets
                  ? {
                      list: buckets.list.map(
                        ({ bucketId, processed, ...rest }) => ({
                          ...rest,
                          bucketId: (
                            <Link to={`/adm/fragments?fragmentRef=${bucketId}`}>
                              {bucketId}
                            </Link>
                          ),
                          processed:
                            typeof processed === 'string'
                              ? baseFormattedDate({
                                  date: new Date(processed).toISOString(),
                                  lang: lang,
                                })
                              : null,
                        }),
                      ),
                      paging: buckets.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'bucketId',
                    key: 'bucketId',
                  },
                  {
                    title: 'terminalId',
                    key: 'terminalId',
                  },
                  {
                    title: 'Processed',
                    key: 'processed',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
