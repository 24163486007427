const stateMap = {
  SUCCESS: '#28a745',
  ERROR: '#dc3545',
  INFO: '#17a2b8',
};

const sizeMap = {
  small: '12px',
  medium: '16px',
  large: '20px',
};

export const logWithState = ({
  state,
  size = 'small',
  value,
  payload,
}: {
  state: 'SUCCESS' | 'ERROR' | 'INFO';
  size?: 'small' | 'medium' | 'large';
  value: string | number;
  payload?: any;
}) => {
  if (payload) {
    console.log(
      `%c ${value}`,
      `color: ${stateMap[state]};font-weight: 600;font-size: ${sizeMap[size]};`,
      payload,
    );
  } else {
    console.log(
      `%c ${value}`,
      `color: ${stateMap[state]};font-weight: 600;font-size: ${sizeMap[size]};`,
    );
  }
};
