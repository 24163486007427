import { observer } from 'mobx-react-lite';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRootStore } from '../../hook/useRootStore.hook';
import { ThemeStoreContextType } from '../../store/theme.store';
import './apploader.component.scss';

const AppLoaderContext = createContext<{
  isLoading: boolean;
  setIsLoading: ((isLoading: boolean) => void) | null;
}>({
  isLoading: false,
  setIsLoading: null,
});

export declare namespace AppLoaderStartType {
  type Props = {
    className?: string;
    config: {
      defaultTheme: ThemeStoreContextType['theme'];
      logo: React.ReactNode | JSX.Element;
    };
    children: React.ReactNode;
  };
}

export const AppLoaderStart = observer(
  ({ children, config, className = '' }: AppLoaderStartType.Props) => {
    const { ThemeStore } = useRootStore();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const currentTheme = ThemeStore.getTheme() || config.defaultTheme;

    return (
      <AppLoaderContext.Provider value={{ isLoading, setIsLoading }}>
        <div className={`appLoader ${className}`}>
          <div
            style={{
              backgroundColor:
                currentTheme === 'dark'
                  ? ThemeStore.registryColors.base.black
                  : ThemeStore.registryColors.base.white,
            }}
            className={`appLoader__content appLoader__content--${!isLoading ? 'progress' : 'stop'}`}
          >
            <div
              style={{
                fill:
                  currentTheme === 'light'
                    ? ThemeStore.registryColors.base.black
                    : ThemeStore.registryColors.base.white,
                color:
                  currentTheme === 'light'
                    ? ThemeStore.registryColors.base.black
                    : ThemeStore.registryColors.base.white,
              }}
              className={`appLoader__content__logo`}
            >
              {config.logo}
            </div>
          </div>

          {children}
        </div>
      </AppLoaderContext.Provider>
    );
  },
);

export declare namespace AppLoaderEndType {
  type Props = {
    children: any;
  };
}

export const AppLoaderEnd = ({ children }: AppLoaderEndType.Props) => {
  const { setIsLoading } = useContext(AppLoaderContext);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(true);
    }
  }, [setIsLoading]);

  return children ? children : null;
};
