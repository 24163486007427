import {
  Button,
  FilterExpanded,
  Page,
  Popover,
  Row,
  Space,
  Table,
  Widget,
  Zone,
  useMutation,
  useMyUrl,
  useQuery,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Mutation,
  MutationDeleteCameraArgs,
  Query,
  QueryCamerasArgs,
} from '../../../../client/graphql';
import { camerasGql } from '../gql/cameras.gql';
import { deleteCameraGql } from '../gql/deleteCamera.gql';

export const CameraSearch = () => {
  const navigate = useNavigate();
  const { t, lang } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { cameras: Query['cameras'] },
    QueryCamerasArgs
  >(camerasGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const [deleteCamera, deleteCameraState] = useMutation<
    Mutation['deleteCamera'],
    MutationDeleteCameraArgs
  >(deleteCameraGql, {
    notification: {
      loading: 'Suppression de la caméra en cours ...',
      success: 'La caméra a été supprimée avec succès',
      error: 'Erreur lors de la suppression de la caméra',
    },
  });

  const cameras = useMemo(() => {
    if (!data) return null;

    return data.cameras;
  }, [data]);

  useEffect(() => {
    if (deleteCameraState.data) {
      refetch();
    }
  }, [deleteCameraState?.data, refetch]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des caméras' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: 'Créer une caméra',
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () => WizardStore.select({ id: 'createCamera' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value) => {
                  navigate(`/prestoscan/cameras/${value._id}`);
                },
              }}
              data={
                cameras
                  ? {
                      list: cameras.list.map((element: any) => ({
                        ...element,
                        action: (
                          <Row>
                            <Button
                              handleEvent={{
                                click: () => {
                                  WizardStore.select({
                                    id: 'updateCamera',
                                    store: {
                                      _id: element._id,
                                      code: element.code,
                                      position: element.position,
                                      ups: element.ups,
                                      upsCode: element.upsCode,
                                      description: element.description,
                                    },
                                  });
                                },
                              }}
                              config={{
                                text: 'Modifier',
                                color: 'warn',
                                size: 'small',
                                stopPropagation: true,
                              }}
                            ></Button>
                            <Space config={{ way: 'horizontal' }}></Space>

                            <Popover.Confirm
                              handleEvent={{
                                confirm: () => {
                                  deleteCamera({
                                    variables: {
                                      input: {
                                        cameraId: element._id,
                                      },
                                    },
                                  });
                                },
                              }}
                              config={{
                                title: t('watermelon-importantAction'),
                                description: t(
                                  'watermelon-areYouSureAboutThis?',
                                ),
                              }}
                            >
                              <Button
                                config={{
                                  text: 'Supprimer',
                                  color: 'error',
                                  size: 'small',
                                }}
                              ></Button>
                            </Popover.Confirm>
                          </Row>
                        ),
                      })),
                      paging: cameras.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    key: '_id',
                    title: 'Id',
                  },
                  {
                    key: 'code',
                    title: 'Code',
                  },
                  {
                    key: 'position',
                    title: 'Position',
                  },
                  {
                    key: 'description',
                    title: 'Description',
                  },
                  {
                    key: 'upsCode',
                    title: 'UpsCode',
                  },
                  {
                    key: 'action',
                    title: 'Actions',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
