import {
  Button,
  Description,
  FilterExpanded,
  Page,
  Popover,
  Row,
  Table,
  Widget,
  Zone,
  languages,
  useMutation,
  useQuery,
  useRootStore,
  useTranslation,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Mutation,
  Query,
  QueryControlUserArgs,
} from '../../../../client/graphql';
import { controlUserGql } from '../gql/control-user.gql';
import {
  UpdateControlUsersParams,
  updateControlUsersGql,
} from '../gql/updateControlUser.gql';

export const ControlUsersDetails = () => {
  const { userId } = useParams();
  const { t, lang } = useTranslation();
  const { WizardStore } = useRootStore();

  const { data, loading, error, refetch } = useQuery<
    { controlUser: Query['controlUser'] },
    QueryControlUserArgs
  >(controlUserGql, {
    variables: { userId: userId! },
  });

  const [updateControlUsers, updateControlUsersState] = useMutation<
    { updateControlUsers: Mutation['updateControlUsers'] },
    {
      input: Pick<UpdateControlUsersParams['input'], 'userId' | 'ups'>;
    }
  >(updateControlUsersGql);

  const controlUser = useMemo(() => {
    if (!data) return null;

    return data.controlUser;
  }, [data]);

  useEffect(() => {
    if (updateControlUsersState.data) {
      refetch();
    }
  }, [updateControlUsersState?.data, refetch]);

  return (
    <Page>
      <Zone
        config={{
          zones: [['details'], ['table']],
          rows: ['min-content', '1fr'],
          columns: ['1fr'],
        }}
      >
        <Zone.Area config={{ area: 'details' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: "Détails de l'agent" }}
          >
            <Description
              data={[
                {
                  key: 'email',
                  label: 'Adresse mail',
                  value: controlUser?.username,
                },
                {
                  key: 'firstName',
                  label: 'Prénom',
                  value: controlUser?.firstName,
                },
                {
                  key: 'lastName',
                  label: 'Nom de famille',
                  value: controlUser?.lastName,
                },
                {
                  key: 'fpsAgentId',
                  label: 'fpsAgentId',
                  value: controlUser?.fpsAgentId,
                },
                {
                  key: 'fpsAgentName',
                  label: 'fpsAgentName',
                  value: controlUser?.fpsAgentName,
                },
                {
                  key: 'lang',
                  label: 'Langue',
                  value: controlUser?.lang
                    ? languages.fr_FR.find(
                        ({ value }) => value === controlUser?.lang,
                      )?.label
                    : undefined,
                },
              ]}
            ></Description>
          </Widget>
        </Zone.Area>
        <Zone.Area config={{ area: 'table' }}>
          <Widget
            state={{ loading, error, refetch }}
            config={{ title: 'Liste des UPS' }}
          >
            <Zone
              config={{
                zones: [['filter'], ['list']],
                rows: ['min-content', '1fr'],
                columns: ['1fr'],
              }}
            >
              <Zone.Area config={{ area: 'filter' }}>
                <FilterExpanded>
                  <FilterExpanded.Fields></FilterExpanded.Fields>
                  <FilterExpanded.Actions>
                    <Button
                      config={{
                        text: 'Ajouter un UPS',
                        type: { value: 'button' },
                      }}
                      handleEvent={{
                        click: () => {
                          WizardStore.select({
                            id: 'addParkingInControlUser',
                            store: {
                              user: {
                                _id: controlUser?._id,
                                ups: controlUser?.ups,
                              },
                            },
                          });
                        },
                      }}
                    />
                  </FilterExpanded.Actions>
                </FilterExpanded>
              </Zone.Area>

              <Zone.Area config={{ area: 'list' }}>
                <Table<any>
                  data={
                    controlUser?.upsList
                      ? {
                          list: controlUser?.upsList.list.map(
                            (element: any) => ({
                              ...element,
                              action: (
                                <Row>
                                  <Popover.Confirm
                                    handleEvent={{
                                      confirm: () => {
                                        updateControlUsers({
                                          variables: {
                                            input: {
                                              userId: controlUser._id,
                                              ups: (controlUser?.ups
                                                ? controlUser.ups.filter(
                                                    (value) =>
                                                      value !==
                                                      (element._id as string),
                                                  )
                                                : null) as [string] | null,
                                            },
                                          },
                                        });
                                      },
                                    }}
                                    config={{
                                      title: t('watermelon-importantAction'),
                                      description: t(
                                        'watermelon-areYouSureAboutThis?',
                                      ),
                                    }}
                                  >
                                    <Button
                                      config={{
                                        text: 'Supprimer',
                                        color: 'error',
                                        size: 'small',
                                      }}
                                    ></Button>
                                  </Popover.Confirm>
                                </Row>
                              ),
                            }),
                          ),
                          paging: controlUser?.upsList.paging,
                        }
                      : undefined
                  }
                  config={{
                    columns: [
                      {
                        title: 'ID',
                        key: '_id',
                      },
                      {
                        title: 'Code',
                        key: 'code',
                      },
                      {
                        key: 'action',
                        title: 'Actions',
                      },
                    ],
                  }}
                />
              </Zone.Area>
            </Zone>
          </Widget>
        </Zone.Area>
      </Zone>
    </Page>
  );
};
