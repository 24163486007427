import {
  Badge,
  Button,
  FilterExpanded,
  Form,
  Input,
  Page,
  Row,
  Table,
  useMyUrl,
  useQuery,
  useRootStore,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Query, QuerySitesArgs } from '../../../../client/graphql';
import { sitesGql } from '../gql/sites.gql';
import { siteDetailsDefaultParamsEncode } from './sites.details';

export const SitesSearch = () => {
  const navigate = useNavigate();
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();
  const { lang } = useTranslation();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { sites: Query['sites'] },
    QuerySitesArgs
  >(sitesGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const sites = useMemo(() => {
    if (!data) return null;

    return data.sites;
  }, [data]);

  const states = useMemo(
    (): Record<string, React.ReactNode> => ({
      down: <Badge config={{ color: 'error', text: 'DOWN' }} />,
      'not-responding': <Badge config={{ color: 'error', text: 'TIMEOUT' }} />,
      up: <Badge config={{ color: 'success', text: 'UP' }} />,
      degraded: <Badge config={{ color: 'warn', text: 'DEGRADED' }} />,
      default: <Badge config={{ color: 'error', text: 'UNKNOWN' }} />,
    }),
    [lang],
  );

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des sites' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'terminalId',
                    label: 'UID',
                  }}
                >
                  <Input data={{ defaultValue: getParamsUrl?.test1 }} />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: 'Rechercher',
                    type: { value: 'submit' },
                  }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
                <Button
                  config={{
                    text: 'Créer un site',
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () => WizardStore.select({ id: 'createSite' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(
                    `/onstreet/sites/${value._id}/global?${siteDetailsDefaultParamsEncode}`,
                  );
                },
              }}
              data={
                sites
                  ? {
                      list: sites.list.map((site) => ({
                        ...site,
                        clientSlug: site.client.slug,
                        upsCode: site.ups.code,
                        state: states[site.monitoring?.status || 'default'],
                        action: (
                          <Row>
                            <Button
                              handleEvent={{
                                click: () => {
                                  WizardStore.select({
                                    id: 'updateSite',
                                    store: {
                                      siteId: site._id,
                                    },
                                  });
                                },
                              }}
                              config={{
                                text: 'Modifier',
                                color: 'warn',
                                size: 'small',
                                stopPropagation: true,
                              }}
                            ></Button>
                          </Row>
                        ),
                      })),
                      paging: sites.paging,
                    }
                  : undefined
              }
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'Terminal UID',
                    key: 'terminalId',
                  },
                  {
                    title: 'Name',
                    key: 'name',
                  },
                  {
                    title: 'Client',
                    key: 'clientSlug',
                  },
                  {
                    title: 'UPS',
                    key: 'upsCode',
                  },
                  {
                    title: 'Actions',
                    key: 'action',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
