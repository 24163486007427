import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Mutation, MutationCreateSiteArgs } from '../../../client/graphql';
import { createSiteGql } from './gql/createSite.gql';
import { SiteStep } from './steps/site.step';

export type CreateSiteWizardContext = any;

export const CreateSiteWizard = () => {
  const { WizardStore } = useRootStore();

  const [createSite, createSiteState] = useMutation<
    Mutation['createSite'],
    MutationCreateSiteArgs
  >(createSiteGql);

  const state = useMemo(() => {
    if (createSiteState.loading) return undefined;
    if (createSiteState.error) return false;
    if (createSiteState.data) return true;
  }, [createSiteState]);

  return (
    <Wizard<CreateSiteWizardContext>
      config={{
        title: 'Créer un site',
        withValidation: {
          state,
          success: 'Le site a été créé avec succès',
          error: 'Une erreur est survenue lors de la création du site',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (payload) => {
          try {
            const {
              latitude,
              longitude,
              selectParking: { _id: upsId },
              selectClient: { _id: clientId },
              listClient,
              listParking,
              ...rest
            } = payload;

            // Parse coordinates.
            const coordinates =
              latitude && longitude
                ? [parseFloat(latitude), parseFloat(longitude)]
                : undefined;

            // Prepare mutation $input variable
            const input = {
              ...rest,
              coordinates,
              upsId,
              clientId,
            };

            await createSite({
              variables: {
                input,
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Site', component: SiteStep }} />
    </Wizard>
  );
};
