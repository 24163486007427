import {
  Form,
  Input,
  Row,
  Select,
  WizardItemType,
  isValidIANA,
  isValidLocale,
} from '@gimlite/watermelon';
import { CreateClientWizardContext } from '../createClient.wizard';

export const ClientStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateClientWizardContext>) => {
  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit(value);
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'name',
              label: 'Name',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'Plan les Ouates',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'slug',
              label: 'Slug',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'plan-les-ouates',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'code',
              label: 'Code',
              rules: [{ required: true, max: 4 }],
            }}
          >
            <Input
              config={{ placeholder: 'PLO1', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'ticketsHub',
              label: 'Tickets Hub',
              rules: [{ required: true }],
            }}
          >
            <Select
              data={{
                items: [
                  { label: 'Iem', value: 'iem' },
                  { label: 'Prestopark', value: 'prestopark' },
                ],
              }}
              config={{
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'defaultContractTag',
              label: 'Contract Tag',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: 'iem/city',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'siret',
              label: 'Siret',
              rules: [{ required: true, min: 14 }],
            }}
          >
            <Input
              config={{
                placeholder: '12345678987654321',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'timezone',
              label: 'Timezone',
              rules: [{ required: true }, { validator: isValidIANA }],
            }}
          >
            <Input
              config={{
                placeholder: 'Europe/Paris',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'locale',
              label: 'Locale',
              rules: [{ required: true }, { validator: isValidLocale }],
            }}
          >
            <Input
              config={{ placeholder: 'fr_FR', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'defaultCurrency',
              label: 'Currency',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{ placeholder: 'CHF', width: 'full', height: 'xlarge' }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'maximumDuration',
              label: 'Maximum duration',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: '120',
                type: { name: 'number' },
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'maximumAmount',
              label: 'Maximum amount',
              rules: [{ required: true }],
            }}
          >
            <Input
              config={{
                placeholder: '600',
                type: { name: 'number' },
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
