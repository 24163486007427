import React from 'react';
import './page.component.scss';

export declare namespace PageType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    config?: {
      overflow?: boolean;
    };
  };
}

export const Page = ({
  children,
  className = '',
  config: { overflow = false } = {},
}: PageType.Props) => {
  return (
    <div className={`page ${className}`}>
      <div
        style={{ overflow: overflow ? 'initial' : 'auto' }}
        className="page__contain"
      >
        {children}
      </div>
    </div>
  );
};
