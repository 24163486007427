import {
  Form,
  Input,
  Row,
  useQuery,
  WizardItemType,
} from '@gimlite/watermelon';
import { Query, QuerySiteArgs } from '../../../../client/graphql';
import { readSiteGql } from '../gql/readSite.gql';
import { UpdateSiteWizardContext } from '../updateSite.wizard';

export const SingleStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<UpdateSiteWizardContext>) => {
  const { data, loading, error, refetch } = useQuery<
    { site: Query['site'] },
    QuerySiteArgs
  >(readSiteGql, {
    variables: { siteId: context.siteId },
    notification: {
      error: "Le site n'a pas été récupéré",
    },
  });

  const site = data?.site;

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value) => {
          submit(value);
        },
        validate: (value) => {
          validate(value);
        },
      }}
    >
      {site && (
        <Form.Structure
          config={{ layout: 'column', width: 'full', fill: true }}
        >
          <Row config={{ gap: 20 }}>
            <Form.Item
              data={{ defaultValue: site.name ?? '' }}
              config={{
                way: 'vertical',
                name: 'name',
                label: 'Nom',
                rules: [{ required: true }],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
            <Form.Item
              data={{ defaultValue: site.terminalId ?? '' }}
              config={{
                way: 'vertical',
                name: 'terminalId',
                label: 'Terminal ID',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
          </Row>
          <Row config={{ gap: 20 }}>
            <Form.Item
              data={{ defaultValue: site.address1 ?? '' }}
              config={{
                way: 'vertical',
                name: 'address1',
                label: 'Adresse (1/2)',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
            <Form.Item
              data={{ defaultValue: site.address2 ?? '' }}
              config={{
                way: 'vertical',
                name: 'address2',
                label: 'Adresse (2/2)',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
            <Form.Item
              data={{ defaultValue: site.zipcode ?? '' }}
              config={{
                way: 'vertical',
                name: 'zipcode',
                label: 'Code postal',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
            <Form.Item
              data={{ defaultValue: site.city ?? '' }}
              config={{
                way: 'vertical',
                name: 'city',
                label: 'Ville',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
            <Form.Item
              data={{ defaultValue: site.country ?? '' }}
              config={{
                way: 'vertical',
                name: 'country',
                label: 'Pays',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
          </Row>
          <Row config={{ gap: 20 }}>
            <Form.Item
              data={{ defaultValue: site.coordinates?.[0] ?? '' }}
              config={{
                way: 'vertical',
                name: 'latitude',
                label: 'Latitude',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>

            <Form.Item
              data={{ defaultValue: site.coordinates?.[1] ?? '' }}
              config={{
                way: 'vertical',
                name: 'longitude',
                label: 'Longitude',
                rules: [],
              }}
            >
              <Input config={{ height: 'xlarge', width: 'full' }} />
            </Form.Item>
          </Row>
        </Form.Structure>
      )}
    </Form>
  );
};
