import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

export type MapsStoreContextType = {
  token: string;
};

export class MapsStoreMobX {
  rootStore: RootStoreMobX;
  @observable token: MapsStoreContextType['token'] | null = null;
  @observable load: boolean = false;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public init({ token }: { token: MapsStoreContextType['token'] }) {
    runInAction(() => {
      this.load = false;
      this.token = token;
      this.load = true;
    });
  }
}

export const ConfigMaps = observer(
  ({ children, token }: { children: React.ReactNode; token: string }) => {
    const { MapsStore } = useRootStore();

    useEffect(() => {
      if (!MapsStore.load && token) {
        logWithState({ state: 'INFO', value: 'ConfigMaps init' });
        MapsStore.init({ token });
      }

      if (MapsStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigMaps load' });
      }
    }, [MapsStore.load, token]);

    return MapsStore.load && MapsStore.token ? children : null;
  },
);
