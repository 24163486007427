import { useEffect, useMemo } from 'react';
import { Wizard } from '../../components/wizard/wizard.component';
import { activate2FAGql } from '../../gql/activate2FA.gql';
import { deactivate2FAGql } from '../../gql/deactivate2FA.gql';
import { useMutation } from '../../hook/useMutation.hook';
import { useRootStore } from '../../hook/useRootStore.hook';
import { useTranslation } from '../../hook/useTranslation.hook';
import { ConfirmStep } from './steps/confirm/confirm.step';
import { SynchronizeStep } from './steps/synchronize/synchronize.step';

export type State2FAContext = {
  codeFromEmail: string | null;
  codeFromAuth: string | null;
  uri: string | null;
  label: string | null;
  secret: string | null;
  tfaLabel: string | null;
  withEmailCode: boolean;
};

export const state2FAWizard = () => {
  const { t, lang } = useTranslation();

  const { AuthStore, WizardStore, GQLStore } = useRootStore();
  const { tfaActivated } = WizardStore.selected?.store;

  const [deactivate2FA, deactivate2FAState] = useMutation(deactivate2FAGql, {
    notification: {
      loading: 'Le désactivation du 2FA est en cours',
      error: 'Une erreur est survenue lors du désactivation du 2FA',
      success: 'Le 2FA a été désactivé avec succès',
    },
  });

  const [activate2FA, activate2FAState] = useMutation(activate2FAGql, {
    notification: {
      loading: 'Le activation du 2FA est en cours',
      error: 'Une erreur est survenue lors du activation du 2FA',
      success: 'Le 2FA a été activé avec succès',
    },
  });

  const state = useMemo(() => {
    if (!tfaActivated) {
      if (activate2FAState.loading) return undefined;
      if (activate2FAState.error) return false;
      if (activate2FAState.data)
        return !!deactivate2FAState.data?.activate2FA?.success;
    } else {
      if (deactivate2FAState.loading) return undefined;
      if (deactivate2FAState.error) return false;
      if (deactivate2FAState.data)
        return !!deactivate2FAState.data?.deactivate2FA?.success;
    }
  }, [activate2FAState]);

  useEffect(() => {
    if (
      AuthStore.load &&
      GQLStore.client &&
      GQLStore.introspection &&
      (activate2FAState.data || deactivate2FAState.data)
    ) {
      AuthStore.setMe({
        client: GQLStore.client!,
        introspection: GQLStore.introspection!,
      });
    }
  }, [
    AuthStore.load,
    activate2FAState.data,
    deactivate2FAState.data,
    GQLStore.client,
    GQLStore.introspection,
  ]);

  return typeof tfaActivated === 'boolean' ? (
    <Wizard<State2FAContext>
      config={{
        withValidation: {
          error: tfaActivated
            ? t('watermelon-2FAAuthenticationCouldNotBeDeactivated')
            : t('watermelon-2FAAuthenticationCouldNotBeActivated'),
          state: state,
          success: tfaActivated
            ? t('watermelon-2FAAuthenticationHasBeenDisabled')
            : t('watermelon-2FAAuthenticationHasBeenActivated'),
        },
        title: tfaActivated
          ? t(`watermelon-desactivate2FAWithGoogleAuthentificator`)
          : t(`watermelon-activate2FAWithGoogleAuthentificator`),
      }}
      data={{
        defaultContext: {
          codeFromAuth: null,
          codeFromEmail: null,
          secret: null,
          label: null,
          uri: null,
          tfaLabel: AuthStore?.features?.tfa?.label || null,
          withEmailCode: !tfaActivated,
        },
      }}
      handleEvent={{
        done: async ({ codeFromAuth, codeFromEmail }) => {
          if (tfaActivated) {
            if (codeFromAuth) {
              await deactivate2FA({
                variables: { input: { tfaToken: codeFromAuth } },
              });
            }
          } else {
            if (codeFromAuth && codeFromEmail) {
              await activate2FA({
                variables: {
                  input: {
                    otpToken: codeFromEmail,
                    tfaToken: codeFromAuth,
                  },
                },
              });
            }
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: async () => {
          await AuthStore.setMe({
            client: GQLStore.client!,
            introspection: GQLStore.introspection!,
          });

          WizardStore.clear();
        },
      }}
    >
      {!tfaActivated && (
        <Wizard.Item
          config={{
            title: t('watermelon-synchronize'),
            component: SynchronizeStep,
          }}
        />
      )}
      <Wizard.Item
        config={{ title: t('watermelon-confirm'), component: ConfirmStep }}
      />
    </Wizard>
  ) : (
    <></>
  );
};
