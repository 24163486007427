import { EnergiesPoint, Query } from '../../../../client/graphql';

const consumable = ['secbat', 'pribat'];

export const statsEnergie = (energiesGraph: EnergiesPoint[]) => {
  const energiesMean = energiesGraph?.reduce(
    (
      acc: Record<keyof Omit<EnergiesPoint['p'], '__typename' | 'sol'>, number>,
      item,
    ) => {
      if (!item?.p) return acc;

      const {
        p: { __typename, sol, ...p },
      } = item;

      const allObject = { ...p };
      let newObject = { ...allObject, ...acc };

      Object.entries(allObject).forEach(([key, value]: [string, number]) => {
        const keyType = key as keyof typeof acc;
        const exist = acc?.[keyType];

        if (typeof exist === 'number') {
          newObject = { ...newObject, [keyType]: newObject[keyType] + value };
        } else {
          newObject = { ...newObject, [keyType]: value };
        }
      });

      return newObject;
    },
    {},
  );

  const energiesAvg = Object.entries(energiesMean).map(([key, value]) => {
    return [key, typeof value === 'number' ? value / energiesGraph.length : 0];
  }) as [string, number][];

  const energiesGroup = energiesAvg.reduce(
    (acc, [key, value]) => {
      if (consumable.includes(key)) acc.prod = acc.prod + value;
      else acc.conso = acc.conso + value;
      acc.balance = acc.prod - acc.conso;

      return acc;
    },
    { prod: 0, conso: 0, balance: 0 },
  );

  energiesGroup.conso = energiesGroup.conso * -1;

  return energiesGroup;
};

export const energieGlobalRender = (energies: Query['site']['energies']) => {
  const energiesGraph = energies?.graph;

  const puissanceFormatted = energiesGraph
    ?.map(({ logDate, p }) => {
      if (!p) return null;

      const { __typename, ...restP } = p;

      return {
        date: new Date(logDate),
        ...(restP as {
          bat: number | null;
          com: number | null;
          core: number | null;
          eft: number | null;
          lpt: number | null;
          misc: number | null;
          pribat: number | null;
          secbat: number | null;
          sol: number | null;
        }),
      };
    })
    ?.filter((item) => !!item);

  const tensionsFormatted = energiesGraph
    ?.map(({ logDate, u }) => {
      if (!u) return null;

      const { __typename, ...restU } = u;

      return {
        date: new Date(logDate),
        ...(Object.fromEntries(
          Object.entries(restU).map(([key, value]) => [key, value / 1000]),
        ) as {
          pribat: number | null;
          secbat: number | null;
          sol: number | null;
        }),
      };
    })
    ?.filter((item) => !!item);

  const tPeriphFormatted = energiesGraph
    ?.map(({ logDate, stats }) => {
      if (!stats) return null;

      const { __typename, ...restStats } = stats;

      return {
        date: new Date(logDate),
        ...(Object.fromEntries(
          Object.entries(restStats.tp).map(([key, value]) => [
            key,
            typeof value === 'number' ? value * 1000 : 0,
          ]),
        ) as {
          coin_ps_coin: number | null;
          door_ps_door: number | null;
          eft_ps_eft: number | null;
          eft_ps_uart6: number | null;
          escrow_ps_coin: number | null;
          heater_ps_heater: number | null;
          motion_ps_motion: number | null;
          nfc_ps_nfc: number | null;
          plug1_ps_plug1: number | null;
          plug2_ps_plug2: number | null;
          printer_ps_printer: number | null;
          safe_ps_safe: number | null;
          shutter_ps_coin: number | null;
        }),
      };
    })
    ?.filter((item) => !!item);

  const tMachineFormatted = energiesGraph
    ?.map(({ logDate, p }) => {
      if (!p) return null;

      const { __typename, pribat, secbat, sol, ...restIsperiph } = p;

      const periph =
        Object.values(restIsperiph).reduce((acc, value) => acc + value, 0) * -1;

      const consume = pribat + secbat;

      return {
        date: new Date(logDate),
        pribat: pribat,
        secbat: secbat,
        periph,
        balance: consume + periph,
      };
    })
    ?.filter((item) => !!item);
  const meteoFormatted = energiesGraph
    ?.map(({ logDate, stats }) => {
      if (!stats) return null;

      const { __typename, ...restStats } = stats;

      return {
        date: new Date(logDate),
        hum: restStats.hum as number | null,
        temp: restStats.temp as number | null,
      };
    })
    ?.filter((item) => !!item);

  return {
    stats: energiesGraph ? statsEnergie(energiesGraph) : null,
    meteo: meteoFormatted,
    tensions: tensionsFormatted,
    puissance: puissanceFormatted,
    tPeriph: tPeriphFormatted,
    tMachine: tMachineFormatted,
  };
};
