import { useRootStore } from '@gimlite/watermelon';
import {
  Wizard,
  WizardType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useState } from 'react';
import { AccountStep } from './steps/account.step';
import { TypeStep } from './steps/type.step';

export type CreateUserWizardContext = {
  accounts: Array<{
    _id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    username?: string | null;
    roles: Array<string>;
  }>;
  selectedAccount: string | null;
  selectedType: string | null;
};

export const CreateUserWizard = () => {
  const { WizardStore } = useRootStore();
  //! TODO: remove this shitty state
  const [result, setResult] = useState<boolean>();

  const [resultDetails, setResultDetails] = useState<
    Array<WizardType.Config.Details>
  >([]);

  return (
    <Wizard<CreateUserWizardContext>
      config={{
        title: 'Créer un utilisateur',
        withValidation: {
          state: result,
          success: "L'inscription de l'utilisateur a été réalisée !",
          error: "Une erreur est survenue lors de la création de l'utilisateur",
          details: resultDetails,
        },
      }}
      data={{
        defaultContext: {
          accounts: [],
          selectedAccount: null,
          selectedType: null,
        },
      }}
      handleEvent={{
        done: async (value) => {
          //! TODO: create user
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Account', component: AccountStep }} />
      <Wizard.Item config={{ title: 'Type', component: TypeStep }} />
    </Wizard>
  );
};
