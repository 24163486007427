import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { gsap } from 'gsap';
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { setCSS } from '../functions/css.function';
import { useRootStore } from '../hook/useRootStore.hook';
import packageJSON from '../package.json';
import '../sass/base/base.scss';
import '../sass/base/font.scss';
import '../sass/base/reset.scss';
import { RootStoreMobX } from './root.store';
import { logWithState } from '../functions/console.function';

Chart.register(...registerables);
Chart.register(zoomPlugin);

gsap.config({
  autoSleep: 60,
  force3D: false,
  nullTargetWarn: true,
});

export type AppStoreContextType = {
  custom: any;
  version: {
    env?: string | null;
    front?: string | null;
    watermelon?: string;
  };
  debug?: boolean;
  isResponsive: boolean;
  favicon: string;
};

export class AppStoreMobX {
  rootStore: RootStoreMobX;
  @observable custom: AppStoreContextType['custom'] | null = null;
  @observable version: AppStoreContextType['version'] | null = null;
  @observable debug: AppStoreContextType['debug'] | null = null;
  @observable isResponsive: AppStoreContextType['isResponsive'] | null = null;
  @observable load: boolean = false;
  @observable favicon: AppStoreContextType['favicon'] | null = null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public setFavicon(faviconUrl: string | null) {
    if (faviconUrl) {
      const favicon = document.createElement('link');
      favicon.rel = 'icon';
      favicon.href = faviconUrl;
      document.head.appendChild(favicon);
      this.favicon = faviconUrl;
    }
  }

  @action public init({
    custom,
    version,
    debug,
    isResponsive,
    favicon,
  }: {
    custom: AppStoreContextType['custom'] | null;
    version: AppStoreContextType['version'] | null;
    debug: AppStoreContextType['debug'] | null;
    isResponsive: boolean | null;
    favicon: AppStoreContextType['favicon'] | null;
  }) {
    runInAction(() => {
      this.load = false;
      this.custom = custom;
      this.version = version;
      this.debug = debug;
      this.isResponsive = !!isResponsive;
      this.load = true;
      this.setFavicon(favicon);
    });
  }
}

export const ConfigApp = observer(
  ({
    children,
    isResponsive,
    custom,
    version,
    debug,
    favicon,
  }: {
    children: React.ReactNode;
    isResponsive?: AppStoreContextType['isResponsive'] | null;
    custom?: AppStoreContextType['custom'] | null;
    version?: AppStoreContextType['version'] | null;
    debug?: AppStoreContextType['debug'] | null;
    favicon?: AppStoreContextType['favicon'] | null;
  }) => {
    const { AppStore } = useRootStore();

    useEffect(() => {
      if (!AppStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigApp init' });

        AppStore.init({
          custom: custom || null,
          version: {
            ...(version || {}),
            watermelon: packageJSON?.version,
          },
          debug: debug || null,
          isResponsive: isResponsive || null,
          favicon: favicon || null,
        });
      }

      if (AppStore.load) {
        logWithState({ state: 'INFO', value: 'ConfigApp load' });
      }
    }, [AppStore.load]);

    useEffect(() => {
      const windowSizeToCSS = () => {
        setCSS('--vh', `${window.innerHeight * 0.01}px`);
        setCSS('--vw', `${window.innerWidth * 0.01}px`);
      };

      windowSizeToCSS();
      window.addEventListener('resize', windowSizeToCSS);

      return () => {
        window.removeEventListener('resize', windowSizeToCSS);
      };
    }, []);

    return AppStore.load ? children : null;
  },
);
