import {
  Button,
  FilterExpanded,
  Page,
  Row,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
  useRootStore,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Query, QueryAdminUsersArgs } from '../../../../client/graphql';
import { adminUsersGql } from '../gql/admin-users.gql';

export const AdminUsersSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { adminUsers: Query['adminUsers'] },
    QueryAdminUsersArgs
  >(adminUsersGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const adminUsers = useMemo(() => {
    if (!data) return null;

    return data?.adminUsers;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Listes des utilisateurs' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{
                    text: "Création d'un administrateur",
                    type: { value: 'button' },
                  }}
                  handleEvent={{
                    click: () => WizardStore.select({ id: 'createAdminUser' }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'table' }}>
            <Table<{
              username?: string | null;
              state?: string | null;
              _id: string;
            }>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value) => {
                  setParamsUrl({ ...getParamsUrl, id: value._id });
                },
              }}
              data={
                adminUsers
                  ? {
                      list: adminUsers?.list.map(
                        ({ credentials, state, _id }, index) => ({
                          username: credentials?.find(
                            (item) => item && item?.type === 'EMAIL',
                          )?.value,
                          state,
                          _id: `${_id}-${index}`,
                          action: (
                            <Row>
                              <Button
                                handleEvent={{
                                  click: () =>
                                    WizardStore.select({
                                      id: 'updateAdminUser',
                                      store: { adminUserId: _id },
                                    }),
                                }}
                                config={{
                                  text: 'Modifier',
                                  color: 'warn',
                                  size: 'small',
                                }}
                              />
                            </Row>
                          ),
                        }),
                      ),
                      paging: adminUsers?.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  { title: 'Email', key: 'username' },
                  { title: 'Statut', key: 'state' },
                  { title: 'Action', key: 'action' },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
