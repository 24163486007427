import {
  FilterExpanded,
  Page,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Query, QueryTransactionsArgs } from '../../../../client/graphql';
import { transactionsGql } from '../gql/transactions.gql';

export const TransactionsSearch = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { data, loading, error, refetch } = useQuery<
    { transactions: Query['transactions'] },
    QueryTransactionsArgs
  >(transactionsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const transactions = useMemo(() => {
    if (!data) return null;

    return data.transactions;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des transactions' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions></FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={
                transactions
                  ? {
                      list: transactions?.list.map(
                        ({
                          amount,
                          currency,
                          contractTag,
                          psp,
                          pspTransactionDate,
                          reference,
                          ...rest
                        }) => {
                          let amountFormatted = undefined;

                          try {
                            amountFormatted = new Intl.NumberFormat(currency, {
                              style: 'currency',
                              currency: currency,
                            }).format(amount / 100);
                          } catch {}

                          return {
                            ...rest,
                            referenceLink: (
                              <Link
                                to={`/payments/transactions?reference=${reference}`}
                              >
                                {reference}
                              </Link>
                            ),
                            contract: `${contractTag} (${psp})`,
                            pspDate:
                              DateTime.fromISO(pspTransactionDate).toRelative(),
                            amount: amountFormatted,
                          };
                        },
                      ),
                      paging: transactions?.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'Consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'Reference',
                    key: 'referenceLink',
                  },
                  {
                    title: 'Contract',
                    key: 'contract',
                  },
                  {
                    title: 'Type',
                    key: 'type',
                  },
                  {
                    title: 'Amount',
                    key: 'amount',
                  },
                  {
                    title: 'Date',
                    key: 'pspDate',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
