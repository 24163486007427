import {
  Page,
  Widget,
  useQuery,
  Code,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryParkingRightArgs } from '../../../../client/graphql';
import { parkingRightGql } from '../gql/parkingRight.gql';

export const ParkingRightsDetails = () => {
  const { parkingRightId } = useParams();

  const { data, loading, error, refetch } = useQuery<
    { parkingRight: Query['parkingRight'] },
    QueryParkingRightArgs
  >(parkingRightGql, {
    variables: { parkingRightId: parkingRightId! },
  });

  const parkingRight = useMemo(() => {
    if (!data) return null;

    return data.parkingRight;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Details du ticket' }}
      >
        <Code
          data={{
            defaultValue: parkingRight
              ? JSON.stringify(parkingRight, null, 4)
              : undefined,
          }}
          config={{ yMax: '80vh', lang: 'json', readOnly: true }}
        />
      </Widget>
    </Page>
  );
};
