import { useTranslation } from '../../hook/useTranslation.hook';
import { Icon } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './hyperlink.component.scss';

class Static {
  public static registryHyperLink = {
    GOOGLE_AUTHENTIFICATOR: {
      link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=fr&gl=US',
      name: 'watermelon-googleAuthentificator-title',
      store: 'watermelon-googleAuthentificator-description',
      icon: 'partnerGoogleAuthentificator',
    },
  } as const;
}

export declare namespace LinkType {
  type Props = {
    className?: string;
    config: {
      type: keyof typeof Static.registryHyperLink;
    };
  };
}

export const HyperLink = ({
  config: { type },
  className = '',
}: LinkType.Props) => {
  const { t } = useTranslation();
  return (
    <a
      href={Static.registryHyperLink[type].link}
      target="__blank"
      className={`
        hyperlink
        ${className}
      `}
    >
      <Icon
        config={{ type: Static.registryHyperLink[type].icon, size: 'large' }}
      ></Icon>

      <div className="hyperlink__text">
        <Write
          data={{ item: t(Static.registryHyperLink[type].name) }}
          config={{ mode: 'namise' }}
        ></Write>
        <Write
          data={{ item: t(Static.registryHyperLink[type].store) }}
          config={{ mode: 'namise-description' }}
        ></Write>
      </div>
    </a>
  );
};
