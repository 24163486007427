import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { Zone } from '@gimlite/watermelon/components/zone/zone.component';
import { isValidEmail } from '@gimlite/watermelon/functions/validator.function';
import { CreatePmsTechnicalUserWizardContext } from '../createPmsTechnicalUser.wizard';
import { Row } from '@gimlite/watermelon';

export const CredentialsStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreatePmsTechnicalUserWizardContext>) => {
  return (
    <Form
      data={{ value: context }}
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'username',
              label: 'Email',
              rules: [{ required: true }, { validator: isValidEmail }],
            }}
          >
            <Input
              config={{
                placeholder: 'technical@user.ch',
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'password',
              label: 'Mot de passe',
              rules: [
                { required: true },
                {
                  validator: (rules, value) =>
                    new Promise((resolve, reject) => {
                      8 <= value.length
                        ? resolve(true)
                        : reject('Au moins 8 caractères');
                    }),
                },
              ],
            }}
          >
            <Input
              config={{
                placeholder: 'password',
                type: { name: 'password' },
                width: 'full',
                height: 'xlarge',
              }}
            />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
