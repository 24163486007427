import { useMutation, useRootStore, Wizard } from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Mutation, MutationCreateClientArgs } from '../../../client/graphql';
import { createClientGql } from './gql/createClient.gql';
import { ClientStep } from './steps/client.step';

export type CreateClientWizardContext = any;
export const CreateClientWizard = () => {
  const { WizardStore } = useRootStore();

  const [createClient, createClientState] = useMutation<
    Mutation['createClient'],
    MutationCreateClientArgs
  >(createClientGql);

  const state = useMemo(() => {
    if (createClientState.loading) return undefined;
    if (createClientState.error) return false;
    if (createClientState.data) return true;
  }, [createClientState]);

  return (
    <Wizard<CreateClientWizardContext>
      config={{
        title: 'Créer un client',
        withValidation: {
          state,
          success: 'Le client a été créé avec succès',
          error: 'Erreur lors de la création du client',
        },
      }}
      data={{
        defaultContext: undefined,
      }}
      handleEvent={{
        done: async (value: any) => {
          try {
            await createClient({ variables: { input: value } });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Client', component: ClientStep }} />
    </Wizard>
  );
};
