import {
  ApolloCache,
  ApolloError,
  DefaultContext,
  DocumentNode,
  FetchResult,
  MaybeMasked,
  MutationFunction,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  TypedDocumentNode,
  useMutation as useMutationApolloClient,
} from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRootStore } from './useRootStore.hook';
import { NotificationModeType } from '../store/notification.store';

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<
    NoInfer<TData>,
    NoInfer<TVariables>,
    TContext,
    TCache
  > & {
    notification?: {
      loading?: string;
      success?: string | ((data: TData) => string);
      error?: string | ((error: ApolloError) => string);
    };
    socket?: Record<string, (data: any) => boolean>;
  },
): [
  (
    options?: MutationFunctionOptions<TData, TVariables, TContext, TCache>,
  ) => Promise<FetchResult<MaybeMasked<TData>>>,
  MutationResult<TData> & {
    count: number;
    resetData: () => void;
  },
] {
  const [stateInterpreter, setStateInterpreter] = useState<{
    loading: boolean | null;
    data: TData | null;
    error: ApolloError | null;
  }>({
    loading: null,
    data: null,
    error: null,
  });

  const { NotificationStore, SocketStore } = useRootStore();
  const useMutationApolloClientResult = useMutationApolloClient<
    TData,
    TVariables,
    TContext,
    TCache
  >(mutation, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: (data) => {
      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: null,
      }));

      setStateInterpreter({
        data: data,
        loading: false,
        error: null,
      });
    },
    onError: (error) => {
      setNotificationKey((prev) => ({
        ...prev,
        ERROR: null,
      }));

      setStateInterpreter({
        data: null,
        loading: false,
        error: error,
      });
    },
    ...options,
  });

  const [call, state] = useMutationApolloClientResult;
  const callCountRef = useRef(0);

  const intermediateCall: MutationFunction<
    TData,
    TVariables,
    TContext,
    TCache
  > = useCallback(
    async (data) => {
      setNotificationKey((prev) => ({
        ...prev,
        LOADING: null,
      }));

      setStateInterpreter({
        data: null,
        loading: true,
        error: null,
      });
      callCountRef.current++;
      return call(data);
    },
    [call, setStateInterpreter],
  );

  const count = useMemo(() => callCountRef.current, [callCountRef.current]);
  const notification = options?.notification;
  const [notificationKey, setNotificationKey] = useState<
    Record<
      Extract<NotificationModeType, 'LOADING' | 'SUCCESS' | 'ERROR'>,
      string | null
    >
  >({
    LOADING: null,
    SUCCESS: null,
    ERROR: null,
  });

  useEffect(() => {
    if (!notification) return;

    if (
      notification?.loading &&
      stateInterpreter.loading &&
      !notificationKey.LOADING
    ) {
      const keyLoading = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'LOADING',
        content: notification.loading,
        duration: 100,
        key: keyLoading,
      });

      setNotificationKey((prev) => ({
        ...prev,
        LOADING: keyLoading,
      }));

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.success &&
      stateInterpreter.data &&
      !notificationKey.SUCCESS
    ) {
      const keySuccess = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'SUCCESS',
        content:
          typeof notification.success === 'function'
            ? notification.success(stateInterpreter.data)
            : notification.success,
        key: keySuccess,
      });

      setNotificationKey((prev) => ({
        ...prev,
        SUCCESS: keySuccess,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.ERROR) {
        NotificationStore.destroy(notificationKey.ERROR);

        setNotificationKey((prev) => ({
          ...prev,
          ERROR: null,
        }));
      }
    } else if (
      notification?.error &&
      stateInterpreter.error &&
      !notificationKey.ERROR
    ) {
      const keyError = crypto.randomUUID();

      NotificationStore.emit({
        mode: 'ERROR',
        content:
          typeof notification.error === 'function'
            ? notification.error(stateInterpreter.error)
            : notification.error,
        key: keyError,
      });

      setNotificationKey((prev) => ({
        ...prev,
        ERROR: keyError,
      }));

      if (notificationKey.LOADING) {
        NotificationStore.destroy(notificationKey.LOADING);

        setNotificationKey((prev) => ({
          ...prev,
          LOADING: null,
        }));
      }

      if (notificationKey.SUCCESS) {
        NotificationStore.destroy(notificationKey.SUCCESS);

        setNotificationKey((prev) => ({
          ...prev,
          SUCCESS: null,
        }));
      }
    }
  }, [notification, stateInterpreter, NotificationStore, notificationKey]);

  useEffect(() => {
    if (SocketStore.client && options?.socket) {
      Object.entries(options.socket).forEach(([key, value]) => {
        SocketStore.client!.on(key, (data) => {
          if (value(data)) {
            state.reset();
          }
        });
      });
    }

    return () => {
      if (SocketStore.client && options?.socket) {
        Object.entries(options.socket).forEach(([key, value]) => {
          SocketStore.client!.off(key, (data) => {
            if (value(data)) {
              state.reset();
            }
          });
        });
      }
    };
  }, [SocketStore.client?.connected, options?.socket]);

  return [
    intermediateCall,
    {
      ...state,
      count,
      resetData: () => state.reset(),
    },
  ];
}
