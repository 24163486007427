import {
  Badge,
  baseFormattedDate,
  Button,
  FilterExpanded,
  Form,
  Input,
  Page,
  Table,
  useMyUrl,
  useQuery,
  useTranslation,
  Widget,
  Zone,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Query, QueryFragmentsArgs } from '../../../../client/graphql';
import { fragmentsGql } from '../gql/fragments.gql';

export const FragmentSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl<{
    fragmentRef: string;
    terminalId: string;
  }>({
    paramsFormatted: {
      fragmentRef: 'string',
      terminalId: 'string',
    },
  });
  const navigate = useNavigate();
  const { lang } = useTranslation();
  const { data, loading, error, refetch } = useQuery<
    { fragments: Query['fragments'] },
    QueryFragmentsArgs
  >(fragmentsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const fragments = useMemo(() => {
    if (!data) return null;

    return data.fragments;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{
          error: error,
          loading: loading,
          refetch: refetch,
        }}
        config={{ title: 'Liste des fragments' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['search']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item config={{ name: 'state', label: 'Etat' }}>
                  <Input />
                </Form.Item>
                <Form.Item config={{ name: 'ftype', label: 'Type' }}>
                  <Input />
                </Form.Item>
                <Form.Item
                  config={{ name: 'terminalId', label: 'Terminal ID' }}
                >
                  <Input />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'search' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/adm/fragments/${value._id}`);
                },
              }}
              data={
                fragments
                  ? {
                      list: fragments.list.map(
                        ({ logDate, state, terminalId, ...rest }) => {
                          return {
                            ...rest,
                            terminalId: (
                              <Link
                                to={`/adm/fragments?terminalId=${terminalId}`}
                              >
                                {terminalId}
                              </Link>
                            ),
                            logDate: logDate
                              ? baseFormattedDate({
                                  date: logDate,
                                  lang,
                                })
                              : null,
                            state:
                              state !== 'PROCESSED' ? (
                                <Badge
                                  config={{ color: 'error', text: state }}
                                />
                              ) : (
                                <Badge
                                  config={{ color: 'success', text: state }}
                                />
                              ),
                          };
                        },
                      ),
                      paging: fragments.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'consumer',
                    key: 'consumer',
                  },
                  {
                    title: 'terminalId',
                    key: 'terminalId',
                  },
                  {
                    title: 'Ref',
                    key: 'fragmentRef',
                  },
                  {
                    title: 'type',
                    key: 'type',
                  },
                  {
                    title: 'logDate',
                    key: 'logDate',
                  },
                  {
                    title: 'state',
                    key: 'state',
                  },
                ],
                actions: { read: true },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
