import {
  Description,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QueryClientArgs } from '../../../../../client/graphql';
import { clientGql } from '../../gql/client.gql';

export const ClientsDetailsGlobal = () => {
  const { clientId } = useParams();
  const { getParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql, {
    variables: { ...getParamsUrl, clientId },
  });

  const client = useMemo(() => {
    if (!data) return null;

    return data.client;
  }, [data]);

  return (
    <Zone
      config={{
        zones: [['description']],
        rows: ['1fr'],
        columns: ['1fr'],
      }}
    >
      <Zone.Area config={{ area: 'description' }}>
        <Widget
          state={{ loading, error, refetch }}
          config={{ title: 'Détails du client' }}
        >
          <Description
            data={[
              {
                key: 'slug',
                label: 'Slug',
                value: client?.slug,
              },
            ]}
          ></Description>
        </Widget>
      </Zone.Area>
    </Zone>
  );
};
