import { ApolloError } from '@apollo/client';
import gsap from 'gsap';
import React, { useMemo, useRef } from 'react';
import type { Position } from '../../types/component.type';
import { Icon, IconType } from '../icon/icon.component';
import { Write } from '../write/write.component';
import './widget.component.scss';
import { useTranslation } from '../../hook/useTranslation.hook';
declare namespace WidgetHeaderType {
  type Props = {
    event?: {
      refetch?: () => void;
    };
    config?: {
      icon?: WidgetHeaderType.Config.Icon;
      title?: WidgetHeaderType.Config.Title;
      extra?: WidgetHeaderType.Config.Extra;
      subtitle?: WidgetHeaderType.Config.SubTitle;
      backtitle?: WidgetHeaderType.Config.BackTitle;
      actions?: WidgetHeaderType.Config.Action;
    };
  };
  namespace Config {
    type Icon = IconType.Config.Type;
    type Title = string;
    type SubTitle = string;
    type BackTitle = boolean;
    type Extra = React.ReactNode;
    type Action = React.ReactNode[];
  }
}

const ErrorRefetch = ({
  size = 'medium',
  refetch,
  backtitle,
}: {
  size?: IconType.Config.Size;
  refetch?: () => void;
  backtitle?: boolean;
}) => {
  const currentElement = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={currentElement}
      onClick={() => {
        refetch?.();

        gsap.fromTo(
          currentElement?.current,
          {
            rotation: '0deg',
          },
          {
            rotation: '720deg',
            duration: 0.5,
          },
        );
      }}
      className="errorRefetch"
    >
      <Icon
        config={{
          type: 'faRefreshSolid',
          size,
          color: backtitle ? 'primary-over' : 'primary',
        }}
      ></Icon>
    </div>
  );
};

const ErrorRequest = ({
  fetch,
  code,
  statusCode,
}: {
  fetch?: () => void;
  code?: string;
  statusCode?: number;
}) => {
  const { t } = useTranslation();
  const origin = useMemo(() => {
    return statusCode && statusCode >= 400 && statusCode < 500
      ? 'CLIENT'
      : 'SERVEUR';
  }, [statusCode]);

  console.log({ errorOrigin: origin });

  return (
    <div className="errorRequest">
      <div className="errorRequest__content">
        <div className="errorRequest__content__present">
          <Write
            config={{ mode: 'namise', color: 'primary' }}
            data={{
              item: t('watermelon-error-request-title'),
            }}
          />
        </div>

        <div className="errorRequest__content__text">
          <Write
            config={{
              mode: 'value-small',
              align: 'center',
              color: 'yang',
            }}
            data={{
              item: t('watermelon-error-request-message'),
            }}
          />
        </div>
        {/* <ErrorRefetch refetch={fetch} size="xlarge" /> */}
      </div>
    </div>
  );
};

const WidgetHeader = ({
  event,
  config: { icon, title, extra, subtitle, actions, backtitle } = {},
}: WidgetHeaderType.Props) => {
  return (
    <div className={`wid-header ${backtitle ? 'wid-header-background' : ''}`}>
      {icon && (
        <Icon
          className="wid-header__icon"
          config={{
            type: icon,
            size: 'xmedium',
            color: backtitle ? 'primary-over' : undefined,
          }}
        ></Icon>
      )}
      <div className="wid-header__info">
        {title && (
          <div className="wid-header__info__text">
            <div className="wid-header__info__text__title">
              <Write
                data={{ item: title }}
                config={{
                  mode: 'title-small',
                  color: backtitle ? 'primary-over' : undefined,
                }}
              />

              {event?.refetch && (
                <ErrorRefetch
                  backtitle={backtitle}
                  refetch={event.refetch}
                  size="medium"
                />
              )}
            </div>
            {subtitle && (
              <div className={`wid-header__info__text__subtitle`}>
                <Write
                  data={{ item: subtitle }}
                  config={{
                    mode: 'title-small-description',
                    color: backtitle ? 'primary-over' : undefined,
                  }}
                />
              </div>
            )}
          </div>
        )}
        <div className="wid-header__info__action">
          {actions?.map((element) => (
            <div className="wid-header__info__action__item">{element}</div>
          ))}
        </div>
      </div>
      {extra && <div className="wid-header__extra">{extra}</div>}
    </div>
  );
};

export declare namespace WidgetType {
  type Props = {
    className?: string;
    children: React.ReactNode;
    state?: {
      loading?: boolean;
      error?: ApolloError;
      refetch?: () => void;
      showPermanentRefetch?: boolean;
    };
    config?: {
      shadow?: boolean;
      position?: Extract<
        Position,
        'sticky' | 'absolute' | 'relative' | 'static'
      >;
      full?: boolean;
      action?: WidgetType.Config.Action;
      yMax?: string | number;
      responsive?: {
        padding?: boolean;
      };
      padding?: boolean;
    } & WidgetHeaderType.Props['config'];
  };

  namespace Config {
    type Action = React.ReactNode[];
  }
}

export const Widget = ({
  children,
  state,
  config: {
    shadow = true,
    title,
    subtitle,
    extra,
    position,
    full,
    icon,
    action,
    padding = true,
    backtitle = false,
    yMax,
    responsive: { padding: responsivePadding = true } = {},
  } = {},
  className = '',
}: WidgetType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  const retryFetching = useMemo(() => {
    return !state?.loading && state?.error;
  }, [state]);

  return (
    <div
      className={`
        widget
        ${retryFetching ? 'widget--error' : ''}
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
        position,
        top: position ? '0px' : 'initial',
        zIndex: position ? '1000' : 'initial',
        height: yMax,
        padding: full ? '0px' : undefined,
        boxShadow: !shadow ? 'none' : undefined,
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            actions: action
              ? Array.isArray(action)
                ? action
                : [action]
              : undefined,
            backtitle,
          }}
          event={{ refetch: state?.refetch }}
        ></WidgetHeader>
      )}
      <div
        className={`widget__contain 
        ${!responsivePadding ? 'widget-responsive-nopadding' : ''}
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {retryFetching ? (
          <ErrorRequest
            code={state?.error?.message}
            statusCode={
              (state?.error?.cause as { statusCode?: number })?.statusCode
            }
            fetch={() => state?.refetch?.()}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export declare namespace WidgetGroupType {
  type Props = {
    className?: string;
    state?: {
      loading?: boolean;
      error?: ApolloError;
      refetch?: () => void;
      showPermanentRefetch?: boolean;
    };
    children: React.ReactNode;
    config?: {
      padding?: boolean;
      responsive?: {
        padding?: boolean;
      };
    } & WidgetHeaderType.Props['config'];
  };
}

Widget.Group = ({
  className = '',
  state,
  config: {
    title,
    extra,
    icon,
    subtitle,
    backtitle = false,
    padding = true,
    responsive: { padding: responsivePadding = true } = {},
  } = {},
  children,
}: WidgetGroupType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  const retryFetching = useMemo(() => {
    return !state?.loading && state?.error;
  }, [state]);

  return (
    <div
      className={`
        widgetgroup
        ${retryFetching ? 'widgetgroup--error' : ''}
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            backtitle,
          }}
          event={{ refetch: state?.refetch }}
        ></WidgetHeader>
      )}
      <div
        className={`widgetgroup__contain 
        ${!responsivePadding ? 'widget-responsive-nopadding' : ''}
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {retryFetching ? (
          <ErrorRequest
            code={state?.error?.message}
            statusCode={
              (state?.error?.cause as { statusCode?: number })?.statusCode
            }
            fetch={() => state?.refetch?.()}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export const WidgetGroup = ({
  className = '',
  config: {
    title,
    extra,
    icon,
    subtitle,
    backtitle = false,
    padding = true,
  } = {},
  children,
}: WidgetGroupType.Props) => {
  const activeHeader = icon || extra || subtitle || title;

  return (
    <div
      className={`
        widgetgroup
        ${className}
      `}
      style={{
        gridTemplateRows: activeHeader ? 'min-content 1fr' : '1fr',
      }}
    >
      {activeHeader && (
        <WidgetHeader
          config={{
            icon,
            extra,
            subtitle,
            title,
            backtitle,
          }}
        ></WidgetHeader>
      )}
      <div
        className={`widgetgroup__contain 
        ${padding ? 'widget-padding' : ''}
        ${!activeHeader ? 'widget-without-title' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};
