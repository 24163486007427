import { AutoComplete, Row, Space, useLazyQuery } from '@gimlite/watermelon';
import { Form } from '@gimlite/watermelon/components/form/form.component';
import { Input } from '@gimlite/watermelon/components/input/input.component';
import { WizardItemType } from '@gimlite/watermelon/components/wizard/wizard.component';
import { useEffect } from 'react';
import {
  Query,
  QueryClientArgs,
  QueryClientsArgs,
  QueryUpsArgs,
  QueryUpsListArgs,
} from '../../../../client/graphql';
import { CreateSiteWizardContext } from '../createSite.wizard';
import { clientGql } from '../gql/client.gql';
import { clientsGql } from '../gql/clients.gql';
import { upsGql } from '../gql/ups.gql';
import { upsListGql } from '../gql/upsList.gql';

export const SiteStep = ({
  context,
  validate,
  submit,
}: WizardItemType.Config.Component<CreateSiteWizardContext>) => {
  const [clients, clientsState] = useLazyQuery<
    { clients: Query['clients'] },
    QueryClientsArgs
  >(clientsGql, {
    notification: {
      error: 'Erreur lors de la recherche des clients',
    },
  });

  const [client, clientState] = useLazyQuery<
    { client: Query['client'] },
    QueryClientArgs
  >(clientGql, {
    notification: {
      error: 'Erreur lors de la lecture du client',
    },
  });

  const [upsList, upsListState] = useLazyQuery<
    { upsList: Query['upsList'] },
    QueryUpsListArgs
  >(upsListGql, {
    notification: {
      error: 'Erreur lors de la recherche des parkings',
    },
  });

  const [ups, upsState] = useLazyQuery<{ ups: Query['ups'] }, QueryUpsArgs>(
    upsGql,
    {
      notification: {
        error: 'Erreur lors de la lecture du parking',
      },
    },
  );

  useEffect(() => {
    if (upsListState.data) {
      submit({
        listParking: upsListState.data.upsList.list,
      });
    }
  }, [upsListState.data]);

  useEffect(() => {
    if (upsState.data) {
      submit({
        selectParking: { _id: upsState.data.ups._id },
      });
    }
  }, [upsState.data]);

  useEffect(() => {
    if (clientsState.data) {
      submit({
        listClient: clientsState.data.clients.list,
      });
    }
  }, [clientsState.data]);

  useEffect(() => {
    if (clientState.data) {
      submit({
        selectClient: { _id: clientState.data.client._id },
      });
    }
  }, [clientState.data]);

  return (
    <Form
      config={{ validateOnChange: true }}
      handleEvent={{
        change: (value: any) => {
          submit(value);
        },
        validate: (value: any) => {
          validate(value);
        },
      }}
    >
      <Form.Structure config={{ layout: 'column', width: 'full', fill: true }}>
        <Row config={{ gap: 20 }}>
          <Form.Item
            config={{
              way: 'vertical',
              name: 'clientId',
              label: 'Client',
              rules: [{ required: true }],
            }}
          >
            <AutoComplete
              handleEvent={{
                input: (value) => {
                  clients({
                    variables: {
                      slug: value,
                    },
                  });
                },
                select(item) {
                  if (item?.value) {
                    client({
                      variables: {
                        clientId: item.value,
                      },
                    });
                  }
                },
              }}
              data={{
                items: context?.listClient?.map(({ slug, _id }: any) => ({
                  value: _id,
                  label: slug,
                })),
              }}
              config={{
                search: {
                  caseSensitive: false,
                  accentSensitive: false,
                  pattern: 'start',
                },
                placeholder: 'plan-les-ouates',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>

          <Form.Item
            config={{
              way: 'vertical',
              name: 'upsId',
              label: 'UPS',
              rules: [{ required: true }],
            }}
          >
            <AutoComplete
              handleEvent={{
                input: (value) => {
                  upsList({
                    variables: {
                      codePattern: value,
                      clientId: context?.selectClient?._id,
                    },
                  });
                },
                select(item) {
                  if (item?.value) {
                    ups({
                      variables: {
                        upsId: item.value,
                      },
                    });
                  }
                },
              }}
              data={{
                items: context?.listParking?.map(({ code, _id }: any) => ({
                  value: _id,
                  label: code,
                })),
              }}
              config={{
                search: {
                  caseSensitive: false,
                  accentSensitive: false,
                  pattern: 'start',
                },
                placeholder: 'plan-les-ouates-a1',
                height: 'xlarge',
                width: 'full',
              }}
            />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            data={{
              defaultValue: context.name ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'name',
              label: 'Nom',
              rules: [{ required: true }],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.terminalId ?? '',
            }}
            config={{
              way: 'vertical',
              rules: [],
              name: 'terminalId',
              label: 'Terminal ID',
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            data={{
              defaultValue: context.address1 ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'address1',
              label: 'Adresse (1/2)',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.address2 ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'address2',
              label: 'Adresse (2/2)',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.zipcode ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'zipcode',
              label: 'Code postal',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.city ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'city',
              label: 'Ville',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.country ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'country',
              label: 'Pays',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>
        </Row>
        <Row config={{ gap: 20 }}>
          <Form.Item
            data={{
              defaultValue: context.latitude ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'latitude',
              label: 'Latitude',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>

          <Form.Item
            data={{
              defaultValue: context.longitude ?? '',
            }}
            config={{
              way: 'vertical',
              name: 'longitude',
              label: 'Longitude',
              rules: [],
            }}
          >
            <Input config={{ height: 'xlarge', width: 'full' }} />
          </Form.Item>
        </Row>
      </Form.Structure>
    </Form>
  );
};
