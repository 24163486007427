import {
  FilterExpanded,
  Popover,
  Row,
  Space,
  useTranslation,
  Page,
  Widget,
  Zone,
  Button,
  Table,
  useMutation,
  useQuery,
  useMyUrl,
  useRootStore,
} from '@gimlite/watermelon';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Mutation,
  Query,
  QueryControlUsersArgs,
} from '../../../../client/graphql';
import { controlUsersGql } from '../../../wizards/createPrestoScan/gql/control-users.gql';
import {
  DeleteControlUsersParams,
  deleteControlUsersGql,
} from '../gql/deleteControlUser.gql';

export const ControlUsersSearch = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { WizardStore } = useRootStore();
  const { data, loading, error, refetch } = useQuery<
    { controlUsers: Query['controlUsers'] },
    QueryControlUsersArgs
  >(controlUsersGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const [deleteControlUsers, deleteControlUsersState] = useMutation<
    { deleteControlUsers: Mutation['deleteControlUsers'] },
    DeleteControlUsersParams
  >(deleteControlUsersGql, {
    notification: {
      loading: "Suppression de l'agent en cours ...",
      success: "L'agent a été supprimé avec succès",
      error: "Erreur lors de la suppression de l'agent",
    },
  });

  const controlUsers = useMemo(() => {
    if (!data) return null;

    return data.controlUsers;
  }, [data]);

  useEffect(() => {
    if (deleteControlUsersState.data) {
      refetch();
    }
  }, [deleteControlUsersState?.data, refetch]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Listes des agents' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields></FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Créer un agent', type: { value: 'button' } }}
                  handleEvent={{
                    click: () =>
                      WizardStore.select({
                        id: 'createControlUser',
                      }),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value: any) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
                read: (value: any) => {
                  navigate(`/prestoscan/users/${value._id}`);
                },
              }}
              data={
                controlUsers
                  ? {
                      list: controlUsers.list.map((element: any) => ({
                        ...element,
                        upsLength: element.ups.length,
                        action: (
                          <Row>
                            <Button
                              handleEvent={{
                                click: () => {
                                  WizardStore.select({
                                    id: 'updateControlUser',
                                    store: {
                                      user: {
                                        firstName: element.firstName,
                                        lastName: element.lastName,
                                        fpsAgentId: element.fpsAgentId,
                                        fpsAgentName: element.fpsAgentName,
                                        lang: element.lang,
                                        ups: element.ups,
                                        _id: element._id,
                                      },
                                      credentials: {
                                        username: element.username,
                                        password: null,
                                      },
                                    },
                                  });
                                },
                              }}
                              config={{
                                text: 'Modifier',
                                color: 'warn',
                                size: 'small',
                                stopPropagation: true,
                              }}
                            ></Button>
                            <Space config={{ way: 'horizontal' }}></Space>

                            <Popover.Confirm
                              handleEvent={{
                                confirm: () => {
                                  deleteControlUsers({
                                    variables: {
                                      input: { userId: element._id },
                                    },
                                  });
                                },
                              }}
                              config={{
                                title: t('watermelon-importantAction'),
                                description: t(
                                  'watermelon-areYouSureAboutThis?',
                                ),
                              }}
                            >
                              <Button
                                config={{
                                  text: 'Supprimer',
                                  color: 'error',
                                  size: 'small',
                                }}
                              ></Button>
                            </Popover.Confirm>
                          </Row>
                        ),
                      })),
                      paging: controlUsers.paging,
                    }
                  : undefined
              }
              config={{
                columns: [
                  {
                    title: 'username',
                    key: 'username',
                  },
                  {
                    title: 'FirstName',
                    key: 'firstName',
                  },
                  {
                    title: 'LastName',
                    key: 'lastName',
                  },
                  {
                    title: 'Fps AgentId',
                    key: 'fpsAgentId',
                  },
                  {
                    title: 'Fps AgentName',
                    key: 'fpsAgentName',
                  },
                  {
                    title: "Nombre d'UPS",
                    key: 'upsLength',
                  },
                  {
                    key: 'action',
                    title: 'Actions',
                  },
                ],
                actions: {
                  read: true,
                },
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
