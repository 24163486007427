export default () => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 42.3 42.3"
  >
    <g>
      <g>
        <path
          className="primary"
          d="M33.2,39.4h-8.9v-4.5h8.9c1,0,1.7-0.8,1.7-1.7v-6.3h4.5v6.3C39.4,36.6,36.6,39.4,33.2,39.4z"
        />
        <path
          className="primary"
          d="M7.4,15.4H2.9V9.1c0-3.5,2.8-6.3,6.3-6.3h8.9v4.5H9.1c-1,0-1.7,0.8-1.7,1.7V15.4z"
        />
        <path
          className="yang"
          d="M33.2,23.4c3.5,0,6.3-2.8,6.3-6.3v-8c0-3.5-2.8-6.3-6.3-6.3h-8.9v4.5h8.9c1,0,1.7,0.8,1.7,1.7v8
			c0,1-0.8,1.7-1.7,1.7h-24c-3.5,0-6.3,2.8-6.3,6.3l0,8c0,3.5,2.8,6.3,6.3,6.3h8.9V25.2c0-1,0.8-1.7,1.7-1.7H33.2z M13.5,25.2v9.8
          H9.1c-1,0-1.7-0.8-1.7-1.7v-1.7h0v-6.3c0-1,0.8-1.7,1.7-1.7h4.7C13.6,24,13.5,24.6,13.5,25.2z"
        />
      </g>
    </g>
  </svg>
);
