import { Button, Col, Empty, Line, Row, Space } from '@gimlite/watermelon';
import { SkeletonBlock } from '@gimlite/watermelon/components/skeleton/skeleton.component';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';

export declare namespace TrunkAmountChartType {
  type Props = {
    data: Array<TrunkAmountChartType.Point>;
    config?: {
      startDate?: Date;
      endDate?: Date;
    };
  };

  type Point = {
    date: Date;
    total: number | null;
    TOK: number | null;
    EUR: number | null;
    CHF: number | null;
  };
}

const empty: Omit<TrunkAmountChartType.Point, 'date'> = {
  total: null,
  TOK: null,
  EUR: null,
  CHF: null,
};

export const TrunkAmountChart = ({
  data,
  config,
}: TrunkAmountChartType.Props) => {
  const [pointRadius, setPointRadius] = useState(0);

  const dataFormatted = useMemo(() => {
    if (!data) return;

    const dataTransform = data.map(({ date, ...rest }) => ({
      ...rest,
      date: date.getTime(),
    }));

    if (config?.startDate) {
      const findSameDateStart = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.startDate as Date).getTime(),
      );

      if (!findSameDateStart) {
        dataTransform.unshift({
          date: new Date(config?.startDate).getTime(),
          ...empty,
        });
      }
    }

    if (config?.endDate) {
      const findSameDateEnd = data.find(
        ({ date }) =>
          new Date(date).getTime() ===
          new Date(config.endDate as Date).getTime(),
      );

      if (!findSameDateEnd) {
        dataTransform.push({
          date: new Date(config?.endDate).getTime(),
          ...empty,
        });
      }
    }

    return dataTransform.sort((a, b) => a.date - b.date);
  }, [data]);

  if (!data || !dataFormatted) {
    return <SkeletonBlock />;
  }

  if (data.length <= 1)
    return (
      <Empty
        config={{
          mode: {
            name: 'noData',
          },
        }}
      ></Empty>
    );

  return (
    <div style={{ width: '100%' }}>
      <Col>
        <Row>
          <Button
            handleEvent={{ click: () => setPointRadius(2) }}
            config={{
              text: 'Avec point',
              color: pointRadius ? 'primary' : 'yang',
              size: 'small',
            }}
          ></Button>
          <Space config={{ way: 'horizontal' }} />
          <Button
            handleEvent={{ click: () => setPointRadius(0) }}
            config={{
              text: 'Sans point',
              color: !pointRadius ? 'primary' : 'yang',
              size: 'small',
            }}
          ></Button>
        </Row>
        <Line
          style={{
            maxHeight: '65vh',
            width: '100%',
          }}
          options={{
            plugins: {
              zoom: {
                zoom: {
                  wheel: {
                    enabled: true,
                    modifierKey: 'ctrl',
                  },
                  drag: {
                    enabled: true,
                    modifierKey: 'ctrl',
                  },
                  mode: 'x',
                  scaleMode: 'y',
                },
                limits: {
                  x: {
                    max: dataFormatted[dataFormatted.length - 1].date,
                    min: dataFormatted[0].date,
                  },
                },
              },
              tooltip: {
                intersect: false,
                mode: 'index',
                callbacks: {
                  label: (context) => {
                    const value = `${context.formattedValue}`.replaceAll(
                      ',',
                      '.',
                    );

                    return `${context.dataset.label}: ${value}`;
                  },
                },
                position: 'nearest',
              },
            },
            scales: {
              x: {
                type: 'time',
                ticks: {
                  callback: (value, index, ticks) =>
                    DateTime.fromJSDate(new Date(value)).toFormat(
                      'dd/MM/yy HH:mm',
                    ),
                },
                time: {
                  tooltipFormat: 'DD/MM/YY HH:mm:ss',
                },
              },
              amount: {
                ticks: {
                  callback: (value, index, ticks) => {
                    return `${value}`;
                  },
                },
                min: 0,
                position: 'left',
              },
            },
          }}
          data={{
            labels: dataFormatted.map(({ date }) => date),
            datasets: [
              {
                yAxisID: 'amount',
                label: 'Total',
                data: dataFormatted.map(({ total }) => total),
                borderWidth: 1,
                order: 0,
                pointRadius,
              },
              {
                yAxisID: 'amount',
                label: 'TOK',
                data: dataFormatted.map(({ TOK }) => TOK),
                borderWidth: 1,
                order: 1,
                pointRadius,
              },
              {
                yAxisID: 'amount',
                label: 'EUR',
                data: dataFormatted.map(({ EUR }) => EUR),
                borderWidth: 1,
                order: 2,
                pointRadius,
              },
              {
                yAxisID: 'amount',
                label: 'CHF',
                data: dataFormatted.map(({ CHF }) => CHF),
                borderWidth: 1,
                order: 3,
                pointRadius,
              },
            ],
          }}
        ></Line>
      </Col>
    </div>
  );
};
