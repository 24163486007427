import { Badge } from '@gimlite/watermelon/components/badge/badge.component';
import { TranslationEntity } from '../../../../client/graphql';

type ColumnTable = {
  _id: string;
  state: React.ReactNode;
  translationKey: string;
  translationValue: unknown;
};

export const translationsRender = (dictionary: TranslationEntity) => {
  const documents = dictionary?.documents ?? [];

  const allKeys = documents.reduce((acc: { [key: string]: unknown }, item) => {
    return { ...acc, ...item?.translations };
  }, {});

  const difference = (
    keys: string[],
    translations: { [key: string]: unknown },
  ): string[] => {
    const translateKey = Object.keys(translations);
    const results = keys.filter(
      (x: string) => !translateKey.includes(x) || !translations[x],
    );
    return results;
  };

  const toColumnTable = (object: { [key: string]: unknown }) => {
    return Object.entries(object).map(([key, value]) => {
      const base = {
        _id: key,
        state: !value ? (
          <Badge config={{ color: 'error', text: 'MISSING' }} />
        ) : (
          <Badge config={{ color: 'success', text: 'CORRECT' }} />
        ),
        translationKey: key,
        translationValue: value,
      };
      return base;
    });
  };

  const langs = documents.reduce(
    (
      acc: Array<{
        label?: string | null;
        key?: string | null;
        list: Array<ColumnTable>;
      }>,
      item,
    ) => {
      const emptyKeys = difference(Object.keys(allKeys), item?.translations);

      const emptyTranslations = emptyKeys.reduce(
        (acc: { [key: string]: null }, cur: string) => ({
          ...acc,
          [cur]: null,
        }),
        {},
      );

      const otherTranslations = Object.entries(item?.translations)
        .filter(([key, value]): boolean => !emptyKeys.includes(key))
        .reduce(
          (
            acc: { [key: string]: unknown },
            [key, value],
          ): { [key: string]: unknown } => {
            acc[key] = value;
            return acc;
          },
          {},
        );

      return [
        ...acc,
        {
          label: item?.lang,
          key: item?.lang,
          list: [
            ...toColumnTable(emptyTranslations),
            ...toColumnTable(otherTranslations),
          ],
        },
      ];
    },
    [],
  );

  return langs;
};
