import { gql } from '@apollo/client';

export const upsListGql = gql`
  query upsList($page: Int, $limit: Int, $order: String, $codePattern: String) {
    upsList(
      page: $page
      limit: $limit
      order: $order
      codePattern: $codePattern
    ) {
      list {
        _id
        code
        center
        translation {
          lang
          name
          description
        }
        address {
          streetNumber
          streetNumberBis
          streetType
          streetName
          specialPlace
          postalCode
          addressLocality
          addressSubRegion
          addressCountry
          postOfficeBoxNumber
        }
        config {
          fps {
            maximumDuration
            maximumAmount
            system
            notificationAuthority
            reducedPriceDuration
            finePrice
            reducedFinePrice
            recourseOrganization {
              address {
                streetNumber
                streetNumberBis
                streetType
                streetName
                specialPlace
                postalCode
                addressLocality
                addressSubRegion
                addressCountry
                postOfficeBoxNumber
              }
              name
              organizationId
              url
            }
          }
          prestoScan {
            entryTolerancePeriod
            exitTolerancePeriod
            isVerbalizationAllowed
            showOccupancy
            deletionDelay {
              incomplete
              complete
              toCheck
              unfined
              fined
            }
            outrageTreshold {
              danger
              warning
              valid
            }
          }
        }
      }
      paging {
        current
        count
        limit
      }
    }
  }
`;
