import { useMemo } from 'react';
import { getCSS } from '../../functions/css.function';
import type { Way } from '../../types/component.type';
import './space.component.scss';

export declare namespace SpaceType {
  type Props = {
    className?: string;
    config?: {
      count?: number;
      way?: Extract<Way, 'vertical' | 'horizontal'>;
    };
  };
}

export const Space = ({
  config: { way = 'vertical', count = 1 } = {},
  className = '',
}: SpaceType.Props) => {
  const total = useMemo(() => {
    const space = getCSS('--space');
    return `calc(${count} * ${space})`;
  }, [count]);

  return (
    <div
      className={`
        space 
        space--${way} 
        ${className}`}
      style={
        way === 'horizontal'
          ? {
              width: total,
              minWidth: total,
              maxWidth: total,
            }
          : {
              height: total,
              minHeight: total,
              maxHeight: total,
            }
      }
    ></div>
  );
};
