import { gql } from '@apollo/client';

export const updateControlUsersGql = gql`
  mutation updateControlUsers($input: UpdateControlUserDto!) {
    updateControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
