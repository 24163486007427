import {
  Button,
  FilterExpanded,
  Form,
  Input,
  Page,
  Select,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Query, QueryMonitoringArgs } from '../../../../client/graphql';
import { monitoringQuery } from '../gql/monitoring.gql';

export const msTypes = new Map([
  ['microservice', 'MS'],
  ['bff', 'BFF'],
  ['api', 'API'],
  ['other', 'Other'],
]);

const typesOptions = [
  { label: 'API', value: 'api' },
  { label: 'MS', value: 'microservice' },
  { label: 'BFF', value: 'bff' },
];

const statusOptions = [
  { label: 'UP', value: 'up' },
  { label: 'DOWN', value: 'down' },
  { label: 'NOT-RESPONDING', value: 'not-responding' },
];

export const Monitoring = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { monitoring: Query['monitoring'] },
    QueryMonitoringArgs
  >(monitoringQuery, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const monitoring = useMemo(() => {
    if (!data) return null;

    return data?.monitoring;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'État des microservices' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['table']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'msType',
                    label: 'Type',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.msType,
                      items: typesOptions,
                    }}
                    config={{
                      mode: 'single',
                      width: 'small',
                    }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'msName',
                    label: 'Name',
                  }}
                >
                  <Input
                    data={{ defaultValue: getParamsUrl?.msName }}
                    config={{ width: 'medium' }}
                  />
                </Form.Item>

                <Form.Item
                  config={{
                    name: 'msStatus',
                    label: 'Status',
                  }}
                >
                  <Select
                    data={{
                      defaultValue: getParamsUrl?.status,
                      items: statusOptions,
                    }}
                    config={{
                      mode: 'single',
                      width: 'medium',
                    }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>

              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>

          <Zone.Area config={{ area: 'table' }}>
            <Table<any>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={
                monitoring
                  ? {
                      list: monitoring?.list?.map(
                        ({ type, key, status, lastPing }, index) => ({
                          msType: msTypes.get(type),
                          msName:
                            `${msTypes.get(type) ? `[${msTypes.get(type)}]` : '[]'} ${key}`.trim(),
                          msStatus: status.toUpperCase(),
                          msLastPing: DateTime.fromISO(
                            lastPing.toString(),
                          ).toRelative(),
                          _id: `${index}`,
                        }),
                      ),
                      paging: monitoring?.paging,
                    }
                  : undefined
              }
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'Status',
                    key: 'msStatus',
                  },
                  {
                    title: 'Name',
                    key: 'msName',
                  },
                  {
                    title: 'Last ping',
                    key: 'msLastPing',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
