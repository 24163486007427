import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { toJS } from 'mobx';
import { RootStore } from '../store/root.store';
import { toCapitalizeCase } from './string.function';
import { GQLContextType, GQLType } from '../store/gql.store';

type ObjectGQL = string | ObjectGQL[];

const searchFieldsOfType = (typeName: string, types: GQLType.Type[]) => {
  const type: GQLType.Type | undefined = Object.values(types).find(
    ({ kind, name }: any) => kind === 'OBJECT' && name === typeName,
  );

  return type?.fields;
};

const detailsOfType = (
  ofType: GQLType.OfType | undefined,
): {
  name: string;
  write: string | undefined;
} => {
  const res = {
    name: searchTypeNameOfType(ofType),
    write: searchKindsOfType(ofType),
  };

  return res;
};

const searchKindsOfType = (
  ofType: GQLType.OfType | undefined,
): string | undefined => {
  if (!ofType) {
    return undefined;
  } else if (ofType?.kind === 'NON_NULL') {
    return searchKindsOfType(ofType?.ofType) + '!';
  } else if (ofType?.kind === 'LIST') {
    return '[' + searchKindsOfType(ofType?.ofType) + ']';
  } else if (ofType.kind === 'SCALAR') {
    return ofType?.name;
  } else {
    return '';
  }
};

const searchTypeNameOfType = (ofType: GQLType.OfType | undefined): string =>
  ofType?.name || searchTypeNameOfType(ofType?.ofType);

const buildGQL = (
  fields: GQLType.Field[],
  types: GQLType.Type[],
  operationName: string,
  operationType: string,
  args: GQLType.Arg[],
): DocumentNode => {
  const buildSchema = (
    fieldsParams: GQLType.Field[],
    typeParams: GQLType.Type[],
  ): ObjectGQL[] =>
    fieldsParams.flatMap(
      ({ name: fieldName, type: fieldType }: GQLType.Field): any => {
        const typeName = searchTypeNameOfType(fieldType);

        const fieldsOfTypeName = typeName
          ? searchFieldsOfType(typeName, typeParams)
          : [];

        return typeName && fieldsOfTypeName
          ? [fieldName, buildSchema(fieldsOfTypeName, typeParams)]
          : [fieldName];
      },
    );

  const buildArgs = (
    argsParams: GQLType.Arg[],
    mode: 'DEFINE' | 'PASS',
  ): string =>
    argsParams.length > 0
      ? `(${argsParams.reduce(
          (acc, { name, type }, index) =>
            `${acc} ${index !== 0 ? ',' : ''} ${
              mode === 'DEFINE' ? '$' + name : name
            }: ${mode === 'PASS' ? '$' + name : detailsOfType(type)?.write}`,
          '',
        )})`
      : '';

  const formatForGQL = (array: ObjectGQL[]): string =>
    `{${array.reduce(
      (acc: unknown, value: ObjectGQL) =>
        `${acc} \n ${Array.isArray(value) ? formatForGQL(value) : value}`,
      '',
    )}}`;

  const setOperation = (request: string) =>
    `${operationType.toLowerCase()} ${operationName.toLowerCase()}${buildArgs(
      args,
      'DEFINE',
    )} {\n${operationName.toLowerCase()}${buildArgs(args, 'PASS')} ${request}}`;

  return gql`
    ${setOperation(formatForGQL(buildSchema(fields, types)))}
  `;
};

export const buildFullSchemaGQL = ({
  operationName,
  operationType,
  introspection,
}: {
  operationName: string;
  operationType: 'QUERY' | 'MUTATION';
  introspection: GQLContextType['introspection'];
}) => {
  if (!introspection) throw new Error('Missing introspection');

  const types: GQLType.Type[] = introspection?.types;

  const query = searchFieldsOfType(toCapitalizeCase(operationType), types);

  const fieldOfResolver =
    (query?.find(
      ({ name }: GQLType.Field) => name === operationName,
    ) as GQLType.Field & { args: GQLType.Arg[] }) || undefined;

  const fieldOfResolverArgs = fieldOfResolver?.args;

  const entityOfResolver = searchTypeNameOfType(fieldOfResolver?.type?.ofType);

  const fieldsOfEntityOfResolver = entityOfResolver
    ? searchFieldsOfType(entityOfResolver, types)
    : undefined;

  if (!fieldsOfEntityOfResolver) throw new Error('Not Exist');

  return buildGQL(
    fieldsOfEntityOfResolver,
    types,
    operationName,
    operationType,
    fieldOfResolverArgs,
  );
};
