import HideImageIcon from '@mui/icons-material/HideImage';
import { Image as ImageAnt } from 'antd';
import React, { useMemo } from 'react';
import type { Size } from '../../types/component.type';
import { Space } from '../space/space.component';
import './image.component.scss';

export declare namespace ImageType {
  type Props = {
    className?: string;
    data?: {
      src?: ImageType.Data.Src | null;
    };
    config?: {
      aspectRatio?: [number, number] | number;
      preview?: boolean;
      size?: Extract<
        Size,
        'cell' | 'xsmall' | 'small' | 'medium' | 'large' | 'full'
      >;
      empty?: {
        icon?: React.ReactNode;
        text?: string;
      };
      color?: string;
    };
  };

  namespace Data {
    type Src = string;
  }
}

export const Image = ({
  data: { src } = {},
  config: {
    aspectRatio,
    preview = false,
    size = 'small',
    empty = {},
    color = 'black',
  } = {},
  className,
}: ImageType.Props) => {
  const style = useMemo((): React.CSSProperties => {
    const styleFormatted: React.CSSProperties = {};

    if (aspectRatio) {
      if (Array.isArray(aspectRatio) && aspectRatio.length === 2) {
        styleFormatted.aspectRatio = `${aspectRatio[0]} / ${aspectRatio[1]}`;
      } else if (typeof aspectRatio === 'number') {
        styleFormatted.aspectRatio = `${aspectRatio}`;
      }
    }

    return styleFormatted;
  }, [aspectRatio]);

  return (
    <div style={style} className={`image image--${size}`}>
      {src ? (
        <>
          <div
            style={{ backgroundColor: color }}
            className="image--backgroud"
          ></div>
          <ImageAnt
            src={src}
            preview={preview}
            className={`image__item image__item--${size} ${className ?? ''}`}
          ></ImageAnt>
        </>
      ) : !src && Object.keys(empty).length > 0 ? (
        <div className="image__empty">
          <div className="image__empty__icon">{empty?.icon}</div>
          {empty?.icon && empty?.text && <Space />}
          <span className="image__empty__text">{empty?.text}</span>
        </div>
      ) : !src && Object.keys(empty).length === 0 ? (
        <div className="image__empty">
          <div className="image__empty__icon">
            <HideImageIcon /> {/* //! FONT AWESOME PRO -- image-slash */}
          </div>
        </div>
      ) : null}
    </div>
  );
};
