import {
  FetchResult,
  SelectType,
  toStringIfValue,
  useCascade,
  UseCascadeResult,
  useRootStore,
  Wizard,
  WizardType,
} from '@gimlite/watermelon';
import { useEffect, useMemo, useState } from 'react';
import {
  CameraPositionEnum,
  ClientEntity,
  ControlUserEntity,
  Mutation,
  MutationCreateCameraArgs,
  MutationCreateClientArgs,
  MutationCreateControlUsersArgs,
  MutationCreateUpsArgs,
  MutationDeleteCameraArgs,
  MutationUpdateCameraArgs,
  MutationUpdateControlUsersArgs,
  MutationUpdateUpsArgs,
  UpsEntity,
} from '../../../client/graphql';
import { createCameraGql } from './gql/createCamera.gql';
import { createClientGql } from './gql/createClient.gql';
import { createControlUsersGql } from './gql/createControlUsers.gql';
import { createUpsGql } from './gql/createUps.gql';
import { deleteCameraGql } from './gql/deleteCamera.gql';
import { updateCameraGql } from './gql/updateCamera.gql';
import { updateControlUsersGql } from './gql/updateControlUsers.gql';
import { updateUpsGql } from './gql/updateUps.gql';
import { AgentsStep } from './steps/agents.step';
import { CamerasStep } from './steps/cameras.step';
import { ClientStep } from './steps/client.step';
import { FpsStep } from './steps/fps.step';
import { UpsStep } from './steps/ups.step';
import { VaoStep } from './steps/vao.step';

export const spaceBeetwenInput = 2;
export const spaceBeetwenTitle = 1;
export const timeoutOnSearch = 200;

export type CreatePrestoScanWizardContext = {
  modeClient: 'search' | 'create';
  searchClient: Pick<ClientEntity, '_id' | 'siret' | 'slug'> | null;
  createClient: {
    name: string | null;
    slug: string | null;
    ticketsHub: string | null;
    code: string | null;
    defaultContractTag: string | null;
    siret: string | null;
    timezone: string | null;
    locale: string | null;
    defaultCurrency: string | null;
    maximumDuration: number | null;
    maximumAmount: number | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  } | null;
  listClient: Array<ClientEntity>;
  modeParking: 'search' | 'create';
  searchParking: {
    _id: string | null;
    code: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    streetName: string | null;
    specialPlace: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    addressCountry: string | null;
    latitude: number | null;
    longitude: number | null;
    capacity: number | null;
    occupancy: number | null;
    terminalId: string | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  } | null;
  createParking: {
    terminalId: string | null;
    latitude: number | null;
    longitude: number | null;
    capacity: number | null;
    occupancy: number | null;
    addressCountry: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    code: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    specialPlace: string | null;
    streetName: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    translation: Array<{
      description: string | null;
      lang: string | null;
      name: string | null;
    }>;
  } | null;
  listParking: Array<UpsEntity>;
  createVAO: {
    sitesLegacyId: string | null;
    rightsLegacyIds: Array<{ id: string }> | null;
    deletionDelay_complete: number | null;
    deletionDelay_fined: number | null;
    deletionDelay_incomplete: number | null;
    deletionDelay_toCheck: number | null;
    deletionDelay_unfined: number | null;
    entryTolerancePeriod: number | null;
    exitTolerancePeriod: number | null;
    isVerbalizationAllowed: boolean | null;
    outrageTreshold_danger: number | null;
    outrageTreshold_warning: number | null;
    showOccupancy: boolean | null;
  };
  FPS: {
    addressCountry: string | null;
    addressLocality: string | null;
    addressSubRegion: string | null;
    finePrice: number | null;
    name: string | null;
    notificationAuthority: string | null;
    organizationId: string | null;
    postOfficeBoxNumber: string | null;
    postalCode: string | null;
    reducedFinePrice: number | null;
    reducedPriceDuration: number | null;
    specialPlace: string | null;
    streetName: string | null;
    streetNumber: string | null;
    streetNumberBis: string | null;
    streetType: string | null;
    url: string | null;
  };
  cameras: Array<{
    _id?: string;
    position: CameraPositionEnum | null;
    code: string | null;
    description: string | null;
  }>;
  camerasSave: ReadonlyArray<{
    _id: string;
    position: CameraPositionEnum | null;
    code: string | null;
    description: string | null;
  }>;
  searchAgent: Pick<ControlUserEntity, '_id' | 'username' | 'ups'> | null;
  listAgent: Array<ControlUserEntity>;
  modeAgent: 'search' | 'create';
  createAgent: {
    firstName: string | null;
    fpsAgentId: number | null;
    fpsAgentName: string | null;
    lang: string | null;
    lastName: string | null;
    password: string | null;
    username: string | null;
  } | null;
};

export const langsItemsSelect: SelectType.Data.Item[] = [
  {
    value: 'fr_FR',
    label: 'Français',
  },
  {
    value: 'en_EN',
    label: 'Anglais',
  },
  {
    value: 'de_DE',
    label: 'Allemand',
  },
  {
    value: 'it_IT',
    label: 'Italien',
  },
];

type ResultPosibility =
  | FetchResult<Mutation['createClient']>
  | FetchResult<Mutation['createUps']>
  | FetchResult<Mutation['updateUps']>
  | FetchResult<Mutation['createCamera']>
  | FetchResult<Mutation['updateCamera']>
  | FetchResult<Mutation['deleteCamera']>
  | FetchResult<Mutation['createControlUsers']>
  | FetchResult<Mutation['updateControlUsers']>;

export const CreatePrestoScanWizard = () => {
  const [result, setResult] = useState<boolean>();
  const [resultError, setResultError] = useState<string>(
    "L'inscription n'a pas été réalisée !",
  );
  const [resultDetails, setResultDetails] = useState<
    Array<WizardType.Config.Details>
  >([]);
  const [data, setData] = useState<CreatePrestoScanWizardContext | null>(null);
  const { GQLStore, WizardStore } = useRootStore();

  const promises = useMemo((): Array<
    (
      results: Array<UseCascadeResult<ResultPosibility>>,
      index: number,
    ) => Promise<UseCascadeResult<ResultPosibility>>
  > => {
    if (!data) return [];

    const getUpsId = (
      prevResult: Array<UseCascadeResult<ResultPosibility>>,
    ): string | null => {
      if (data.modeParking === 'search') {
        return data?.searchParking?._id || null;
      }

      const findIndexResult: any =
        data.modeClient === 'create' && data.modeParking === 'create'
          ? prevResult[1]
          : prevResult[0];

      return findIndexResult &&
        !(findIndexResult instanceof Error) &&
        'ups' in findIndexResult?.response &&
        findIndexResult?.response?.ups?._id
        ? findIndexResult?.response?.ups._id
        : null;
    };

    const getClientId = (
      prevResult: Array<UseCascadeResult<ResultPosibility>>,
    ): string | null => {
      if (data.modeClient === 'search') {
        return data?.searchClient?._id || null;
      }

      const findIndexResult: any = prevResult[0];

      return findIndexResult &&
        !(findIndexResult instanceof Error) &&
        'client' in findIndexResult?.response &&
        findIndexResult?.response?.client?._id
        ? findIndexResult?.response?.client._id
        : null;
    };

    return [
      ...(data.modeClient === 'create'
        ? [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { createClient } = data;

              try {
                if (!createClient) throw new Error('createClient is null');

                const createClientExe = await GQLStore.client!.mutate<
                  Mutation['createClient'],
                  MutationCreateClientArgs
                >({
                  mutation: createClientGql,
                  variables: {
                    input: {
                      name: createClient.name!,
                      slug: createClient.slug!,
                      ticketsHub: createClient.ticketsHub!,
                      code: createClient.code!,
                      defaultContractTag: createClient.defaultContractTag!,
                      siret: createClient.siret!,
                      timezone: createClient.timezone!,
                      locale: createClient.locale!,
                      defaultCurrency: createClient.defaultCurrency!,
                      maximumDuration: createClient.maximumDuration!,
                      maximumAmount: createClient.maximumAmount!,
                      translation: createClient.translation
                        .filter(Boolean)
                        .map(({ lang, name, description }) => ({
                          lang: lang!,
                          name: name!,
                          description,
                        })),
                    },
                  },
                });

                return { title: 'createClient', response: createClientExe };
              } catch (error: unknown) {
                return {
                  title: 'createClient',
                  response: new Error(
                    error instanceof Error ? `${error?.message}` : `${error}`,
                  ),
                };
              }
            },
          ]
        : []),
      ...(data.modeParking === 'create'
        ? [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { createParking, searchClient, FPS, createVAO } = data;
              try {
                if (!createParking) throw new Error('createParking is null');
                if (!FPS) throw new Error('FPS is null');
                const createParkingExe = await GQLStore.client!.mutate<
                  Mutation['createUps'],
                  MutationCreateUpsArgs
                >({
                  mutation: createUpsGql,
                  variables: {
                    input: {
                      code: createParking.code!,
                      center: [createParking.latitude, createParking.longitude],
                      address: {
                        streetNumber:
                          toStringIfValue(createParking.streetNumber) || null,
                        streetNumberBis:
                          toStringIfValue(createParking.streetNumberBis) ||
                          null,
                        streetType:
                          toStringIfValue(createParking.streetType) || null,
                        streetName:
                          toStringIfValue(createParking.streetName) || null,
                        postOfficeBoxNumber:
                          toStringIfValue(createParking.postOfficeBoxNumber) ||
                          null,
                        specialPlace:
                          toStringIfValue(createParking.specialPlace) || null,
                        postalCode: toStringIfValue(createParking.postalCode)!,
                        addressLocality: toStringIfValue(
                          createParking.addressLocality,
                        )!,
                        addressSubRegion: toStringIfValue(
                          createParking.addressSubRegion,
                        )!,
                        addressCountry: toStringIfValue(
                          createParking.addressCountry,
                        )!,
                      },
                      clientId: getClientId(prevResult)!,
                      shape: null,
                      translation: createParking.translation
                        .filter(Boolean)
                        .map(({ lang, name, description }) => ({
                          lang: lang!,
                          name: name!,
                          description,
                        })),
                      type: 'PARK',
                      config: {
                        fps: {
                          system: 'iem',
                          notificationAuthority: FPS.notificationAuthority!,
                          reducedPriceDuration: FPS.reducedPriceDuration!,
                          finePrice: FPS.finePrice!,
                          reducedFinePrice: FPS.reducedFinePrice!,
                          recourseOrganization: {
                            address: {
                              addressCountry: toStringIfValue(
                                FPS.addressCountry,
                              )!,
                              addressLocality: toStringIfValue(
                                FPS.addressLocality,
                              )!,
                              addressSubRegion: toStringIfValue(
                                FPS.addressSubRegion,
                              )!,
                              postOfficeBoxNumber: toStringIfValue(
                                FPS.postOfficeBoxNumber,
                              ),
                              specialPlace: toStringIfValue(FPS.specialPlace),
                              streetName: toStringIfValue(FPS.streetName),
                              streetType: toStringIfValue(FPS.streetType),
                              streetNumberBis: toStringIfValue(
                                FPS.streetNumberBis,
                              ),
                              streetNumber: toStringIfValue(FPS.streetNumber)!,
                              postalCode: toStringIfValue(FPS.postalCode)!,
                            },
                            name: FPS.name!,
                            organizationId: FPS.organizationId!,
                            url: FPS.url!,
                          },
                        },
                        prestoScan: {
                          sitesLegacyId: createVAO.sitesLegacyId!,
                          rightsLegacyIds: createVAO
                            .rightsLegacyIds!.filter(Boolean)
                            .map(({ id }) => id),
                          entryTolerancePeriod: createVAO.entryTolerancePeriod!,
                          exitTolerancePeriod: createVAO.exitTolerancePeriod!,
                          isVerbalizationAllowed:
                            !!createVAO.isVerbalizationAllowed,
                          showOccupancy: !!createVAO.showOccupancy,
                          deletionDelay: {
                            incomplete: createVAO.deletionDelay_incomplete!,
                            complete: createVAO.deletionDelay_complete!,
                            toCheck: createVAO.deletionDelay_toCheck!,
                            unfined: createVAO.deletionDelay_unfined!,
                            fined: createVAO.deletionDelay_fined!,
                          },
                          outrageTreshold: {
                            danger: createVAO.outrageTreshold_danger!,
                            warning: createVAO.outrageTreshold_warning!,
                            valid: 0,
                          },
                          terminalId: createParking.terminalId!,
                        },
                      },
                      occupancy: createParking.occupancy!,
                      capacity: createParking.capacity!,
                    },
                  },
                });

                return { title: 'createParking', response: createParkingExe };
              } catch (error: unknown) {
                return {
                  title: 'createParking',
                  response: new Error(
                    error instanceof Error ? `${error.message}` : `${error}`,
                  ),
                };
              }
            },
          ]
        : [
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              const { searchParking, searchClient, FPS, createVAO } = data;
              try {
                if (!searchParking) throw new Error('searchParking is null');
                if (!FPS) throw new Error('FPS is null');

                const updateParkingExe = await GQLStore.client!.mutate<
                  Mutation['updateUps'],
                  MutationUpdateUpsArgs
                >({
                  mutation: updateUpsGql,
                  variables: {
                    input: {
                      upsId: searchParking._id!,
                      occupancy: searchParking.occupancy!,
                      capacity: searchParking.capacity!,
                      center: [
                        searchParking.latitude!,
                        searchParking.longitude!,
                      ],
                      address: {
                        streetNumber: toStringIfValue(
                          searchParking.streetNumber,
                        ),
                        streetNumberBis: toStringIfValue(
                          searchParking.streetNumberBis,
                        ),
                        streetType: toStringIfValue(searchParking.streetType),
                        streetName: toStringIfValue(searchParking.streetName),
                        postOfficeBoxNumber: toStringIfValue(
                          searchParking.postOfficeBoxNumber,
                        ),
                        specialPlace: toStringIfValue(
                          searchParking.specialPlace,
                        ),
                        postalCode: toStringIfValue(searchParking.postalCode),
                        addressLocality: toStringIfValue(
                          searchParking.addressLocality,
                        ),
                        addressSubRegion: toStringIfValue(
                          searchParking.addressSubRegion,
                        ),
                        addressCountry: toStringIfValue(
                          searchParking.addressCountry,
                        ),
                      },
                      config: {
                        fps: {
                          system: 'iem',
                          notificationAuthority: FPS.notificationAuthority!,
                          reducedPriceDuration: FPS.reducedPriceDuration,
                          finePrice: FPS.finePrice,
                          reducedFinePrice: FPS.reducedFinePrice,
                          recourseOrganization: {
                            address: {
                              addressCountry: toStringIfValue(
                                FPS.addressCountry,
                              ),
                              addressLocality: toStringIfValue(
                                FPS.addressLocality,
                              ),
                              addressSubRegion: toStringIfValue(
                                FPS.addressSubRegion,
                              ),
                              postOfficeBoxNumber: toStringIfValue(
                                FPS.postOfficeBoxNumber,
                              ),
                              specialPlace: toStringIfValue(FPS.specialPlace),
                              streetName: toStringIfValue(FPS.streetName),
                              streetType: toStringIfValue(FPS.streetType),
                              streetNumberBis: toStringIfValue(
                                FPS.streetNumberBis,
                              ),
                              streetNumber: toStringIfValue(FPS.streetNumber),
                              postalCode: toStringIfValue(FPS.postalCode),
                            },
                            name: FPS.name,
                            organizationId: FPS.organizationId,
                            url: FPS.url,
                          },
                        },
                        prestoScan: {
                          sitesLegacyId: createVAO.sitesLegacyId,
                          rightsLegacyIds:
                            createVAO?.rightsLegacyIds?.map(({ id }) => id) ||
                            null,
                          entryTolerancePeriod: createVAO.entryTolerancePeriod,
                          exitTolerancePeriod: createVAO.exitTolerancePeriod,
                          isVerbalizationAllowed:
                            !!createVAO.isVerbalizationAllowed,
                          deletionDelay: {
                            incomplete: createVAO.deletionDelay_incomplete,
                            complete: createVAO.deletionDelay_complete,
                            toCheck: createVAO.deletionDelay_toCheck,
                            unfined: createVAO.deletionDelay_unfined,
                            fined: createVAO.deletionDelay_fined,
                          },
                          outrageTreshold: {
                            danger: createVAO.outrageTreshold_danger,
                            warning: createVAO.outrageTreshold_warning,
                            valid: 0,
                          },
                          terminalId: searchParking.terminalId!,
                        },
                      },
                      translation: searchParking.translation
                        .filter(Boolean)
                        .map(({ lang, name, description }) => ({
                          lang: lang!,
                          name: name!,
                          description,
                        })),
                    },
                  },
                });

                return { title: 'updateParking', response: updateParkingExe };
              } catch (error: unknown) {
                return {
                  title: 'updateParking',
                  response: new Error(
                    error instanceof Error ? `${error.message}` : `${error}`,
                  ),
                };
              }
            },
          ]),
      ...[...data.cameras, ...data.camerasSave]
        .reduce((acc: CreatePrestoScanWizardContext['cameras'], camera) => {
          if (!('_id' in camera)) {
            return [...acc, camera];
          } else if (acc.find((element) => element._id === camera._id)) {
            return acc;
          } else {
            return [...acc, camera];
          }
        }, [])
        .filter(Boolean)
        .map(
          (camera) =>
            async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
              if (!('_id' in camera)) {
                try {
                  const createCamera = await GQLStore.client!.mutate<
                    Mutation['createCamera'],
                    MutationCreateCameraArgs
                  >({
                    mutation: createCameraGql,
                    variables: {
                      input: {
                        code: camera.code!,
                        position: camera.position!,
                        description: camera.description,
                        ups: getUpsId(prevResult)!,
                      },
                    },
                  });

                  return { title: 'createCamera', response: createCamera };
                } catch (error: unknown) {
                  return {
                    title: 'createCamera',
                    response: new Error(
                      error instanceof Error ? `${error.message}` : `${error}`,
                    ),
                  };
                }
              } else if (
                data.camerasSave.find(
                  (cameraSave) => cameraSave._id === camera._id,
                ) &&
                data.cameras.find((cameraSave) => cameraSave._id === camera._id)
              ) {
                try {
                  const updateCamera = await GQLStore.client!.mutate<
                    Mutation['updateCamera'],
                    MutationUpdateCameraArgs
                  >({
                    mutation: updateCameraGql,
                    variables: {
                      input: {
                        code: camera.code!,
                        position: camera.position!,
                        description: camera.description!,
                        ups: getUpsId(prevResult)!,
                        cameraId: camera._id,
                      },
                    },
                  });

                  return { title: 'updateCamera', response: updateCamera };
                } catch (error: unknown) {
                  return {
                    title: 'createCamera',
                    response: new Error(
                      error instanceof Error ? `${error.message}` : `${error}`,
                    ),
                  };
                }
              } else {
                try {
                  const deleteCamera = await GQLStore.client!.mutate<
                    Mutation['deleteCamera'],
                    MutationDeleteCameraArgs
                  >({
                    mutation: deleteCameraGql,
                    variables: {
                      input: {
                        cameraId: camera._id!,
                      },
                    },
                  });

                  return { title: 'deleteCamera', response: deleteCamera };
                } catch (error: unknown) {
                  return {
                    title: 'deleteCamera',
                    response: new Error(
                      error instanceof Error ? `${error.message}` : `${error}`,
                    ),
                  };
                }
              }
            },
        ),
      data.modeAgent === 'create'
        ? async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            const { createAgent } = data;

            try {
              if (!createAgent) throw new Error('createAgent is null');

              const createAgentExe = await GQLStore.client!.mutate<
                Mutation['createControlUsers'],
                MutationCreateControlUsersArgs
              >({
                mutation: createControlUsersGql,
                variables: {
                  input: {
                    firstName: createAgent.firstName!,
                    lastName: createAgent.lastName!,
                    username: createAgent.username!,
                    lang: createAgent.lang!,
                    password: createAgent.password!,
                    fpsAgentId: `${createAgent.fpsAgentId!}`,
                    fpsAgentName: createAgent.fpsAgentName!,
                    upsId: getUpsId(prevResult)!,
                  },
                },
              });

              return { title: 'createAgent', response: createAgentExe };
            } catch (error: unknown) {
              return {
                title: 'createAgent',
                response: new Error(
                  error instanceof Error ? `${error.message}` : `${error}`,
                ),
              };
            }
          }
        : async (prevResult: Array<UseCascadeResult<ResultPosibility>>) => {
            const { searchAgent } = data;

            const ups = [...(searchAgent?.ups || [])];

            const parkingId = getUpsId(prevResult);

            if (parkingId) ups.push(parkingId);

            try {
              if (!searchAgent) throw new Error('searchAgent is null');

              const updateAgentExe = await GQLStore.client!.mutate<
                Mutation['updateControlUsers'],
                MutationUpdateControlUsersArgs
              >({
                mutation: updateControlUsersGql,
                variables: {
                  input: {
                    userId: searchAgent._id!,
                    ups,
                  },
                },
              });
              return { title: 'updateAgent', response: updateAgentExe };
            } catch (error: unknown) {
              return {
                title: 'updateAgent',
                response: new Error(
                  error instanceof Error ? `${error.message}` : `${error}`,
                ),
              };
            }
          },
    ];
  }, [data, GQLStore]);

  const { isFinish, results } = useCascade<ResultPosibility>(
    promises,
    promises.length > 0,
  );

  useEffect(() => {
    if (isFinish && promises.length > 0) {
      if (results.every((item) => !(item.response instanceof Error))) {
        setResult(() => true);
      } else {
        setResult(() => false);
        setResultError(() =>
          results.every((item) => item.response instanceof Error)
            ? "L'inscription n'a pas été réalisée !"
            : "L'inscription a été partiellement réalisée !",
        );

        setResultDetails(() =>
          results.map((item) => {
            if (item.response instanceof Error) {
              return {
                type: 'error',
                title: item.title,
                description: item.response.message,
              };
            } else {
              return { type: 'success', title: item.title };
            }
          }),
        );
      }
    }
  }, [isFinish, results]);

  return (
    <Wizard<CreatePrestoScanWizardContext>
      config={{
        title: 'Configuration PrestoScan',
        withValidation: {
          state: result,
          success: "L'inscription a été réalisée !",
          error: resultError,
          details: resultDetails,
        },
      }}
      data={{
        defaultContext: {
          createClient: {
            code: null,
            defaultContractTag: null,
            defaultCurrency: null,
            locale: null,
            maximumAmount: null,
            maximumDuration: null,
            name: null,
            siret: null,
            slug: null,
            ticketsHub: null,
            timezone: null,
            translation: [],
          },
          modeClient: 'search',
          searchClient: null,
          listClient: [],
          createParking: {
            terminalId: null,
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            capacity: null,
            code: null,
            latitude: null,
            longitude: null,
            occupancy: null,
            postOfficeBoxNumber: null,
            postalCode: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            translation: [],
          },
          listParking: [],
          modeParking: 'search',
          searchParking: {
            _id: null,
            code: null,
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            capacity: null,
            latitude: null,
            longitude: null,
            occupancy: null,
            postalCode: null,
            postOfficeBoxNumber: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            translation: [],
            terminalId: null,
          },
          createVAO: {
            sitesLegacyId: null,
            rightsLegacyIds: null,
            deletionDelay_complete: 3, //* En règle
            deletionDelay_fined: 90, //* Verbalisée
            deletionDelay_incomplete: 3, //* Incomplète
            deletionDelay_toCheck: 10, //* A contrôler
            deletionDelay_unfined: 3, //* Non verbalisée
            entryTolerancePeriod: null,
            exitTolerancePeriod: null,
            isVerbalizationAllowed: false,
            outrageTreshold_danger: null,
            outrageTreshold_warning: null,
            showOccupancy: false,
          },
          FPS: {
            addressCountry: null,
            addressLocality: null,
            addressSubRegion: null,
            finePrice: null,
            name: null,
            notificationAuthority: 'LOCAL',
            organizationId: null,
            postOfficeBoxNumber: null,
            postalCode: null,
            reducedFinePrice: null,
            reducedPriceDuration: null,
            specialPlace: null,
            streetName: null,
            streetNumber: null,
            streetNumberBis: null,
            streetType: null,
            url: null,
          },
          cameras: [],
          camerasSave: [],
          searchAgent: null,
          listAgent: [],
          modeAgent: 'search',
          createAgent: {
            firstName: null,
            fpsAgentId: null,
            fpsAgentName: null,
            lang: null,
            lastName: null,
            password: null,
            username: null,
          },
        },
      }}
      handleEvent={{
        done: async (value) => {
          setData(value);
        },
        clear: () => {
          console.log('CLEAR');
        },
        close: () => {
          WizardStore.clear();
        },
      }}
    >
      <Wizard.Item config={{ title: 'Client', component: ClientStep }} />
      <Wizard.Item config={{ title: 'Parking', component: UpsStep }} />
      <Wizard.Item config={{ title: 'PrestoScan', component: VaoStep }} />
      <Wizard.Item config={{ title: 'FPS', component: FpsStep }} />
      <Wizard.Item config={{ title: 'Cameras', component: CamerasStep }} />
      <Wizard.Item config={{ title: '1er agent', component: AgentsStep }} />
    </Wizard>
  );
};
