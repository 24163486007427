import {
  Badge,
  Col,
  Space,
  Table,
  useMyUrl,
  useQuery,
  Widget,
} from '@gimlite/watermelon';

import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Query, QuerySiteArgs } from '../../../../../client/graphql';
import { siteFragmentsDebugPaging } from '../../gql/entity/fragments.gql';
import { siteFragmentsGql } from '../../gql/tab/fragments.gql';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsFragment = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  const fragmentQuery = useQuery<{ site: Query['site'] }, QuerySiteArgs>(
    siteFragmentsGql,
    {
      variables: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
    },
  );

  const debugFragmentQuery = useQuery<{ site: Query['site'] }, QuerySiteArgs>(
    siteFragmentsDebugPaging,
    {
      variables: {
        ...siteDetailsDefaultParams,
        ...getParamsUrl,
        siteId,
        type: 'dbg',
      },
    },
  );

  const fragmentFormattedPaging = useCallback(
    (fragments: Query['site']['fragments']) => {
      return {
        list: fragments?.list.map((fragment) => {
          return {
            ...fragment,
            fragmentRef: !!fragment.fragmentRef ? (
              <Link to={`/adm/fragments/${fragment._id}`}>
                {fragment.fragmentRef.split(':')?.[1]}
              </Link>
            ) : (
              fragment.bucketId
            ),
            bucketId:
              !!fragment.bucketId && fragment.bucketId !== 'none' ? (
                <Link to={`/adm/buckets/${fragment.bucketId}`}>
                  {fragment.bucketId}
                </Link>
              ) : (
                fragment.bucketId
              ),
            logDate: fragment?.logDate
              ? DateTime.fromISO(fragment.logDate).toFormat('dd/MM/yy HH:mm:ss')
              : null,
            state: fragment?.state ? (
              fragment.state !== 'PROCESSED' ? (
                <Badge config={{ color: 'error', text: fragment.state }} />
              ) : (
                <Badge config={{ color: 'success', text: fragment.state }} />
              )
            ) : undefined,
          };
        }),
        paging: fragments?.paging,
      };
    },
    [],
  );

  const fragment = useMemo(() => {
    if (!fragmentQuery?.data?.site) return null;

    const { fragments, ...rest } = fragmentQuery.data.site;

    return {
      ...rest,
      fragments: fragments ? fragmentFormattedPaging(fragments) : undefined,
    };
  }, [fragmentQuery.data]);

  const debugFragment = useMemo(() => {
    if (!debugFragmentQuery?.data?.site) return null;

    const { fragments, ...rest } = debugFragmentQuery.data.site;

    return {
      ...rest,
      fragments: fragments ? fragmentFormattedPaging(fragments) : undefined,
    };
  }, [debugFragmentQuery.data]);

  return (
    <Col>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: fragmentQuery.error,
          loading: fragmentQuery.loading,
          refetch: fragmentQuery.refetch,
        }}
        config={{ title: 'Fragments' }}
      >
        <Table<any>
          handleEvent={{
            paging: (value: any) => {
              const { page: fragmentsPage, limit: fragmentsLimit } = value;
              setParamsUrl({
                ...getParamsUrl,
                fragmentsPage,
                fragmentsLimit,
              });
            },
          }}
          data={fragment?.fragments}
          config={{
            columns: [
              {
                title: 'Bucket',
                key: 'bucketId',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'type',
                key: 'type',
              },
              {
                title: 'state',
                key: 'state',
              },
            ],
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: debugFragmentQuery.error,
          loading: debugFragmentQuery.loading,
          refetch: debugFragmentQuery.refetch,
        }}
        config={{ title: 'Debug' }}
      >
        <Table<any>
          handleEvent={{
            paging: (value) => {
              const { page: fragmentsDebugPage, limit: fragmentsDebugLimit } =
                value;
              setParamsUrl({
                ...getParamsUrl,
                fragmentsDebugPage,
                fragmentsDebugLimit,
              });
            },
          }}
          data={debugFragment?.fragments}
          config={{
            columns: [
              {
                title: 'bucketId',
                key: 'bucketId',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'logDate',
                key: 'logDate',
              },
              {
                title: 'type',
                key: 'type',
              },
              {
                title: 'state',
                key: 'state',
              },
            ],
          }}
        />
      </Widget>
    </Col>
  );
};
