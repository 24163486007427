import {
  Col,
  Description,
  Space,
  Tab,
  Table,
  useLazyQuery,
  useMyUrl,
  useQuery,
  Widget,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  CurrencyAmount,
  Query,
  QuerySiteArgs,
} from '../../../../../client/graphql';
import { TrunkAmountChart } from '../../../../charts/trunkAmount.chart';
import { TrunkCoinsChart } from '../../../../charts/trunkCoins.chart';
import { siteFinanceGql } from '../../gql/tab/finance.gql';
import { exportCollectionPdf } from '../../render/finance-pdf.render';
import { siteDetailsDefaultParams, SitesDetailsFilter } from '../sites.details';

export const SitesDetailsFinance = () => {
  const { getParamsUrl, setParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  const [siteCall, { data, loading, error, refetch }] = useLazyQuery<
    { site: Query['site'] },
    QuerySiteArgs
  >(siteFinanceGql);

  const site = useMemo(() => {
    if (!data) return null;

    const {
      site: { ...rest },
    } = data;

    return {
      ...rest,
    };
  }, [data]);

  useEffect(() => {
    siteCall({
      variables: {
        ...siteDetailsDefaultParams,
        ...getParamsUrl,
        siteId: siteId || '',
      },
    });
  }, [siteDetailsDefaultParams, getParamsUrl, siteId]);

  return (
    <Col config={{ scrollY: true }}>
      <SitesDetailsFilter />
      <Space config={{ count: 2 }} />
      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Collecte de pièces', yMax: 500 }}
      >
        <Table<{
          _id: string;
          logDate: string;
          localAmount: string | null;
          coins: any;
          comment: string | null;
          cardUID: string | null;
          fragmentRef: React.ReactNode;
          rejectedCoins: any;
          inhibitedCoins: any;
          siteId: string;
          createdAt: string;
          currencyAmounts: CurrencyAmount[] | null;
          terminalId: string;
          name: string | null;
          $expandable: React.ReactNode | null;
        }>
          handleEvent={{
            paging: (value) => {
              setParamsUrl({
                ...getParamsUrl,
                collectsPage: value.page,
                collectsLimit: value.limit,
              });
            },
          }}
          data={
            site?.collects
              ? {
                  list: site.collects?.list?.map(
                    ({
                      logDate,
                      localAmount,
                      coins,
                      comment,
                      cardUID,
                      fragmentRef,
                      _id,
                      rejectedCoins,
                      inhibitedCoins,
                      siteId,
                      createdAt,
                      currencyAmounts,
                      terminalId,
                    }) => {
                      const currency = 'CHF'; //! TODO: VIENT D'OU ? Pas dans le graph

                      return {
                        _id,
                        coins,
                        comment: comment || null,
                        cardUID: cardUID || null,
                        rejectedCoins,
                        inhibitedCoins,
                        siteId,
                        createdAt,
                        currencyAmounts: currencyAmounts || null,
                        terminalId,
                        name: comment || null,
                        fragmentRef: (
                          <Link to={`/adm/fragmentsByRef/${fragmentRef}`}>
                            {fragmentRef.split(':')?.[1]}
                          </Link>
                        ),
                        localAmount:
                          typeof localAmount === 'number'
                            ? new Intl.NumberFormat(currency, {
                                style: 'currency',
                                currency: currency,
                              }).format(localAmount / 100)
                            : null,
                        $expandable: coins ? (
                          <Description
                            data={Object.entries(coins).map(([key, value]) => {
                              return {
                                key,
                                label: key,
                                value: value ? `${value}` : null,
                              };
                            })}
                          />
                        ) : null,
                        logDate:
                          DateTime.fromISO(logDate).toFormat(
                            'dd/MM/yy HH:mm:ss',
                          ),
                      };
                    },
                  ),
                  paging: site.collects.paging,
                }
              : undefined
          }
          config={{
            columns: [
              {
                title: 'Date',
                key: 'logDate',
              },
              {
                title: 'Ref.',
                key: 'fragmentRef',
              },
              {
                title: 'Montant total',
                key: 'localAmount',
              },
              {
                title: 'Pièces rejetées',
                key: 'rejectedCoins',
              },
              {
                title: 'Pièces inhibées',
                key: 'inhibitedCoins',
              },
              {
                title: 'Card UID',
                key: 'cardUID',
              },
              {
                title: 'Name',
                key: 'name',
              },
            ],
            actions: {
              export: (collectLine) => {
                exportCollectionPdf(collectLine, site!);
              },
            },
          }}
        />
      </Widget>
      <Space config={{ count: 2 }} />

      <Widget
        state={{
          error: error,
          loading,
          refetch,
        }}
        config={{ title: 'Etat du coffre', yMax: 'min-content' }}
      >
        <Tab
          data={{
            items: [
              {
                key: 'amounts',
                label: 'Montants',
                children: (
                  <Col>
                    <TrunkAmountChart
                      config={{
                        startDate: getParamsUrl?.startDate,
                        endDate: getParamsUrl?.stopDate,
                      }}
                      data={
                        site?.safeInfos?.graph?.map(
                          ({ logDate, currencyAmounts, localAmount }) => {
                            const TOK =
                              currencyAmounts?.find(
                                ({ currency }) => currency === 'TOK',
                              )?.localAmount || 0;

                            const CHF =
                              currencyAmounts?.find(
                                ({ currency }) => currency === 'CHF',
                              )?.localAmount || 0;

                            const EUR =
                              currencyAmounts?.find(
                                ({ currency }) => currency === 'EUR',
                              )?.localAmount || 0;

                            const total = localAmount || null;

                            return {
                              date: new Date(logDate),
                              TOK: TOK / 100,
                              CHF: CHF / 100,
                              EUR: EUR / 100,
                              total: total ? total / 100 : null,
                            };
                          },
                        ) || []
                      }
                    ></TrunkAmountChart>
                  </Col>
                ),
              },
              {
                key: 'coins',
                label: 'Pièces',
                children: (
                  <Col>
                    <TrunkCoinsChart
                      config={{
                        startDate: getParamsUrl?.startDate,
                        endDate: getParamsUrl?.stopDate,
                      }}
                      data={
                        site?.safeInfos?.graph?.map(
                          ({ logDate, rejectedCoins, inhibitedCoins }) => ({
                            date: new Date(logDate),
                            rejected: rejectedCoins || null,
                            inhibited: inhibitedCoins || null,
                          }),
                        ) || []
                      }
                    ></TrunkCoinsChart>
                  </Col>
                ),
              },
            ],
            selected: 'amounts',
          }}
        />
      </Widget>
    </Col>
  );
};
