import {
  FilterExpanded,
  Form,
  Page,
  Table,
  Widget,
  Zone,
  useMyUrl,
  useQuery,
  Input,
  Button,
} from '@gimlite/watermelon';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Query, QueryMailsArgs } from '../../../../client/graphql';
import { mailsGql } from '../gql/mails.gql';

export const MailsSearch = () => {
  const { getParamsUrl, setParamsUrl, clearParamsUrl } = useMyUrl();

  const { data, loading, error, refetch } = useQuery<
    { mails: Query['mails'] },
    QueryMailsArgs
  >(mailsGql, {
    variables: { limit: 15, ...getParamsUrl },
  });

  const mails = useMemo(() => {
    if (!data) return null;

    return data?.mails;
  }, [data]);

  return (
    <Page>
      <Widget
        state={{ loading, error, refetch }}
        config={{ title: 'Liste des mails' }}
      >
        <Zone
          config={{
            zones: [['filter'], ['search']],
            rows: ['min-content', '1fr'],
            columns: ['1fr'],
          }}
        >
          <Zone.Area config={{ area: 'filter' }}>
            <FilterExpanded
              data={{ value: getParamsUrl }}
              handleEvent={{
                submit: (data: any) => {
                  setParamsUrl({ ...getParamsUrl, ...data, page: 1 });
                },
              }}
            >
              <FilterExpanded.Fields>
                <Form.Item
                  config={{
                    name: 'state',
                    label: 'State',
                  }}
                >
                  <Input
                    data={{ defaultValue: getParamsUrl?.state }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
                <Form.Item config={{ name: 'from', label: 'From' }}>
                  <Input
                    data={{ defaultValue: getParamsUrl?.from }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
                <Form.Item config={{ name: 'to', label: 'To' }}>
                  <Input
                    data={{ defaultValue: getParamsUrl?.to }}
                    config={{ width: 'large' }}
                  />
                </Form.Item>
              </FilterExpanded.Fields>
              <FilterExpanded.Actions>
                <Button
                  config={{ text: 'Rechercher', type: { value: 'submit' } }}
                />
                <Button
                  handleEvent={{
                    click: () => clearParamsUrl(),
                  }}
                  config={{
                    text: 'Nettoyer la recherche',
                    disabled: !(
                      getParamsUrl && Object.values(getParamsUrl).length > 0
                    ),
                  }}
                />
              </FilterExpanded.Actions>
            </FilterExpanded>
          </Zone.Area>
          <Zone.Area config={{ area: 'search' }}>
            <Table<any>
              handleEvent={{
                paging: (value) => {
                  setParamsUrl({ ...getParamsUrl, ...value });
                },
              }}
              data={
                mails
                  ? {
                      list: mails?.list.map(({ to, createdAt, ...rest }) => ({
                        ...rest,
                        to: to?.toString().replaceAll(',', ' / ') || null,
                        diff: createdAt
                          ? DateTime.fromISO(createdAt).toRelative()
                          : null,
                      })),
                      paging: mails?.paging,
                    }
                  : undefined
              }
              config={{
                pagination: 'click',
                columns: [
                  {
                    title: 'State',
                    key: 'state',
                  },
                  {
                    title: 'From',
                    key: 'from',
                  },
                  {
                    title: 'To',
                    key: 'to',
                  },
                  {
                    title: 'System',
                    key: 'system',
                  },
                  {
                    title: 'Processed At',
                    key: 'diff',
                  },
                ],
              }}
            />
          </Zone.Area>
        </Zone>
      </Widget>
    </Page>
  );
};
