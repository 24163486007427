import {
  Wizard,
  WizardItemType,
} from '@gimlite/watermelon/components/wizard/wizard.component';
import { useTranslation } from '@gimlite/watermelon/hook/useTranslation.hook';
import { observer } from 'mobx-react-lite';
import QRCode from 'qrcode';
import { useCallback, useEffect } from 'react';
import { Col } from '../../../../components/col/col.component';
import { HyperLink } from '../../../../components/hyperlink/hyperlink.component';
import { Space } from '../../../../components/space/space.component';
import { Widget } from '../../../../components/widget/widget.component';
import { Write } from '../../../../components/write/write.component';
import { Zone } from '../../../../components/zone/zone.component';
import { generate2FAGql } from '../../../../gql/generate2FA.gql';
import { useRootStore } from '../../../../hook/useRootStore.hook';
import { State2FAContext } from '../../state2FA.wizard';

export const SynchronizeStep = observer(
  ({
    context: { uri, secret },
    validate,
    submit,
    loading,
  }: WizardItemType.Config.Component<State2FAContext>) => {
    const { t, lang } = useTranslation();
    const { NotificationStore, GQLStore } = useRootStore();
    const generate2FA = useCallback(async () => {
      try {
        const response = await GQLStore.client?.mutate<{
          generate2FA: { uri: string; secret: string };
        }>({
          mutation: generate2FAGql,
          variables: {},
        });

        submit({
          secret: response?.data?.generate2FA.secret,
          uri: response?.data?.generate2FA.uri,
        });
      } catch {
        NotificationStore.emit({
          mode: 'ERROR',
          content: t('watermelon-SERVER_PROBLEM'),
        });
      }
    }, []);

    useEffect(() => {
      validate(true);

      if (!uri && !secret) {
        loading(true);
        generate2FA();
      }
    }, []);

    useEffect(() => {
      if (uri) {
        const nodeQRCode = document.getElementById('qrcode_2FA');
        const size = '250px';
        loading(false);

        QRCode.toCanvas(nodeQRCode, uri, (error: unknown) => {
          if (error) {
            NotificationStore.emit({
              mode: 'ERROR',
              content: t('watermelon-SERVER_PROBLEM'),
            });
          } else {
            nodeQRCode!.style.height = size;
            nodeQRCode!.style.width = size;
          }
        });
      }
    }, [uri]);
    return (
      <Zone
        config={{
          gap: {
            y: 1,
            x: 1,
          },
          zones: [['info', 'form']],
          rows: ['1fr'],
          columns: ['min-content', '1fr'],
        }}
      >
        <Zone.Area config={{ area: 'info' }}>
          <Wizard.CardInfo
            data={{
              title: t('watermelon-intructions'),
              info: (
                <Col>
                  <Write
                    data={{ item: `${t('watermelon-pleaseInstall')} :` }}
                    config={{ mode: 'value-medium' }}
                  />
                  <Space config={{ count: 2 }} />
                  <HyperLink
                    config={{ type: 'GOOGLE_AUTHENTIFICATOR' }}
                  ></HyperLink>
                  <Space config={{ count: 2 }} />
                  <Write
                    data={{
                      item: t('watermelon-2FA_INSTRUCTION_INSTALL_PLACE'),
                    }}
                    config={{ mode: 'value-medium' }}
                  />
                </Col>
              ),
            }}
          />
        </Zone.Area>

        <Zone.Area config={{ area: 'form' }}>
          <Zone
            config={{
              zones: [['qrcode']],
              rows: ['1fr'],
              columns: ['1fr'],
            }}
          >
            <Zone.Area config={{ area: 'qrcode' }}>
              <Widget config={{ title: t('watermelon-qrcode'), shadow: false }}>
                <Col
                  config={{
                    horizontal: 'center',
                    vertical: 'start',
                    height: 'full',
                    scrollY: true,
                  }}
                >
                  <canvas
                    style={{ height: '250px!important' }}
                    id="qrcode_2FA"
                  ></canvas>
                  <Space config={{ count: 2 }} />
                  {secret && (
                    <Write
                      data={{ item: secret }}
                      config={{
                        mode: 'title-small',
                        copyPaste: true,
                        wrap: true,
                      }}
                    />
                  )}
                  <Space config={{ count: 2 }} />
                  <Write
                    data={{
                      item: t('watermelon-2FA_INSTRUCTION_COPY_SAFE'),
                    }}
                    config={{ mode: 'value-medium', align: 'center' }}
                  />
                </Col>
              </Widget>
            </Zone.Area>
          </Zone>
        </Zone.Area>
      </Zone>
    );
  },
);
