import {
  useMutation,
  useMyUrl,
  useRootStore,
  Wizard,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { Mutation, MutationUpdateAdminUserArgs } from '../../../client/graphql';
import { updateAdminUserGql } from './gql/updateAdminUser.gql';
import { UpdateCredentialsStep } from './steps/update-creds.step';

export type UpdateAdminUserWizardContext = {
  id: string;
  password: string;
};

export const UpdateAdminUserWizard = () => {
  const { getParamsUrl } = useMyUrl();
  const { id } = getParamsUrl;
  const { WizardStore } = useRootStore();

  const [updateAdminUser, updateAdminUserState] = useMutation<
    Mutation['updateAdminUser'],
    MutationUpdateAdminUserArgs
  >(updateAdminUserGql);

  const state = useMemo(() => {
    if (updateAdminUserState.loading) return undefined;
    if (updateAdminUserState.error) return false;
    if (updateAdminUserState.data) return true;
  }, [updateAdminUserState]);

  return (
    <Wizard<UpdateAdminUserWizardContext>
      config={{
        title: "Mise à jour de l'utilisateur",
        withValidation: {
          state,
          success: "La mise à jour de l'utilisateur a été réalisée !",
          error:
            "Une erreur est survenue lors de la mise à jour de l'utilisateur",
        },
      }}
      data={{
        defaultContext: {
          id,
          password: '',
        },
      }}
      handleEvent={{
        done: async (value: UpdateAdminUserWizardContext) => {
          try {
            await updateAdminUser({
              variables: {
                id,
                password: value.password,
              },
            });
          } catch (error) {
            console.log('🚀 ~ done: ~ error:', error);
          }
        },
        close: () => WizardStore.clear(),
      }}
    >
      <Wizard.Item
        config={{
          title: 'Credentials update',
          component: UpdateCredentialsStep,
        }}
      />
    </Wizard>
  );
};
