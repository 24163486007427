import { useQuery } from '@apollo/client';
import {
  Badge,
  Blurb,
  Description,
  Icon,
  ListFlatInfo,
  Popover,
  Result,
  Row,
  Space,
  Statistic,
  useMyUrl,
  Widget,
  Zone,
  ResultType,
  Col,
} from '@gimlite/watermelon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Query, QuerySiteArgs } from '../../../../../client/graphql';
import { siteGlobalGql } from '../../gql/tab/global.gql';
import { statsEnergie } from '../../render/energie.render';
import { siteDetailsDefaultParams } from '../sites.details';

const errorMapper = Object.freeze({
  infos: 'default',
  'action-requested': 'warn',
  degraded: 'warn',
  'out-of-order': 'error',
} as const);

const status: Record<
  string,
  { status: ResultType.Props['data']['status']; title: string }
> = {
  up: {
    status: 'success',
    title: 'La machine fonctionne correctement',
  },
  degraded: {
    status: 'warn',
    title: 'La machine est dans état dégradé',
  },
  down: {
    status: 'error',
    title: 'La machine est hors service',
  },
  'not-responding': {
    status: 'warn',
    title: 'La machine ne répond pas',
  },
} as const;

export const SitesDetailsGlobal = () => {
  const { getParamsUrl } = useMyUrl();
  const { siteId } = useParams();

  const { data, loading, error, refetch } = useQuery<
    { site: Query['site'] },
    QuerySiteArgs
  >(siteGlobalGql, {
    variables: { ...siteDetailsDefaultParams, ...getParamsUrl, siteId },
    context: {
      addTypename: false,
    },
  });

  const site = useMemo(() => {
    if (!data) return null;

    const {
      site: { monitoring, energies, ...rest },
    } = data;

    return {
      ...rest,
      monitoring,
      state: status[monitoring?.status as keyof typeof status],
      energies: energies?.graph ? statsEnergie(energies?.graph) : null,
    };
  }, [data]);

  return (
    <Row>
      <Col>
        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Etat du terminal' }}
        >
          <Result
            data={{
              status: site?.state?.status || (loading ? null : 'error'),
              title:
                site?.state?.title ||
                (loading ? null : 'Aucun terminal assigné'),
            }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Alarmes courantes' }}
        >
          <ListFlatInfo<'icon' | 'blurb' | 'badge'>
            config={{
              pagination: 'none',
              columns: [
                { key: 'icon', label: '', size: 'min-content' },
                { key: 'blurb', label: '', size: '1fr' },
                { key: 'badge', label: '', size: 'min-content' },
              ],
            }}
            data={{
              list:
                Object.values(site?.monitoring?.currentAlarms || [])?.map(
                  ({ alarm, effect, extra, startDate }) => {
                    const title =
                      alarm.charAt(0).toUpperCase() + alarm.slice(1);

                    return {
                      _id: title + startDate,
                      items: {
                        icon: (
                          <Icon
                            config={{
                              type: 'faLandMineOnSolid',
                              color: 'primary',
                            }}
                          ></Icon>
                        ),
                        blurb: (
                          <Blurb
                            data={{
                              title,
                            }}
                          ></Blurb>
                        ),
                        badge: (
                          <Badge
                            config={{
                              text: effect ? effect.toUpperCase() : 'PROBLEME',
                              color:
                                errorMapper[
                                  effect as keyof typeof errorMapper
                                ] || 'error',
                            }}
                          ></Badge>
                        ),
                      },
                    };
                  },
                ) || [],
            }}
          ></ListFlatInfo>
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Charge' }}
        >
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: site?.energies?.prod }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Consommation' }}
        >
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: site?.energies?.conso }}
          />
        </Widget>
        <Space config={{ count: 2 }} />

        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Balance' }}
        >
          <Statistic
            config={{
              explain: 'Puissance moyenne sur les 7 derniers jours',
              precision: 2,
              way: true,
              suffix: 'mW',
            }}
            data={{ value: site?.energies?.balance }}
          />
        </Widget>
      </Col>
      <Space config={{ way: 'horizontal', count: 2 }} />
      <Col>
        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Périphériques' }}
        >
          <ListFlatInfo<'component'>
            config={{
              pagination: 'none',
              columns: [{ key: 'component', label: '', size: '1fr' }],
            }}
            data={{
              list: site?.peripherals
                ?.filter((peripheral) => !!peripheral)

                ?.map(({ kind, swvers, mfg, mdl, hwvers, bootvers }: any) => {
                  return {
                    _id: kind,
                    items: {
                      component: (
                        <Popover
                          config={{
                            placement: 'bottomLeft',
                            trigger: 'hover',
                            maxWidth: 'large',
                          }}
                          data={
                            <Description
                              data={[
                                {
                                  key: `mfg`,
                                  label: 'Manufacturer',
                                  value: mfg,
                                },
                                {
                                  key: `mdl+hwvers`,
                                  label: 'Model',
                                  value: `${mdl ?? ''}${hwvers ? `-${hwvers}` : ''}`,
                                },
                                {
                                  key: `swvers`,
                                  label: 'Firmware',
                                  value: swvers,
                                },
                                {
                                  key: `bootvers`,
                                  label: 'Bootloader',
                                  value: bootvers,
                                },
                              ]}
                            />
                          }
                        >
                          <Zone
                            config={{
                              zones: [['icon', 'blurb']],
                              rows: ['1fr'],
                              columns: ['min-content', '1fr'],
                              horizontal: 'center',
                            }}
                          >
                            <Zone.Area config={{ area: 'icon' }}>
                              <Icon
                                config={{
                                  type: 'faPlugSolid',
                                  color: 'primary',
                                }}
                              ></Icon>
                            </Zone.Area>

                            <Zone.Area config={{ area: 'blurb' }}>
                              <Blurb
                                data={{
                                  title: kind,
                                  description: swvers,
                                }}
                              ></Blurb>
                            </Zone.Area>
                          </Zone>
                          ,
                        </Popover>
                      ),
                    },
                  };
                }),
            }}
          ></ListFlatInfo>
        </Widget>
        <Space config={{ count: 2 }} />
        <Widget
          state={{
            error: error,
            loading,
            refetch,
          }}
          config={{ title: 'Version Logiciels' }}
        >
          <ListFlatInfo<'icon' | 'blurb'>
            config={{
              pagination: 'none',
              columns: [
                { key: 'icon', label: '', size: 'min-content' },
                { key: 'blurb', label: '', size: '1fr' },
              ],
            }}
            data={{
              list: site?.versions?.map(({ _id, name, version }) => {
                return {
                  _id,
                  items: {
                    icon: (
                      <Icon
                        config={{
                          type: 'faCloudArrowUpSolid',
                          color: 'primary',
                        }}
                      ></Icon>
                    ),
                    blurb: (
                      <Blurb
                        data={{
                          title: name,
                          description: version,
                        }}
                      ></Blurb>
                    ),
                  },
                };
              }),
            }}
          ></ListFlatInfo>
        </Widget>
      </Col>
    </Row>
  );
};
