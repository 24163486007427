import { gql } from '@apollo/client';

export const createControlUserGql = gql`
  mutation createControlUsers($input: CreateControlUserDto!) {
    createControlUsers(input: $input) {
      controlUser {
        _id
      }
    }
  }
`;
