import { LoadingOutlined } from '@ant-design/icons';
import { Spin as SpintAnt } from 'antd';
import { Size } from '../../types/component.type';
import './loading.component.scss';

export declare namespace LoadingType {
  type Props = {
    className?: string;
    config?: {
      size?: Extract<Size, 'small' | 'medium' | 'large'>;
    };
  };
}

export const Loading = ({
  className = '',
  config: { size = 'medium' } = {},
}: LoadingType.Props) => {
  return (
    <SpintAnt
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      className={`
        loading
        loading__size--${size} 
        ${className}
      `}
    />
  );
};
