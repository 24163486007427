import Keycloak, { KeycloakProfile } from 'keycloak-js';
import { action, makeAutoObservable, observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { buildFullSchemaGQL } from '../functions/request.function';
import { useRootStore } from '../hook/useRootStore.hook';
import { RootStoreMobX } from './root.store';
import { GQLContextType } from './gql.store';
import { logWithState } from '../functions/console.function';

type Resource = {
  name: string;
  label: string;
  description: string;
  color: string;
  title: string;
  url: string;
  canUse: boolean;
};

type Me = unknown;

export type SSOContextType = {
  mode: { type: 'parent' } | { type: 'children'; parentUrl: string };
  instance: Keycloak;
  account: KeycloakProfile;
  user: Me;
  resources: {
    modules: Array<Resource>;
    parentUrl: string;
  };
};

export class SSOStoreMobX {
  rootStore: RootStoreMobX;
  @observable public mode: SSOContextType['mode'] | null = null;
  @observable public instance: SSOContextType['instance'] | null = null;
  @observable public user: SSOContextType['user'] | null = null;
  @observable public load: boolean = false;
  @observable public account: KeycloakProfile | null = null;
  @observable public roles: Array<string> = [];
  @observable public authenticated: boolean = false;
  @observable public resources: SSOContextType['resources'] | null = null;

  constructor(rootStore: RootStoreMobX) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  @action public async auth({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    if (this.instance && !this.instance?.authenticated) {
      const checkSession = await this.checkSession();

      if (!checkSession) {
        await this.instance.login();
      }

      if (this.instance?.authenticated && this.instance.token) {
        this.setAccount(this.instance);
        this.setRoles(this.instance);
        this.setAuthenticated(this.instance);

        this.rootStore.GQLStore.updateHeaders({
          authorization: `Bearer ${this.instance.token}`,
        });
      }

      setInterval(async () => {
        try {
          await this.instance?.updateToken(50);

          if (this.instance?.token) {
            this.rootStore.GQLStore.updateHeaders({
              authorization: `Bearer ${this.instance.token}`,
            });
          }
        } catch (error) {
          console.error('Erreur lors du rafraîchissement du token:', error);
        }
      }, 30000);

      await this.setResources({ client, introspection });
      await this.setMe({ client, introspection });
    }
  }

  @action public async setMe({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    try {
      const { data: dataMe } = await client!.query<{
        me: Me;
      }>({
        query: buildFullSchemaGQL({
          operationName: 'me',
          operationType: 'QUERY',
          introspection,
        }),
      });

      this.user = dataMe.me;
    } catch (e) {
      console.log({ e });
      this.user = null;
    }
  }

  @action public async setResources({
    client,
    introspection,
  }: {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    try {
      const { data: dataResources } = await client!.query<{
        resources: {
          list: Array<Resource>;
        };
      }>({
        query: buildFullSchemaGQL({
          operationName: 'resources',
          operationType: 'QUERY',
          introspection,
        }),
        variables: {
          limit: 300,
        },
      });

      this.resources = {
        modules: dataResources.resources.list,
        parentUrl:
          this.mode?.type === 'children'
            ? this.mode.parentUrl
            : location.origin,
      };
    } catch (e) {
      console.log({ e });
      this.resources = null;
    }
  }

  @action public async checkSession(): Promise<boolean> {
    try {
      const checkSSO = await this.instance?.init({
        onLoad: 'check-sso',
      });

      return !!checkSSO;
    } catch {
      return false;
    }
  }

  @action public logout() {
    this.instance?.logout();
  }

  @observable private setRoles(instance: SSOContextType['instance']) {
    this.roles =
      instance?.resourceAccess &&
      instance?.clientId &&
      instance?.clientId in instance.resourceAccess
        ? Object.values(instance.resourceAccess?.[instance?.clientId]?.roles)
        : [];
  }

  @observable public setAuthenticated(instance: SSOContextType['instance']) {
    this.authenticated = !!instance?.authenticated;
  }

  @action private async setAccount(instance: SSOContextType['instance']) {
    this.account = (await instance?.loadUserProfile()) || null;
  }

  @action public async init({
    mode,
    instance,
    client,
    introspection,
  }: Pick<SSOContextType, 'mode' | 'instance'> & {
    client: GQLContextType['client'];
    introspection: GQLContextType['introspection'];
  }) {
    this.load = false;
    this.mode = mode;
    this.instance = instance;
    await this.auth({ client, introspection });
    this.load = true;
  }
}

export const ConfigSSO = observer(
  ({
    children,
    mode,
    instance,
  }: { children: React.ReactNode } & Pick<
    SSOContextType,
    'mode' | 'instance'
  >) => {
    //! Dépend du GQLStore
    const { GQLStore, SSOStore } = useRootStore();

    useEffect(() => {
      (async () => {
        if (
          !SSOStore.load &&
          GQLStore.load &&
          instance &&
          mode &&
          GQLStore.client &&
          GQLStore.introspection
        ) {
          logWithState({ state: 'INFO', value: 'ConfigSSO init' });
          await SSOStore.init({
            instance,
            mode,
            client: GQLStore.client!,
            introspection: GQLStore.introspection!,
          });
        }

        if (SSOStore.load) {
          logWithState({ state: 'INFO', value: 'ConfigSSO load' });
        }
      })();
    }, [
      SSOStore.load,
      instance,
      mode,
      GQLStore.load,
      GQLStore.client,
      GQLStore.introspection,
    ]);

    return SSOStore.load ? children : null;
  },
);
